import { LEGAL_ENTITY_FILTER, USER_MAPPING_FILTER, EXPORT_USER_MAPPING_REPORT_RESET, EXPORT_USER_MAPPING_REPORT, USER_MAPPING_REPORT_SHOW } from '../../types/index'
const initialState = {
    usermapppingreport: {
        usermapppingreportfilter: [],
        legalentityfilter: [],
        showdata: [],
        exportdata: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case USER_MAPPING_FILTER:
            return {
                ...state,
                usermapppingreport: {
                    ...state.usermapppingreport,
                    usermapppingreportfilter: payload,
                },
            };
        case LEGAL_ENTITY_FILTER:
            return {
                ...state,
                usermapppingreport: {
                    ...state.usermapppingreport,
                    legalentityfilter: payload,
                },
            };
        case USER_MAPPING_REPORT_SHOW:
            return {
                ...state,
                usermapppingreport: {
                    ...state.usermapppingreport,
                    showdata: payload,
                },
            };

        case EXPORT_USER_MAPPING_REPORT:
            return {
                ...state,
                usermapppingreport: {
                    ...state.usermapppingreport,
                    exportdata: payload,
                },
            };
        case EXPORT_USER_MAPPING_REPORT_RESET:
            return {
                ...state,
                usermapppingreport: {
                    ...state.usermapppingreport,
                    exportdata: [],
                },
            };
        default:
            return state;
    }
}