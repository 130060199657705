import { STATUTORYMAPPINGREPORT, STATUTORYMAPPINGREPORTSHOW } from './../../types/index'
const initialState = {
    statutorymapping: {
        StatutoryMappingReportSuccess: [],
        StatutoryMappingReportShow: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case STATUTORYMAPPINGREPORT:
            return {
                ...state,
                statutorymapping: {
                    ...state.statutorymapping,
                    StatutoryMappingReportSuccess: payload,
                },
            };
        case STATUTORYMAPPINGREPORTSHOW:
            return {
                ...state,
                statutorymapping: {
                    ...state.statutorymapping,
                    StatutoryMappingReportShow: payload,
                },
            };
        default:
            return state;
    }
}