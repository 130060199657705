import { STATUTORYNOTIFICATIONSFILTERS, STATUTORYNOTIFICATIONSREPORTDATA, EXPORT_USER_WISE_REPORT } from './../../types/index'
const initialState = {
    statutorynotificationlist: {
        statutorylist: [],
        statutorylistreportdata: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case STATUTORYNOTIFICATIONSFILTERS:
            return {
                ...state,
                statutorynotificationlist: {
                    ...state.statutorynotificationlist,
                    statutorylist: payload,
                },
            };
        case STATUTORYNOTIFICATIONSREPORTDATA:
            return {
                ...state,
                statutorynotificationlist: {
                    ...state.statutorynotificationlist,
                    statutorylistreportdata: payload,
                },
            };

        default:
            return state;
    }
}