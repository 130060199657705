import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, Space, Button, Table, Tooltip, Modal,Pagination } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { lowerFirst, reject, update } from 'lodash';
import { FilterOutlined, SearchOutlined, CheckOutlined, PlayCircleOutlined, InfoCircleOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { getfiltersdata, filtersearch, approverejecttask, passworkverfy,viewupload} from '../../Store/Action/Transactions/ApproveStatutoryBulk'
import URL from '../../Libs/URL'
const _ = require("lodash");

const mapStateToProps = (state) => ({
    listdata: state.ApproveStatotoryBulkupload.approvebulkupload.viewdata,
    filtervalue: state.ApproveStatotoryBulkupload.approvebulkupload.filterdata,

})
const ApproveStatutoryBulkuploadview = ({
    listdata,
    getfiltersdata,
    filtervalue,
    filtersearch,
    approverejecttask,
    passworkverfy,
    csvid
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    const formvalidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [FormSubmit1, setFormSubmit1] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const [pageSize, setPageSize] = useState(250);
    const [current, setCurrent] = useState(1);
    const [pageState, setpageState] = useState(false);
    const [remarksmodal, setremarksmodal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([])
    const [passwordmodal, setpasswordmodal] = useState(false);
    const [rejectselectedRows, setRejectSelectedRows] = useState([])
    const [tempvalue, setTempvalue] = useState([])
    const [check, setCheck] = useState([])
    const [checkstatus, setCheckstatus] = useState([])
    const [rejectcheck, setRejectcheck] = useState([])
    const [rejectcheckstatus, setRejectcheckstatus] = useState([])
    const [checkedrow, setCheckedRow] = useState(false);
    const [rejectcheckedrow, setRejectCheckedRow] = useState(false);
    const [filtersshow, setfiltersshow] = useState(false);
    const [filterdata, setfilterdata] = useState({
        viewdata: '3',
        organization: '',
        nature: '',
        statutory: '',
        Frequency: '',
        location: '',
        task: '',
        document: '',
        descriptions: ''
    })
    const [rejectdata, setrejectdata] = useState({
        id: '',
        remarks: '',
        csv_id: ''
    });
    const [password, setpassword] = useState({
        passwordvalue: ''
    });


    useEffect(() => {
        validator.current.showMessages();
        formvalidator.current.showMessages();
    }, []);

    useEffect(() => {
        if (listdata && listdata.mapping_data) {
            let temp = []
            for (let i in listdata.mapping_data) {
                temp.push(listdata.mapping_data[i].bu_action)
            }
            setCheck(temp)
            setRejectcheck(temp)
        }
    }, [listdata && listdata.mapping_data])

    useEffect(() => {
        if (check && check.length > 0) {
            let temp = []
            for (let i in check) {
                temp.push(check[i])
            }
            let unique = _.uniqBy(temp, function (e) {
                return e;
            });
            if (unique.length == 1) {
                if (unique[0] == 1) {
                    setCheckstatus(1)
                } else {
                    setCheckstatus(0)
                }
            } else {
                setCheckstatus(0)
            }
        }
    }, [check])

    useEffect(() => {
        if (rejectcheck && rejectcheck.length > 0) {
            let temp = []
            for (let i in rejectcheck) {
                temp.push(rejectcheck[i])
            }
            let unique = _.uniqBy(temp, function (e) {
                return e;
            });
            if (unique.length == 1) {
                if (unique[0] == 2) {
                    setRejectcheckstatus(2)
                } else {
                    setRejectcheckstatus(0)
                }
            } else {
                setRejectcheckstatus(0)
            }
        }
    }, [rejectcheck])


    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });


    const columns = [
        {
            title: 'Actions',
            children: [
                {
                    title: '#',
                    dataIndex: false,
                    key: false,
                    ellipsis: true,
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return index + 1
                    }
                },
                {
                    title: <span style={{ color: 'blue' }}>
                        <Tooltip title="Approve" color='#1890ff'>
                            <CheckOutlined />
                        </Tooltip>
                        <input type='checkbox'
                            checked={checkstatus == 1 ? true : false}
                            name="allchecked" onClick=
                            {(e) => {
                                let checked = e.target.checked
                                overallapprovelist(checked)
                            }} />
                    </span>,
                    dataIndex: false,
                    key: false,
                    ellipsis: false,
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return <Tooltip title="click here to approve">
                            <input type='checkbox'
                                checked={check && check.length > 0 && check[index] == 1 ? true : false}
                                name='approvelist'
                                onClick={
                                    (e) => {
                                        let checked = e.target.checked
                                        approvelist(record, index, checked)
                                    }} />
                        </Tooltip>
                    }
                },
                {
                    title: <span style={{ color: 'red' }}>
                        <Tooltip title="Reject" color='red'>
                            <CheckOutlined />
                        </Tooltip>
                        <input type='checkbox'
                            // checked={checkstatus == 1 ? true : false}
                            checked={rejectcheckstatus == 2 ? true : false}
                            name='allrejectlist' onClick=
                            {(e) => {
                                let checked = e.target.checked
                                overallrejectlist(checked)
                                // rejectlist(record, index, checked)
                            }} />
                    </span>,
                    dataIndex: false,
                    key: false,
                    ellipsis: false,
                    align: 'center',
                    width: '50px',
                    render: (text, record, index) => {
                        return <div>
                            <Tooltip title="click here to reject">
                                <input type='checkbox'
                                    checked={rejectcheck && rejectcheck.length > 0 && rejectcheck[index] == 2 ? true : false}
                                    // checked={check && check.length > 0 && check[index] == 1 ? true : false}
                                    name='rejectlist' onClick=
                                    {(e) => {
                                        let checked = e.target.checked
                                        rejectlist(record, index, checked)
                                    }} /></Tooltip>&nbsp;&nbsp;&nbsp;&nbsp;
                            {rejectdata.remarks != '' ?
                                <Tooltip title={rejectdata.remarks}>
                                    <InfoCircleOutlined />
                                </Tooltip> : ''
                            }
                        </div>
                    }
                },
            ]
        },
        {
            title: 'Statutory',
            dataIndex: 'statutory',
            key: 'statutory',
            ...getColumnSearchProps('statutory', 'Statutory'),
            width: '300px',
            ellipsis: true,
        },
        {
            title: 'Organization',
            dataIndex: 'orga_name',
            key: 'orga_name',
            ...getColumnSearchProps('orga_name', 'Organization'),
            ellipsis: true,
            align: 'center',
            width: '150px'
        },
        {
            title: 'Statutory Nature',
            dataIndex: 's_nature',
            key: 's_nature',
            ...getColumnSearchProps('s_nature', 'Statutory Natureization'),
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_provision',
            key: 's_provision',
            ...getColumnSearchProps('s_provision', 'Statutory Provision'),
            ellipsis: true,
            width: '200px',
            align: 'center'

        },
        {
            title: 'Compliance Task',
            dataIndex: 'c_task_name',
            key: 'c_task_name',
            ...getColumnSearchProps('c_task_name', 'Compliance Task'),
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: 'Compliance Document',
            dataIndex: 'c_doc',
            key: 'c_doc',
            ...getColumnSearchProps('c_doc', 'Compliance Document'),
            ellipsis: true,
            width: '200px'

        },
        {
            title: 'Compliance Description',
            dataIndex: 'c_desc',
            key: 'c_desc',
            ...getColumnSearchProps('c_desc', 'Compliance Description'),
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Penal consequences',
            dataIndex: 'p_cons',
            key: 'p_cons',
            ...getColumnSearchProps('p_cons', 'Penal consequences'),
            ellipsis: true,
            width: '200px'
        },
        {
            title: 'Reference Link',
            ellipsis: true,
            dataIndex: 'refer_bu',
            width: '150px',
            ...getColumnSearchProps('refer_bu', 'Reference Link'),
            render: (text, record) => {
                if (record.refer_bu == '') {
                    return ''
                }
                else {
                    return record.refer_bu
                }
            },
        },
        {
            title: 'Compliance Frequency',
            dataIndex: 'frequency',
            key: 'frequency',
            ...getColumnSearchProps('frequency', 'Compliance Frequency'),
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: 'Compfie Law Reference',
            dataIndex: 'c_law_ids',
            key: 'c_law_ids',
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: 'Effective Date',
            dataIndex: 'effective_date',
            key: 'effective_date',
            ellipsis: true,
            width: '130px'
        },
        {
            title: 'Statutory Month',
            dataIndex: 'statu_month',
            key: 'statu_month',
            ellipsis: true,
            width: '140px',
            align: 'center'
        },
        {
            title: 'Statutory Date',
            dataIndex: 'statu_date',
            key: 'statu_date',
            ellipsis: true,
            width: '140px',
            align: 'center'
        },
        {
            title: 'Trigger Before Days',
            dataIndex: 'trigger_before',
            key: 'trigger_before',
            ellipsis: true,
            width: '160px',
            align: 'center'
        },
        {
            title: 'Repeats Every',
            dataIndex: 'r_every',
            key: 'r_every',
            ellipsis: true,
            width: '130px'
        },
        {
            title: 'Repeats Type',
            dataIndex: 'r_type',
            key: 'r_type',
            ellipsis: true,
            width: '130px'
        },
        {
            title: 'Repeats By',
            dataIndex: 'r_by',
            key: 'r_by',
            ellipsis: true,
            width: '130px'
        },
        {
            title: 'Duration',
            dataIndex: 'dur',
            key: 'dur',
            ellipsis: true,
            width: '100px'
        },
        {
            title: 'Duration Type',
            dataIndex: 'dur_type',
            key: 'dur_type',
            ellipsis: true,
            width: '140px'
        },
        {
            title: 'Multiple Input Selection',
            dataIndex: 'multiple_input',
            key: 'multiple_input',
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: 'Format File',
            dataIndex: 'format_file',
            key: 'format_file',
            ...getColumnSearchProps('format_file', 'Format File'),
            ellipsis: true,
        },
        {
            title: 'Geography Location',
            dataIndex: 'geo_location',
            key: 'geo_location',
            ...getColumnSearchProps('geo_location', 'Geography Location'),
            ellipsis: true,
            width: '180px'
        },
        {
            title: 'Criticality',
            dataIndex: 'criticality_name',
            key: 'criticality_name',
            ...getColumnSearchProps('criticality_name', 'Criticality'),
            ellipsis: true,
            width: '120px',
            align: 'center'
        },
    ];

    const filterclose = () => {
        setfiltersshow(false);
        setfilterdata({
            ...filterdata,
            viewdata: '3',
            organization: '',
            nature: '',
            statutory: '',
            Frequency: [],
            location: '',
            task: '',
            document: '',
            descriptions: ''
        })

        const pay = {
            "session_token": authtoken,
            "request": [
                "GetApproveMappingFilter",
                {
                    "csv_id": listdata.csv_id
                }
            ]
        }

        getfiltersdata({
            payload: pay,
            caller: paramid
        })
    }

    const showfilter = () => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetApproveMappingFilter",
                {
                    "csv_id": listdata.csv_id
                }
            ]
        }

        getfiltersdata({
            payload: pay,
            caller: paramid
        })
        setfiltersshow(true);
    }

    const searchfilter = () => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetApproveStatutoryMappingViewFilter",
                {
                    "csv_id": listdata.csv_id,
                    "orga_name": filterdata.organization,
                    "s_nature": filterdata.nature,
                    "f_types": filterdata.Frequency,
                    "statutory": filterdata.statutory,
                    "geo_location": filterdata.location,
                    "c_task_name": filterdata.task,
                    "c_desc": filterdata.descriptions,
                    "c_doc": filterdata.document,
                    "f_count": 0,
                    "r_range": 250,
                    "filter_view_data": parseInt(filterdata.viewdata)
                }
            ]
        }

        filtersearch({
            payload: pay,
            caller: paramid
        })
    }

    const overallapprovelist = (checked) => {
        let pay
        let temparr = []
        let temp = []
        let tempuncheck = []
        let temparruncheck = []
        let value = []
        var ele = document.getElementsByName('approvelist');
        if (checked == true) {
            setCheckstatus(1)
            for (let k in check) {
                value.push(1)
            }
            setCheck(value)
            setCheckedRow(true)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
            temp = listdata && listdata.mapping_data
            setSelectedRows([...temp])
            for (let j in temp) {
                temparr.push(temp[j].sm_id)
            }
            pay = {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": temparr,
                        "csv_id": listdata.csv_id,
                        "bu_action": 1,
                        "remarks": null
                    }
                ]
            }
        } else {
            for (let k in check) {
                value.push(0)
            }
            setCheck(value)
            setCheckstatus(0)
            setCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
            setSelectedRows([])
            tempuncheck = listdata && listdata.mapping_data
            for (let j in tempuncheck) {
                temparruncheck.push(tempuncheck[j].sm_id)
            }
            pay = {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": temparruncheck,
                        "csv_id": listdata.csv_id,
                        "bu_action": 0,
                        "remarks": null
                    }
                ]
            }
        }
        approverejecttask({
            payload: pay,
            caller: paramid
        })
    }

    const approvelist = (rd, i, e) => {
        let pay;
        let temp = selectedRows
        let chek = []
        if (checkedrow == true) {
            if (e == true) {
                for (let k in check) {
                    if (k == i) {
                        chek.push(1)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                temp.push(rd)
                if (listdata && listdata.mapping_data.length == temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setSelectedRows([...temp])
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.sm_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 1,
                            "remarks": null
                        }
                    ]
                }
            } else {
                for (let i in temp) {
                    if (temp[i].sm_id == rd.sm_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let k in check) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                if (listdata && listdata.mapping_data.length > temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
                setSelectedRows([...temp])
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.sm_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": null
                        }
                    ]
                }
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
        } else {
            let temp = selectedRows
            if (e == true) {
                for (let k in check) {
                    if (k == i) {
                        chek.push(1)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.sm_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 1,
                            "remarks": null
                        }
                    ]
                }
                temp.push(rd)
                if (listdata && listdata.mapping_data.length == temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setSelectedRows([...temp])
            } else {
                for (let k in check) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(check[k])
                    }
                }
                setCheck(chek)
                pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.sm_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": null
                        }
                    ]
                }
                for (let i in temp) {
                    if (temp[i].sm_id == rd.sm_id) {
                        temp.splice(i, 1)
                    }
                }
                setSelectedRows([...temp])
                if (listdata && listdata.mapping_data.length != temp.length) {
                    var ele = document.getElementsByName('allchecked');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
        }
    }

    const overallrejectlist = (checked) => {
        let pay
        let temparr = []
        let temp = []
        let tempuncheck = []
        let temparruncheck = []
        let value = []
        var ele = document.getElementsByName('rejectlist');
        if (checked == true) {
            setRejectcheckstatus(2)
            for (let k in rejectcheck) {
                value.push(2)
            }
            setRejectcheck(value)
            setremarksmodal(true);
            setRejectCheckedRow(true)
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
            temp = listdata && listdata.mapping_data
            setRejectSelectedRows([...temp])
            for (let j in temp) {
                temparr.push(temp[j].sm_id)
            }
            setTempvalue([...temparr])
            setrejectdata({
                ...rejectdata,
                id: temparr,
                csv_id: listdata.csv_id,
            })
        } else {
            for (let k in rejectcheck) {
                value.push(0)
            }
            setRejectcheck(value)
            setRejectcheckstatus(0)
            setRejectCheckedRow(false)
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
            setRejectSelectedRows([])
            tempuncheck = listdata && listdata.mapping_data
            for (let j in tempuncheck) {
                temparruncheck.push(tempuncheck[j].sm_id)
            }
            setTempvalue([...temparruncheck])
            const pay = {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": temparruncheck,
                        "csv_id": listdata.csv_id,
                        "bu_action": 0,
                        "remarks": rejectdata.remarks
                    }
                ]
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
        }
    }

    const rejectlist = (rd, i, e) => {
        let temp = rejectselectedRows
        let chek = []
        if (rejectcheckedrow == true) {
            if (e == true) {
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(2)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                temp.push(rd)
                setrejectdata({
                    ...rejectdata,
                    id: [rd.sm_id],
                    csv_id: listdata.csv_id,
                })
                if (listdata && listdata.mapping_data.length == temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setRejectSelectedRows([...temp])
                setremarksmodal(true);
            } else {
                for (let i in temp) {
                    if (temp[i].sm_id == rd.sm_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                if (listdata && listdata.mapping_data.length != temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
                setRejectSelectedRows([...temp])
                const pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.sm_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": rejectdata.remarks
                        }
                    ]
                }
                approverejecttask({
                    payload: pay,
                    caller: paramid
                })
            }
        } else {
            let temp = rejectselectedRows
            if (e == true) {
                setrejectdata({
                    ...rejectdata,
                    id: [rd.sm_id],
                    csv_id: listdata.csv_id,
                })
                setremarksmodal(true);
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(2)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                temp.push(rd)
                if (listdata && listdata.mapping_data.length == temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = true;
                    }
                }
                setRejectSelectedRows([...temp])
            } else {
                for (let i in temp) {
                    if (temp[i].sm_id == rd.sm_id) {
                        temp.splice(i, 1)
                    }
                }
                for (let k in rejectcheck) {
                    if (k == i) {
                        chek.push(0)
                    } else {
                        chek.push(rejectcheck[k])
                    }
                }
                setRejectcheck(chek)
                if (listdata && listdata.mapping_data.length != temp.length) {
                    var ele = document.getElementsByName('allrejectlist');
                    for (var i = 0; i < ele.length; i++) {
                        if (ele[i].type === 'checkbox')
                            ele[i].checked = false;
                    }
                }
                setRejectSelectedRows([...temp])
                const pay = {
                    "session_token": authtoken,
                    "request": [
                        "SaveAction",
                        {
                            "as_ids": [
                                rd.sm_id
                            ],
                            "csv_id": listdata.csv_id,
                            "bu_action": 0,
                            "remarks": rejectdata.remarks
                        }
                    ]
                }
                approverejecttask({
                    payload: pay,
                    caller: paramid
                })
            }

        }
    }

    const rejecttask = () => {
        setAddFormSubmit1(true);
        if (validator.current.allValid()) {
            const pay =
            {
                "session_token": authtoken,
                "request": [
                    "SaveAction",
                    {
                        "as_ids": rejectdata.id,
                        "csv_id": rejectdata.csv_id,
                        "bu_action": 2,
                        "remarks": rejectdata.remarks
                    }
                ]
            }
            approverejecttask({
                payload: pay,
                caller: paramid
            })
            setremarksmodal(false);
        }
    }

    const reasoncancel = () => {
        setremarksmodal(false);
        setrejectdata({
            ...rejectdata,
            id: '',
            remarks: '',
            csv_id: ''

        })
        setAddFormSubmit1(false);
    }

    const submittask = () => {
        setpasswordmodal(true);
    }

    const passwordcancel = () => {
        setpasswordmodal(false);
        setFormSubmit1(false);
        setpassword({
            ...password,
            passwordvalue: ''
        })

    }
    const submitpassword = () => {
        setFormSubmit1(true);
        if (formvalidator.current.allValid()) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": password.passwordvalue
                    }
                ]
            }

            passworkverfy({
                payload: payload,
                caller: paramid
            })
            setpasswordmodal(false);
        }
    }

    useEffect(() => {
        if (pageState == true) {
         const pay = {
            "session_token": authtoken,
            "request": [
                "GetApproveStatutoryMappingView",
                {
                    "csv_id": csvid,
                    "f_count": 0,
                    "r_range": 250
                }
            ]
        }
        viewupload({
            payload: pay,
            caller: paramid
        })
        }
      }, [current, pageSize, pageState])

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link><b>Approve / Reject Statutory Mapping - Bulk Upload</b></Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                        <div style={{ marginRight: '15px' }}>
                            <Tooltip title="Back" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                size='default' onClick={() => {
                                    window.location.reload()
                                }}>
                                Back
                            </Button></Tooltip>
                        </div>
                    </div>
                </div>
            </div>

            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} >
                                <Panel header="Approve/ Reject  Statutory Mapping" key={1}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label><b>Country:&nbsp;</b></label>
                                                {listdata.bulk_c_name}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Domain:&nbsp;</b></label>
                                                {listdata.bulk_d_name}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Uploaded On:&nbsp;</b></label>
                                                {listdata.uploaded_on}
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12'>
                                        <div className="row">
                                            <div className='col-md-4'>
                                                <label><b>Uploaded File Name:&nbsp;</b></label>
                                                {listdata.csv_name}
                                            </div>
                                            <div className='col-md-4'>
                                                <label><b>Uploaded By:&nbsp;</b></label>
                                                {listdata.uploaded_by}
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <div style={{ 'margin-left': '880px', 'margin-top': '15px' }}>
                                <Tooltip title="Filter" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                    icon={<FilterOutlined />} size='default' onClick={showfilter} >
                                    Filter
                                </Button></Tooltip>
                            </div>
                            <div className="col-lg-12" style={{ marginTop: '2%' }}>
                                <div className="row">
                                    <Table
                                        className='userprivclass'
                                        columns={columns}
                                        dataSource={listdata && listdata.mapping_data}
                                        bordered
                                        scroll={{ x: 4000 }}
                                        pagination={false}
                                    />
                                   <span>Showing {listdata && listdata.total} to {listdata && listdata.total } of {listdata && listdata.total} compliances | Show
                                    <Pagination
                                        style={{ "margin-top": "10px", float: " RIGHT" }}
                                        current={current}
                                        showSizeChanger={true}
                                        showQuickJumper={false}
                                        onShowSizeChange={() => {
                                            setCurrent(1);
                                        }}
                                        pageSizeOptions={[250, 500, 700,1000]}
                                        defaultPageSize={250}
                                        onChange={(page, pageSizes) => {
                                            setpageState(true);
                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                            setPageSize(pageSizes);
                                        }}
                                        total={listdata && listdata.total}
                                    /></span> 
                                </div>
                            </div>
                            {console.log(listdata.total,'total')}
                            <Button type="primary" shape="round" className='addbutton' style={{ marginLeft: "400px", marginTop: "20px" }}
                                size='default' onClick={submittask}>
                                Submit
                            </Button>
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={filtersshow} maskClosable={false} className="serviceProvider" footer={null} title="Filter By"
                onCancel={filterclose}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>View Data</b></label>
                                    <form
                                        value={filterdata.viewdata}
                                        onChange={(e) => {
                                            setfilterdata({
                                                ...filterdata,
                                                viewdata: e.target.value,
                                            });
                                        }}>
                                        <div class="radio radiofill">
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                        filterdata.viewdata == 1
                                                    }
                                                />
                                                &nbsp;Verified
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                        filterdata.viewdata == 2
                                                    }
                                                />
                                                &nbsp;Pending
                                            </label>
                                        </div>
                                        <div>
                                            <label>
                                                <input
                                                    type="radio"
                                                    value="3"
                                                    name="status"
                                                    checked={
                                                        filterdata.viewdata == 3
                                                    }
                                                />
                                                &nbsp;All
                                            </label>
                                        </div>
                                    </form>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Organization:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Organization'
                                        placeholder="Enter Organization"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.organization}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                organization: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.orga_names && filtervalue.orga_names.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Statutory Nature:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Nature'
                                        placeholder="Enter Statutory Nature"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.nature}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                nature: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.s_natures && filtervalue.s_natures.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Statutory:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Statutory'
                                        placeholder="Enter Statutory"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.statutory}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                statutory: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.bu_statutories && filtervalue.bu_statutories.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Frequency:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Frequency'
                                        mode="multiple"
                                        placeholder="Enter Compliance Frequency"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.Frequency || undefined}
                                        onChange={(e) => {
                                            setfilterdata({
                                                ...filterdata,
                                                Frequency: e
                                            })
                                        }}>
                                        {filtervalue && filtervalue.frequencies && filtervalue.frequencies.map((item, i) => {
                                            return <Option value={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Geography Location:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Location'
                                        placeholder="Enter Geography Location"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.location}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                location: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.geo_locations && filtervalue.geo_locations.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Task:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Task'
                                        placeholder="Enter Compliance Task"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.task}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                task: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.c_tasks && filtervalue.c_tasks.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Document:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Document'
                                        placeholder="Enter Compliance Document"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.document}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                document: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.c_docs && filtervalue.c_docs.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-md-6">
                                <div className="mb-2">
                                    <label className="control-label"><b>Compliance Description:&nbsp;</b></label>
                                    <Select
                                        allowClear={false}
                                        size="default"
                                        name='Description'
                                        placeholder="Enter Compliance Description"
                                        showSearch
                                        filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                        style={{ width: '100%' }}
                                        value={filterdata.descriptions}
                                        onChange={(data, value) => {
                                            setfilterdata({
                                                ...filterdata,
                                                descriptions: value.value
                                            })
                                        }}>
                                        {filtervalue && filtervalue.c_descs && filtervalue.c_descs.map((item, i) => {
                                            return <Option key={item}>{item}</Option>
                                        })}
                                    </Select>
                                </div>
                            </div>
                            <div className="col-md-4" style={{ 'margin-top': '20px', 'margin-left': '95px' }}>
                                <div className="mb-2">
                                    <Tooltip title="Filter" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                        icon={<SearchOutlined />} size='default' onClick={searchfilter} >
                                        Search
                                    </Button></Tooltip>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal>
            <Modal visible={remarksmodal} className="newStyleModalPassword" title="Enter Reason" footer={null} onCancel={reasoncancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Reason</b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="text"
                                id="reason"
                                name='reason'
                                autoComplete={false}
                                placeholder="Enter Reason"
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                                onChange={(e) => {
                                    setrejectdata({
                                        ...rejectdata,
                                        remarks: e.target.value
                                    })
                                }}
                                value={rejectdata.remarks}
                            />
                            {validator.current.message(
                                'reason',
                                rejectdata.remarks,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Reason Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={() => { rejecttask() }}>
                        Submit
                    </Button>
                </div>
            </Modal>
            <Modal visible={passwordmodal} className="newStyleModalPassword" title="Enter Password" footer={null} onCancel={passwordcancel}>
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label className="control-label"> <b>Password:</b><span style={{ "color": "red" }}>*</span></label>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                autoComplete={false}
                                placeholder="Enter password"
                                style={{ "margin-top": "5px", width: '100%' }}
                                className="form-control"
                                onChange={(e) => {
                                    setpassword({
                                        ...password,
                                        passwordvalue: e.target.value
                                    })
                                }}
                                value={password.passwordvalue}
                            />
                            {formvalidator.current.message(
                                'Password',
                                password.passwordvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${FormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Password Required'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <Button type="primary" shape="round" className='addbutton'
                        style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                        icon={<PlayCircleOutlined />} size='default' onClick={submitpassword}>
                        Submit
                    </Button>
                </div>
            </Modal>
        </div>
    )
}
export default connect(mapStateToProps, {
    getfiltersdata,
    filtersearch,
    approverejecttask,
    passworkverfy
})(ApproveStatutoryBulkuploadview);