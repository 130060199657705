import { PROFILE } from "../types/index";
const initialState = {
    profile: {
        profiledata: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case PROFILE:
            return {
                ...state,
                profile: {
                    ...state.profile,
                    profiledata: payload,
                },
            };
        default:
            return state;
    }
}