import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, Space, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import ApproveStatutoryBulkuploadview from './Approvestat_bulk_view'
import {
    Domainlist, ExeList, bulkuploadList, approveupload, rejectupload,
    approvealive, rejectalive, viewupload
} from '../../Store/Action/Transactions/ApproveStatutoryBulk'
import { update } from 'lodash';
import { DownloadOutlined, EditOutlined, DislikeOutlined, LikeOutlined, PlayCircleOutlined, SearchOutlined } from "@ant-design/icons";
import URL from '../../Libs/URL'
import Swal from 'sweetalert2';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    domainlist: state.ApproveStatotoryBulkupload.approvebulkupload.domaindetails,
    exelist: state.ApproveStatotoryBulkupload.approvebulkupload.exedetails,
    list: state.ApproveStatotoryBulkupload.approvebulkupload.list.pending_csv_list,
    success: state.ApproveStatotoryBulkupload.approvebulkupload.ressuccess,
    reject_sta: state.ApproveStatotoryBulkupload.approvebulkupload.rejectresponse

})
const ApproveStatutoryBulkupload = ({
    Domainlist,
    ExeList,
    domainlist,
    exelist,
    list,
    bulkuploadList,
    approveupload,
    rejectupload,
    success,
    reject_sta,
    approvealive,
    rejectalive,
    viewupload

}) => {
    console.log(list, 'list');
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    const { Panel } = Collapse;
    const { Option } = Select;
    const [showlist, setshowlist] = useState(false);
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    const formValidator2 = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [currentpage, setcurrentpage] = useState('list');
    const [searchdata, Setsearchdata] = useState({
        country: '',
        countrydata: '',
        doamindata: '',
        domain: '',
        userdata: '',
        user: '',
        userid: ''
    })
    const [password, setpassword] = useState({
        passwordvalue: ''
    });
    const [remarks, setremarks] = useState({
        remarksvalue: '',
        password: ''
    })
    const [coldisplay, setcoldisplay] = useState('none');
    const [icondisplay, seticondisplay] = useState(0);
    const [approveform, setapproveform] = useState(false);
    const [rejectform, setrejectform] = useState(false);
    const [approvedata, setapprovedata] = useState();
    const [rejectdata, setrejectdata] = useState();
    const[csvid,setcsvid] = useState();
    const [searchText, setSearchText] = useState('');
    const [searchedColumn, setSearchedColumn] = useState('');
    const searchInput = useRef(null);

    const handleSearch = (selectedKeys, confirm, dataIndex) => {
        confirm();
        setSearchText(selectedKeys[0]);
        setSearchedColumn(dataIndex);
    };
    const handleReset = (clearFilters) => {
        clearFilters();
        setSearchText('');
    };

    const getColumnSearchProps = (dataIndex, placeholder) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
            <div
                style={{
                    padding: 8,
                }}
            >
                <Input
                    ref={searchInput}
                    placeholder={placeholder}
                    value={selectedKeys[0]}
                    onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, confirm, dataIndex)}
                    style={{
                        marginBottom: 8,
                        display: 'block',
                    }}
                />
                <Space>
                    <Button
                        type="primary"
                        onClick={() => handleSearch(selectedKeys, confirm, dataIndex)}
                        icon={<SearchOutlined />}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Search
                    </Button>
                    <Button
                        onClick={() => clearFilters && handleReset(clearFilters)}
                        size="small"
                        style={{
                            width: 90,
                        }}
                    >
                        Reset
                    </Button>
                </Space>
            </div>
        ),
        filterIcon: (filtered) => (
            <SearchOutlined
                style={{
                    color: filtered ? '#1890ff' : undefined,
                }}
            />
        ),
        onFilter: (value, record) =>
            record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : "",
        onFilterDropdownOpenChange: (visible) => {
            if (visible) {
                setTimeout(() => searchInput.current?.select(), 100);
            }
        },
        render: (text) => text
    });


    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '50px',
            render: (text, record, index) => {
                return <> {index + 1} {record && record.is_amendment == 0 ?
                    <Tooltip title={record.compliance_frequency}><p><i style={{ color: "green" }} class="bi bi-square-fill"></i></p></Tooltip> :
                    <Tooltip title={record.compliance_frequency}><p><i style={{ color: "yellow" }} class="bi bi-square-fill"></i></p></Tooltip>
                }</>
            }
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name',
            width: '180px',
            key: 'csv_name',
            ...getColumnSearchProps('csv_name', 'File Name'),
            ellipsis: true,
            render: (record) => {
                let ab = record.split('_');
                let display = ab[0] + '_' + ab[1]
                return (
                    <p title={display} className='cut-text'>{ab[0]}_{ab[1]}</p>
                )
            }
        },
        {
            title: 'Uploaded On',
            dataIndex: 'uploaded_on',
            width: '140px',
            ...getColumnSearchProps('uploaded_on', 'Uploaded on'),
            key: 'uploaded_on',
            ellipsis: true,
        },
        {
            title: 'Uploaded By',
            dataIndex: 'uploaded_by',
            key: 'uploaded_by',
            width: '140px',
            ...getColumnSearchProps('uploaded_by', 'Uploaded By'),
            align: 'center',
            ellipsis: true,
            render: (record) => {
                return (
                    <span>{searchdata && searchdata.userdata}</span>
                )
            }
        },
        {
            title: 'No. of records',
            dataIndex: 'no_of_records',
            width: '150px',
            key: 'no_of_records',
            ...getColumnSearchProps('no_of_records', 'No Of Records'),
            ellipsis: true,
            align: 'center',

        },
        {
            title: 'Approved / Rejected',
            dataIndex: 'approve_count',
            key: 'approve_count',
            ellipsis: true,
            width: '170px',
            align: 'center',
            render: (text, record) => {
                return <span>{record.approve_count}/{record.rej_count}</span>

            },
        },
        {
            title: 'Download',
            align: 'center',
            width: '140px',
            render: (text, record, index) => {
                return (
                    <>
                        <span
                            className="btn btn-sm btn-light-primary text-primary btn-circle d-flex align-items-center 
                          justify-content-center" style={{ marginLeft: '58px' }}
                            onClick={() => {
                                if (index == 1)
                                    if (icondisplay == 0) {
                                        setcoldisplay('block');
                                        seticondisplay(1)
                                    } else {
                                        setcoldisplay('none');
                                        seticondisplay(0)
                                    }

                            }}><DownloadOutlined /></span>

                        <span style={{ display: coldisplay }}>
                            <div>
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('excel', record.csv_name) }}>Download Excel</span><br />
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('csv', record.csv_name) }}>Download CSV</span><br />
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('ods', record.csv_name) }}>Download ODS</span><br />
                                <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('text', record.csv_name) }}>Download Text</span>
                            </div>
                        </span>
                    </>
                );
            }
        },
        {
            title: 'Task Wise Action',
            dataIndex: 'c_by',
            key: 'c_by',
            ellipsis: true,
            width: '150px',
            align: 'center',
            render: (text, record) => {
                if (record.approve_count >= 1) {
                    return <span><Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to edit"}>
                        <EditOutlined onClick={() => { showview(record) }} />
                    </Tooltip></span>
                } else {
                    if (record.rej_count >= 1) {
                        return <span><Tooltip
                            className="toolTipClass"
                            placement="topRight"
                            title={"click here to edit"}>
                            <EditOutlined onClick={() => { showview(record) }} />
                        </Tooltip></span>
                    } else {
                        return <Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to view"}>
                        <Button
                            type="primary"
                            shape="round"
                            className='addbutton' onClick={() => { showview(record) }}>
                            View
                        </Button></Tooltip>
                    }
                }
            },
        },
        {
            title: 'Approve',
            align: 'center',
            width: "100px",
            render: (text, record) => {
                return <span className="btn btn-sm btn-light-success
                text-success btn-circle d-flex align-items-center 
                justify-content-center" style={{ marginLeft: '28px' }} onClick={() => {
                        setapproveform(true);
                        setapprovedata(record);
                    }}>
                    <Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to approve"}>
                        <LikeOutlined />
                    </Tooltip>
                </span>
            },
        },
        {
            title: 'Reject',
            ellipsis: true,
            width: "100px",
            align: 'center',
            render: (text, record) => {
                return <span className="btn btn-sm btn-light-danger
                text-danger btn-circle d-flex align-items-center 
                justify-content-center" style={{ marginLeft: '28px' }} onClick={() => {
                        Swal.fire({
                            icon: 'warning',
                            title: 'Alert messsage!',
                            text: 'This Option will reject all the tasks. To reject, selected task you may choose"View Option". Do you want to proceed',
                            showCancelButton: true,
                            confirmButtonText: 'Yes',
                            cancelButtonText: 'No',
                        }).then((result) => {
                            if (result.isConfirmed) {
                                setrejectform(true);
                            }
                        })
                    }}>
                    <Tooltip
                        className="toolTipClass"
                        placement="topRight"
                        title={"click here to reject"}>
                        <DislikeOutlined />
                    </Tooltip></span>
            },
        },
        {
            title: 'Queued Documents',
            ellipsis: true,
            align: 'center',
            width: '150px',
            render: (text, record) => {
                return <span><EditOutlined /></span>
            },
        },
    ];



    const downloadfile = (e, id) => {
        const expression = id.split('.')
        let site;
        if (e == 'csv') {
            window.open(URL.CommonURL + '/uploaded_file/csv/' + id);
        } else if (e == 'excel') {
            window.open(URL.CommonURL + '/uploaded_file/xlsx/' + expression[0] + '.' + 'xlsx');
        } else if (e == 'ods') {
            window.open(URL.CommonURL + '/uploaded_file/xlsx/' + expression[0] + '.' + 'ods');
        } else {
            window.open(URL.CommonURL + '/uploaded_file/txt/' + expression[0] + '.' + 'txt', '');
        }

    }

    const showview = (record) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetApproveStatutoryMappingView",
                {
                    "csv_id": record.csv_id,
                    "f_count": 0,
                    "r_range": 250
                }
            ]
        }

        viewupload({
            payload: pay,
            caller: paramid
        })
        setcsvid(record.csv_id);
        setcurrentpage('view');
    }


    useEffect(() => {
        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetDomains",
                    {}
                ]
            }
            let payload2 = {
                "session_token": authtoken,
                "request": [
                    "GetKExecutiveDetails",
                    {}
                ]
            }

            Domainlist({
                payload: payload,
                caller: paramid,
            })

            ExeList({
                payload: payload2,
                caller: paramid,
            })

        }
    }, [isAuth]);

    useEffect(() => {
        formValidator.current.showMessages();
        formValidator1.current.showMessages();
        formValidator2.current.showMessages();
    }, []);

    useEffect(() => {
        if (domainlist && exelist.k_executive_info) {
            let temp = [];
            let arr = [];

            const cid = exelist.k_executive_info[0]['c_ids']
            const did = exelist.k_executive_info[0]['d_ids']
            const uniqcid = [...new Set(cid)];
            for (let i = 0; i < uniqcid.length; i++) {
                const countryid = _.filter(domainlist && domainlist.bsm_countries, { country_id: uniqcid[i] })
                temp.push(countryid);
            }
            for (let j = 0; j < did.length; j++) {
                const domainid = _.filter(domainlist && domainlist.bsm_domains, { domain_id: did[j] })
                arr.push(domainid);
            }
            Setsearchdata({
                ...searchdata,
                user: exelist && exelist.k_executive_info[0].emp_code_name,
                countrydata: [].concat.apply([], temp),
                doamindata: [].concat.apply([], arr),
                userid: exelist && exelist.k_executive_info[0].user_id
            })

        }
    }, [domainlist, exelist.k_executive_info])

    const searchlist = (e) => {
        e.preventDefault()
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            const payload = {
                "session_token": authtoken,
                "request": [
                    "GetApproveStatutoryMappingList",
                    {
                        "c_id": parseInt(searchdata.country),
                        "d_id": parseInt(searchdata.domain),
                        "uploaded_by": searchdata.userid
                    }
                ]
            }
            bulkuploadList({
                payload: payload,
                caller: paramid,
            })
            setshowlist(true)
        }
    }

    const passwordcancel = () => {
        setapproveform(false);
        setpassword({
            ...password,
            passwordvalue: ''
        });
        setAddFormSubmit1(false);
    }

    const rejectcancel = () => {
        setrejectform(false);
        setremarks({
            ...remarks,
            password: '',
            remarksvalue: ''
        })
        setAddFormSubmit2(false);
    }

    const submitpassword = (e) => {
        e.preventDefault();
        setAddFormSubmit1(true);
        if (formValidator1.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": password.passwordvalue
                    }
                ]
            }
            const pay2 = {
                "session_token": authtoken,
                "request": [
                    "UpdateApproveActionFromList",
                    {
                        "csv_id": approvedata.csv_id,
                        "bu_action": 1,
                        "remarks": null,
                        "password": password.passwordvalue,
                        "c_id": parseInt(searchdata.country),
                        "d_id": parseInt(searchdata.domain)
                    }
                ]
            }

            approveupload({
                payload: pay,
                payload2: pay2,
                caller: paramid
            })
            setapproveform(false);
            setAddFormSubmit1(false);
            setpassword({
                ...password,
                passwordvalue: ''
            })

        }

    }




    useEffect(() => {
        if (success && success.csv_name) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetApproveMappingStatus",
                    {
                        "csv_name": success.csv_name
                    }
                ]
            }
            approvealive({
                payload: pay,
                caller: paramid
            })

        }
    }, [success])

    const addremarks = (e) => {
        e.preventDefault();
        setAddFormSubmit2(true);
        if (formValidator2.current.allValid()) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": remarks.password
                    }
                ]
            }

            const pay2 = {
                "session_token": authtoken,
                "request": [
                    "UpdateApproveActionFromList",
                    {
                        "csv_id": approvedata && approvedata.csv_id,
                        "bu_action": 1,
                        "remarks": remarks.remarksvalue,
                        "password": remarks.password,
                        "c_id": parseInt(searchdata.country),
                        "d_id": parseInt(searchdata.domain)
                    }
                ]
            }

            rejectupload({
                payload: pay,
                payload2: pay2,
                caller: paramid
            })
            setrejectform(false);
            setAddFormSubmit2(false);
            setremarks({
                ...remarks,
                password: '',
                remarksvalue: ''
            })
        }
    }

    useEffect(() => {
        if (reject_sta && reject_sta.csv_name) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetApproveMappingStatus",
                    {
                        "csv_name": reject_sta.csv_name
                    }
                ]
            }
            rejectalive({
                payload: pay,
                caller: paramid
            })
        }
    }, [reject_sta])

    return (
        <>
            {currentpage == 'list' ? (
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Transactions</Link>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <Link><b>Approve / Reject Statutory Mapping -Bulk Upload</b></Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card-body" style={{ padding: "15px 5px" }}>
                                    <Collapse defaultActiveKey={["1"]} >
                                        <Panel header="Approve/Reject Statutory Mapping - Bulk Upload" key={1}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Country:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                name='country'
                                                                placeholder="Enter Country"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                onChange={(data, value) => {
                                                                    Setsearchdata({
                                                                        ...searchdata,
                                                                        country: value.value
                                                                    })
                                                                }}>
                                                                {searchdata && searchdata.countrydata && searchdata.countrydata.map((item, i) => {
                                                                    return <Option key={item.country_id}>{item.country_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'country',
                                                                searchdata.country,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Domain:</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                name='domain'
                                                                placeholder="Enter Domain Name"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                disabled={searchdata.country == '' ? true : false}
                                                                onChange={((data, value) => {
                                                                    Setsearchdata({
                                                                        ...searchdata,
                                                                        domain: value.value
                                                                    })
                                                                })}>
                                                                {searchdata.doamindata && searchdata.doamindata.map((data) => {
                                                                    return <Option key={data.domain_id}>{data.domain_name}</Option>
                                                                })}
                                                            </Select>
                                                            {formValidator.current.message(
                                                                'domain',
                                                                searchdata.domain,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Compfie Domain Name Required'
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>User:</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Organization"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                disabled={searchdata.domain == '' ? true : false}
                                                                onChange={((data, value) => {
                                                                    Setsearchdata({
                                                                        ...searchdata,
                                                                        userdata: value.value
                                                                    })
                                                                })}>
                                                                <Option key={searchdata.user}>{searchdata.user}</Option>
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <br></br>
                                            <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <Button
                                                            type="primary"
                                                            shape="round"
                                                            className='addbutton'
                                                            style={{
                                                                marginTop: "20px"
                                                            }}
                                                            onClick={searchlist}>
                                                            Show
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {showlist == true ?
                                                <>
                                                    <div className="col-lg-12" style={{ marginTop: '5%' }}>
                                                        <div className="row">
                                                            <Table
                                                                className='userprivclass'
                                                                columns={columns}
                                                                dataSource={list && list}
                                                                bordered
                                                                scroll={{ x: 1700 }}
                                                                pagination={false}
                                                            />
                                                        </div>
                                                    </div>
                                                    <div style={{ marginTop: '2%' }}>
                                                        <div className="d-flex">
                                                            <i style={{ color: "green" }} class="bi bi-square-fill me-2"></i><p>{"Statutoy mapping Bulk Upload tasks"}</p>
                                                        </div>
                                                        <div className="d-flex">
                                                            <i style={{ color: "yellow" }} class="bi bi-square-fill me-2"></i><span>{"Approve, Approve & Notify Tasks"}</span>
                                                        </div>
                                                    </div>

                                                </>
                                                : ""}
                                        </Panel>
                                    </Collapse>
                                </div>
                            </div>
                        </div>
                    </div>
                    <Modal visible={approveform} className="newStyleModalPassword" title="Enter Password" footer={null} onCancel={passwordcancel}>
                        <div className="row">
                            <div className="col-md-12">
                                <div className="mb-6">
                                    <label className="control-label"> <b>Password </b><span style={{ "color": "red" }}>*</span></label>
                                    <input
                                        type="password"
                                        id='passwordvalue'
                                        name='passwordvalue'
                                        autoComplete={false}
                                        placeholder="Enter password"
                                        style={{ "margin-top": "5px", width: '100%' }}
                                        className="form-control"
                                        onChange={(e) => {
                                            setpassword({
                                                ...password,
                                                passwordvalue: e.target.value
                                            })
                                        }}
                                        value={password.passwordvalue}
                                    />
                                    {formValidator1.current.message(
                                        'passwordvalue',
                                        password.passwordvalue,
                                        ['required'],
                                        {
                                            className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                            messages: {
                                                required: 'Password Required'
                                            }
                                        })}
                                </div>
                            </div>

                        </div>
                        <br />
                        <div className="form-actions">
                            <Button type="primary" shape="round" className='addbutton'
                                style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                                icon={<PlayCircleOutlined />} size='default' onClick={submitpassword}>
                                Submit
                            </Button>
                        </div>
                    </Modal>

                    <Modal visible={rejectform} className='usr_modal_class' footer={null} title="Enter Password"
                        onCancel={rejectcancel}>
                        <div className="col-md-12">
                            <div className="form-group">
                                <label htmlFor=""><b>Password</b> <span style={{ "color": "red" }}>*</span></label>
                                <input
                                    type="password"
                                    id="passwordvalue"
                                    name='passwordvalue'
                                    className="form-control"
                                    placeholder="Enter password"
                                    value={remarks.password}
                                    onChange={(e) => {
                                        setremarks({
                                            ...remarks,
                                            password: e.target.value
                                        })
                                    }} />
                                {formValidator2.current.message(
                                    'passwordvalue',
                                    remarks.password,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                        messages: {
                                            required: 'Password Required'
                                        }
                                    })}
                            </div>
                        </div>
                        <br />
                        <div className="col-md-12">
                            <label htmlFor=""><b>Remarks:</b> <span style={{ "color": "red" }}>*</span></label>
                            <textarea className='form-control' name='remark'
                                row='1' cols='4' style={{ resize: 'none' }}
                                placeholder="Enter Remarks"
                                value={remarks.remarksvalue}
                                onChange={(e) => {
                                    setremarks({
                                        ...remarks,
                                        remarksvalue: e.target.value
                                    })
                                }}>
                            </textarea>
                            {formValidator2.current.message(
                                'remark',
                                remarks.remarksvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit2 ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Remarks Required'
                                    }
                                })}
                        </div>
                        <br />
                        <div className="form-actions">
                            <Button type="primary" shape="round" className='addbutton'
                                style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                                icon={<PlayCircleOutlined />} size='default' onClick={addremarks}

                            >
                                Submit
                            </Button>
                        </div>
                    </Modal>
                </div>
            ) : <ApproveStatutoryBulkuploadview csvid={csvid} />}
        </>
    )

}
export default connect(mapStateToProps, {
    Domainlist,
    ExeList,
    bulkuploadList,
    approveupload,
    rejectupload,
    approvealive,
    rejectalive,
    viewupload
})(ApproveStatutoryBulkupload);