import React from 'react';
import { connect } from 'react-redux';
import { Switch, Route } from "react-router-dom";

// import Home from './Home';
import Dashboard from './Dashboard';
import TaskDueDate from './Transactions/Taskduedate';
import ConsolidatedReport from "./Report/ComplianceReports/ConsolidatedReport"
import DomainReport from './Report/ComplianceReports/DomainReports';
import Notification from './Notifications/Notifications';
import Messagesshowall from './Notifications/Messages';
import Rejectedsummary from './Transactions/Rejectedsummary';
import TaskCategory from './Transactions/Taskcategory';
import StatutoryNotificationList from './Report/StatutoryNotificationList';
import StatutoryMappingBulkReport from './Report/StatutoryMapping_BulkUploadReport';
import StatutoryMappingReport from './Report/StatutoryMappingReport';
import UserMappingReport from './Report/UserMappingReport';
import Taskcategoryedit from './Transactions/Taskcategoryedit';
import StatutoryMapping from './Transactions/StatutoryMapping';
import MyProfile from './MyProfile'
import ChangePassword from './ChangePassword'
import AddStatutoryMapping from './Transactions/Addstatutorymapping'
import StatutoruMappingBulkupload from './Transactions/StatutoryMappingBulkupload'
import Taskcategoryadd from './Transactions/Taskcategoryadd';
import Approvetaskcategorymapping from './Transactions/Approvetaskcategorymapping';
import ApproveStatutoryBulkupload from './Transactions/Approve_statutory_bulkupload'
import ApproveStatutoryMapping from './Transactions/ApproveStatutoryMapping';
import AssignClientUnit from './Transactions/Assignclientunit'
import ApproveAssignBulkupload from './Transactions/Approve_assign_bulkupload'

const RoutesLink = () => {
    return (
        <Switch>
            <Route exact path="/dashboard">
                <Dashboard />
            </Route>
            <Route exact path="/knowledge/home/:id">
                <Dashboard />
            </Route>
            <Route exact path="/status-report-consolidated/:id">
                <ConsolidatedReport />
            </Route>
            <Route exact path="/domain-wise-report/:id">
                <DomainReport />
            </Route>
            <Route exact path="/knowledge/messages/:id">
                <Messagesshowall />
            </Route>
            <Route exact path="/knowledge/statutory-notifications/:id">
                <Notification />
            </Route>
            <Route exact path="/knowledge/reject-statutory-mapping-bu/:id">
                <Rejectedsummary />
            </Route>
            <Route exact path="/knowledge/task-due-extension/:id">
                <TaskDueDate />
            </Route>
            <Route exact path="/knowledge/taskcategory-mapping/:id">
                <TaskCategory />
            </Route>
            <Route exact path="/knowledge/statutory-notifications-list/:id">
                <StatutoryNotificationList />
            </Route>
            <Route exact path="/knowledge/statutory-mapping-bulkupload-report/:id">
                <StatutoryMappingBulkReport />
            </Route>
            <Route exact path="/knowledge/taskcategory-mapping/:id">
                <Taskcategoryedit />
            </Route>
            <Route exact path="/knowledge/statutory-mapping/:id">
                <StatutoryMapping />
            </Route>
            <Route exact path="/knowledge/statutory-mapping/:id">
                <AddStatutoryMapping />
            </Route>
            <Route exact path="/knowledge/statutory-mapping-report/:id">
                <StatutoryMappingReport />
            </Route>
            <Route exact path="/knowledge/user-mapping-report/:id">
                <UserMappingReport />
            </Route> 
            <Route exact path="/knowledge/profile/:id">
                <MyProfile />
            </Route>
            <Route exact path="/knowledge/change-password/:id">
                <ChangePassword />
            </Route>
            <Route exact path="/knowledge/statutory-mapping-bu/:id">
                <StatutoruMappingBulkupload />
            </Route>
            <Route exact path="/knowledge/taskcategory-mapping/:id">
                <Taskcategoryadd />
            </Route>
            <Route exact path="/knowledge/approve-taskcategory-mapping/:id">
                <Approvetaskcategorymapping />
            </Route>
            <Route exact path="/knowledge/approve-statutory-mapping-bu/:id">
                <ApproveStatutoryBulkupload />
                </Route>
            <Route exact path="/knowledge/approve-statutory-mapping/:id">
                <ApproveStatutoryMapping />
            </Route>
            <Route exact path="/knowledge/assign-client-unit/:id">
                <AssignClientUnit />
            </Route>
            <Route exact path="/knowledge/approve-assign-statutory-bu/:id">
                <ApproveAssignBulkupload />
            </Route>
        </Switch>
    )
}


export default connect(null)(RoutesLink);
