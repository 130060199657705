import HTTP from '../../../Libs/http';
import {ASSIGN_STATU_UPLOAD_LIST} from '../../types/index'

const initialState = {
    assignstatutorybulkupload: {
        list: [],
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case ASSIGN_STATU_UPLOAD_LIST:
            {
                return {
                    ...state,
                    assignstatutorybulkupload: {
                        ...state.assignstatutorybulkupload,
                        list: payload,
                    },
                };
            }
        default:
            return state;
    }
}