import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import {
  Collapse,
  Select,
  Button,
  Table,
  Tooltip,
  Tabs,
  Input,
  Card,
} from "antd";
import SimpleReactValidator from "simple-react-validator";
import {
  ArrowRightOutlined,
  ArrowLeftOutlined,
  PlusOutlined,
  CloseOutlined,
  PlusCircleOutlined,
  DeleteColumnOutlined,
  EditOutlined,
  DeleteOutlined,
  InfoCircleOutlined,
  ConsoleSqlOutlined,
} from "@ant-design/icons";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { Toaster } from "../../Libs/Toaster";

import { savestatutorymapping, multiplefileupload, multiplefileuploadnotification } from "../../Store/Action/Transactions/Statutorymapping";
import { tab } from "@testing-library/user-event/dist/tab";
import { update } from "lodash";
import Swal from "sweetalert2";

const _ = require("lodash");

const mapStateToProps = (state) => ({
  list: state.Statutorymapping.statutorymap.list,
  info: state.Statutorymapping.statutorymap.info,
  master: state.Statutorymapping.statutorymap.master,
  filedetails: state.Statutorymapping.statutorymap.filedetails,
  filedetailsnotification: state.Statutorymapping.statutorymap.filedetailsnotification
});

const AddStatutoryMapping = ({ info,
  list,
  master,
  savestatutorymapping,
  multiplefileupload, filedetails,
  multiplefileuploadnotification, filedetailsnotification
}) => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const location = useLocation();
  const paramid = location.pathname;
  const [listpage1, setlistpage] = useState(false);
  const { TabPane } = Tabs;
  const { Option, OptGroup } = Select;
  const [key, setKey] = useState("1");
  const [alldetails, setalldetails] = useState([]);
  const [tablevisiable, settablevisiable] = useState("none");
  const [selectcheckbox, Setselectcheckbox] = useState("block");
  const [unselectcheckbox, Setunselectcheckbox] = useState("none");
  const [selectstate, Setselectstate] = useState("block");
  const [unselectstate, Setunselectstate] = useState("none");
  const [selectdistrict, Setselectdistrict] = useState("block");
  const [unselectdistrict, Setunselectdistrict] = useState("none");
  const [selectcity, Setselectcity] = useState("block");
  const [unselectcity, Setunselectcity] = useState("none");
  const [all, setAll] = useState([]);
  const validator = useRef(new SimpleReactValidator());
  const tab2 = useRef(new SimpleReactValidator());
  const tab3 = useRef(new SimpleReactValidator());
  const tab4 = useRef(new SimpleReactValidator());
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [Tab2FormSubmit, setTab2FormSubmit] = useState(false);
  const [Tab3FormSubmit, setTab3FormSubmit] = useState(false);
  const [Tab4FormSubmit, setTab4FormSubmit] = useState(false);
  let [repeatesvalue, setrepeatsvalue] = useState(1);
  const [days, Setdays] = useState([
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22,
    23, 24, 25, 26, 27, 28, 29, 30, 31,
  ]);
  const [months, setmonths] = useState([
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "July",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
  ]);
  const [weekdays, Setweekdays] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
    "End of the week",
  ]);
  const [weekdays1, Setweekdays1] = useState([
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
    "Sunday",
  ]);
  const [formultiple, setformultiple] = useState([]);
  const [filedata, Setfiledata] = useState([]);
  console.log(filedata, 'filedata')
  const [fortmultiple, setfortmultiple] = useState([]);
  const [fortmultiple1, setfortmultiple1] = useState([]);
  const [fileresponse, setfileresponse] = useState();
  const [selectedata, setselecteddata] = useState({
    country: "",
    domain: "",
    domaindata: "",
    organization: [],
    organizationdata: "",
    nature: "",
    naturedata: "",
  });
  const [tab2data, Settab2data] = useState({
    pri_Legislation: "",
    sec_Legislation: "",
    sec_Legislationdata: "",
    pri_mappings: "",
  });

  console.log(tab2data,'tab2datacheck')
  const [tab3data, settab3data] = useState({
    sta_provision: "",
    task: "",
    descriptions: "",
    document: "",
    format: "",
    penal: "",
    link: "",
    frequency: "",
    frequencydata: "",
    law_ref: "",
    effect_date: master && master.statutory_info[0] && moment(master.statutory_info[0].effective_date).format("YYYY-MM-DD"),
    criticality: "",
    criticality_name: "",
    week1days: "",
    week1startday: "",
    week1excludedays: "",
    week1triggerdays: "",
    fortnight: "1",
    fortrepeatevery: "",
    fortrepeatby: "",
    fortdate: "",
    forttriggerdays: "",
    fortsta_date: "",
    fortmultipleselect: "",
    fortshow: "none",
    fortnight1: "2",
    fortrepeatevery1: "",
    fortrepeatby1: "",
    fortdate1: "",
    forttriggerdays1: "",
    fortsta_date1: "",
    fortmultipleselect1: "",
    fortshow1: "none",

    sta_date: "",
    sta_month: "",
    triggerdays: "",
    repeats_every: "",
    repeats_every_label: "",
    repeats_months: "",
    multipleselection: "",
    repeatby: 1,
    tocomplete: "",
    filedetails: '',
    notificationfiledetails: ''
  });

  const [tab4data, settab4data] = useState({
    state: "",
    statedata: "",
    statelabel: "",
    district: "",
    districtdata: "",
    districtlabel: "",
    city: "",
    citydata: "",
    citylabel: "",
    area: "",
    areadata: "",
  });
  const [currentIndex, setCurrentIndex] = useState(false)
  const [paginationSize, setPaginationSize] = useState(25);
  const [notificationfileupload, setNotificationFileUpload] = useState([])
  const [finalNotification, setFinalNotification] = useState([])
  console.log(notificationfileupload, 'notificationfileupload')

  const complianceColumn = [
    {
      title: "#",
      key: "index",
      align: "center",
      width: "50px",
      render: (text, record, index) => {
        return index + 1;
      },
    },
    {
      title: "Statutory Provision",
      dataIndex: "sta_provision",
      key: "sta_provision",
    },
    {
      title: "Task",
      dataIndex: "task",
      key: "task",
    },
    {
      title: "Description",
      dataIndex: "descriptions",
      key: "descriptions",
    },
    {
      title: "Frequency",
      dataIndex: "frequency",
      render: (text, record) => {
        return (
          <>
            {text == 1 ? (
              <span>One Time</span>
            ) : text == 2 ? (
              <span>Periodical</span>
            ) : text == 3 ? (
              <span>Review</span>
            ) : text == 4 ? (
              <span>Flexi Review</span>
            ) : (
              <span>On Occurrence</span>
            )}
          </>
        );
      },
    },
    {
      title: "Repeats",
      dataIndex: "repeats_every",
      key: "repeats_every",
    },
    {
      title: (
        <>
          <label>Actions</label>
          <Tooltip
            className="toolTipClass"
            style={{ marginBotton: "5px", marginleft: "5px" }}
            placement="topRight"
            title={"This Action Includes Edit and Delete"}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "repeats_every",
      width: "100px",
      render: (text, record, index) => {
        return (
          <Fragment>
            <div className="row">
              <div className="col-md-3">
                <span
                  className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                  onClick={() => {
                    editcompliance(index);
                  }}>
                  <Tooltip title="Click here to edit" color="#39cb7f" >
                    <EditOutlined />
                  </Tooltip>
                </span>
              </div>
              <div className="col-md-3">
                <span
                  className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                  onClick={() => {
                    deletecompliance(index);
                  }}>
                  <Tooltip
                    title="Click here to remove compliance"
                    color="#fc4b6c">
                    <DeleteOutlined />
                  </Tooltip>
                </span>
              </div>
            </div>
          </Fragment>
        );
      },
    },
  ];

  const deletecompliance = (e) => {
    Swal.fire({
      title: "Are you sure?",
      text: 'You want to delete',
      icon: "info",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        let removed = alldetails.splice(e);
        setalldetails([...alldetails]);
      }
    });
  };

  const editcompliance = (e) => {
    setCurrentIndex(e)
    let temp = [];
    let arr = [];
    if (alldetails[e].fortmultipleselect == 1) {
      fortmultiple.map((item, i) => {
        temp.push({
          statutorydate: item.statutorydate,
          statutorydays: item.statutorydays,
          triggerdays: item.triggerdays
        })
      })
    }
    if (alldetails[e].fortmultipleselect1 == 1) {
      fortmultiple1.map((item, i) => {
        arr.push({
          statutorydate1: item.statutorydate1,
          statutorydays1: item.statutorydays1,
          triggerdays1: item.triggerdays1
        })
      })
      setfortmultiple([...temp])
      setfortmultiple1([...arr])
    }

    if (alldetails[e].multipleselection == 1) {
      let temarr = [];
      formultiple.map((data, i) => {
        temarr.push({
          conditions_id: data.conditions_id,
          conditions_for: data.condition_for,
          conditions_types: data.condition_type,
          conditions_rules: data.conditions,
        })
      })
      setformultiple([...temarr])
    }


    settab3data({
      ...tab3data,
      sta_provision: alldetails[e].sta_provision,
      task: alldetails[e].task,
      descriptions: alldetails[e].descriptions,
      document: alldetails[e].document,
      format: alldetails[e].format,
      penal: alldetails[e].penal,
      link: alldetails[e].link,
      frequency: alldetails[e].frequency,
      frequencydata: alldetails[e].frequencydata,
      law_ref: alldetails[e].law_ref,
      effect_date: moment(alldetails[e].effect_date).format("YYYY-MM-DD"),
      criticality: alldetails[e].criticality,
      criticality_name: alldetails[e].criticality_name,
      weekid: alldetails[e].weekid,
      week1days: alldetails[e].week1days,
      week1startday: alldetails[e].week1startday,
      week1excludedays: alldetails[e].week1excludedays,
      week1triggerdays: alldetails[e].week1triggerdays,
      fortnight: "1",
      fortrepeatevery: alldetails[e].fortrepeatevery,
      fortrepeatby: alldetails[e].fortrepeatby,
      fortdate: alldetails[e].fortdate,
      forttriggerdays: alldetails[e].forttriggerdays,
      fortsta_date: alldetails[e].fortsta_date,
      fortmultipleselect: alldetails[e].fortmultipleselect,
      fortshow: "none",
      fortnight1: "2",
      fortrepeatevery1: alldetails[e].fortrepeatevery1,
      fortrepeatby1: alldetails[e].fortrepeatby1,
      fortdate1: alldetails[e].fortdate1,
      forttriggerdays1: alldetails[e].forttriggerdays1,
      fortsta_date1: alldetails[e].fortsta_date1,
      fortmultipleselect1: alldetails[e].fortmultipleselect1,
      fortshow1: "none",
      sta_date: alldetails[e].sta_date,
      sta_month: alldetails[e].sta_month,
      triggerdays: alldetails[e].triggerdays,
      repeats_every: alldetails[e].repeats_every,
      repeats_every_label: alldetails[e].repeats_every_label,
      repeats_months: alldetails[e].repeats_months,
      multipleselection: alldetails[e].multipleselection,
      repeatby: alldetails[e].repeatby,
      tocomplete: alldetails[e].tocomplete,
      indexNo: e,
    })
  }

  useEffect(() => {
    validator.current.showMessages();
    tab2.current.showMessages();
    tab3.current.showMessages();
    tab4.current.showMessages();
  }, []);

  useEffect(() => {
    if (info) {
      const state = _.filter(info.geography_info, { l_position: 1 });
      settab4data({
        ...tab4data,
        statedata: state,
      });
    }
  }, [info]);

  useEffect(() => {
    if (selectedata.country && selectedata.country != "") {
      const domain = _.filter(info.domain_info, {
        c_id: parseInt(selectedata.country.value),
      });
      const nature = _.filter(info.nature_info, {
        c_id: parseInt(selectedata.country.value),
      });
      setselecteddata({
        ...selectedata,
        domaindata: domain,
        naturedata: nature,
      });
    }
  }, [selectedata.country]);

  useEffect(() => {
    if (selectedata.domain && selectedata.domain != "") {
      const organisation = _.filter(info.organisation_info, {
        c_id: parseInt(selectedata.country.value),
        d_id: parseInt(selectedata.domain.value),
      });
      setselecteddata({
        ...selectedata,
        organizationdata: organisation,
      });
    }
  }, [selectedata.domain]);

  useEffect(() => {
    if (tab2data.pri_Legislation && tab2data.pri_Legislation != "") {
      const secondary = _.filter(master.statutory_info, {
        p_id: parseInt(tab2data.pri_Legislation.value),
      });
      Settab2data({
        ...tab2data,
        sec_Legislationdata: secondary,
      });
    }
  }, [tab2data.pri_Legislation]);

  const [org_details, setorg_details] = useState({
    listof_org: [],
  });
  const HandelChange = (obj) => {
    let allArray = [];
    if (obj.includes("all")) {
      if (
        selectedata &&
        selectedata.organizationdata &&
        selectedata.organizationdata.length > 0
      ) {
        let temp = [];
        for (let i in selectedata.organizationdata) {
          let Code = selectedata.organizationdata[i].org_id;
          temp.push(Code);
          allArray.push(Code);
        }
        setAll(temp);
        setselecteddata({
          ...selectedata,
          organization: allArray,
        });
      }
      Setselectcheckbox("none");
      Setunselectcheckbox("block");
    } else if (obj.includes("unselect")) {
      let temp = [];
      setselecteddata({
        ...selectedata,
        organization: [],
      });
      setAll(temp);
      Setselectcheckbox("block");
      Setunselectcheckbox("none");
    } else if (obj.includes("unselect")) {
      let temp = [];
      setAll(temp);
      Setselectcheckbox("block");
      Setunselectcheckbox("none");
    } else {
      if (obj.length == selectedata.organizationdata.length) {
        Setselectcheckbox("none");
        Setunselectcheckbox("block");
      } else {
        Setselectcheckbox("block");
        Setunselectcheckbox("none");
      }
      setAll(obj);
      setselecteddata({
        ...selectedata,
        organization: obj,
      });
    }
  };

  const Handelstate = (obj) => {
    let allArray = [];
    let alllabel = [];
    if (obj.includes("all")) {
      if (tab4data.statedata && tab4data.statedata.length > 0) {
        let temp = [];
        for (let i in tab4data.statedata) {
          let Code = tab4data.statedata[i].g_id;
          let label = tab4data.statedata[i];
          temp.push(Code);
          allArray.push(Code);
          alllabel.push(label);
        }

        settab4data({
          ...tab4data,
          state: allArray,
          statelabel: alllabel,
        });
      }
      Setselectstate("none");
      Setunselectstate("block");
    } else if (obj.includes("unselect")) {
      let temp = [];
      settab4data({
        ...tab4data,
        state: [],
        statelabel: "",
      });
      Setselectstate("block");
      Setunselectstate("none");
    } else if (obj.includes("unselect")) {
      let temp = [];
      Setselectstate("block");
      Setunselectstate("none");
    } else {
      if (obj.length == tab4data.statedata.length) {
        Setselectstate("none");
        Setunselectstate("block");
      } else {
        Setselectstate("block");
        Setunselectstate("none");
      }
      let arr = [];
      for (let i in obj) {
        const label = _.filter(tab4data.statedata, { g_id: obj[i] });
        arr.push(label);
      }
      const concat = [].concat.apply([], arr);
      settab4data({
        ...tab4data,
        state: obj,
        statelabel: concat,
      });
    }
  };

  const Handeldistrict = (obj) => {
    let allArray = [];
    let alllabel = [];

    if (obj.includes("all")) {
      if (tab4data.districtdata && tab4data.districtdata.length > 0) {
        let temp = [];
        for (let i in tab4data.districtdata) {
          for (let j in tab4data.districtdata[i]) {
            let Code = tab4data.districtdata[i][j].g_id;

            let label = tab4data.districtdata[i][j];
            temp.push(Code);
            allArray.push(Code);
            alllabel.push(label);
          }
        }
        settab4data({
          ...tab4data,
          district: allArray,
          districtlabel: alllabel,
        });
      }
      Setselectdistrict("none");
      Setunselectdistrict("block");
    } else if (obj.includes("unselect")) {
      let temp = [];
      settab4data({
        ...tab4data,
        district: [],
        districtlabel: "",
      });
      Setselectdistrict("block");
      Setunselectdistrict("none");
    } else if (obj.includes("unselect")) {
      let temp = [];
      Setselectdistrict("block");
      Setunselectdistrict("none");
    } else {
      if (obj.length == tab4data.districtdata.length) {
        Setselectdistrict("none");
        Setunselectdistrict("block");
      } else {
        Setselectdistrict("block");
        Setunselectdistrict("none");
      }
      let arr = [];
      let data_index = "";
      for (let i in obj) {
        for (let j in tab4data.districtdata) {
          let keyindex = _.findIndex(tab4data.districtdata[j], function (o) {
            return o.g_id == obj[i];
          });
          if (keyindex >= 0) data_index = j;
        }
        let filter = _.filter(tab4data.districtdata[data_index], {
          g_id: parseInt(obj[i]),
        });
        arr.push(filter[0]);
      }
      settab4data({
        ...tab4data,
        district: obj,
        districtlabel: arr,
      });
    }
  };

  const Handelcity = (obj) => {
    let allArray = [];
    let alllabel = [];

    if (obj.includes("all")) {
      if (tab4data.citydata && tab4data.citydata.length > 0) {
        let temp = [];
        for (let i in tab4data.citydata) {
          for (let j in tab4data.citydata[i]) {
            let Code = tab4data.citydata[i][j].g_id;

            let label = tab4data.citydata[i][j];
            temp.push(Code);
            allArray.push(Code);
            alllabel.push(label);
          }
        }

        settab4data({
          ...tab4data,
          city: allArray,
          citylabel: alllabel,
        });
      }
      Setselectcity("none");
      Setunselectcity("block");
    } else if (obj.includes("unselect")) {
      let temp = [];
      settab4data({
        ...tab4data,
        city: [],
        citylabel: "",
      });
      Setselectcity("block");
      Setunselectcity("none");
    } else if (obj.includes("unselect")) {
      let temp = [];
      Setselectcity("block");
      Setunselectcity("none");
    } else {
      if (obj.length == tab4data.citydata.length) {
        Setselectcity("none");
        Setunselectcity("block");
      } else {
        Setselectcity("block");
        Setunselectcity("none");
      }
      let arr = [];
      let data_index = "";
      for (let i in obj) {
        for (let j in tab4data.citydata) {
          let keyindex = _.findIndex(tab4data.citydata[j], function (o) {
            return o.g_id == obj[i];
          });
          if (keyindex >= 0) data_index = j;
        }
        let filter = _.filter(tab4data.citydata[data_index], {
          g_id: parseInt(obj[i]),
        });
        arr.push(filter[0]);
      }
      settab4data({
        ...tab4data,
        city: obj,
        citylabel: arr,
      });
    }
  };

  const Next1 = () => {
    setAddFormSubmit(true);
    if (validator.current.allValid()) {
      setKey("2");
    }
  };

  const Next2 = () => {
    setTab2FormSubmit(true);
    if (tab2.current.allValid()) {
      setKey("3");
    }
  };
  const previous1 = () => {
    setKey("1");
  };

  const tab4pre = () => {
    setKey("3");
  };
  const [tabtempdata, settabtempdata] = useState();
  const tab3next = () => {
    if (alldetails && alldetails.length > 0) {

      let temparray = [];
      let fortnight = [];
      for (let i in alldetails) {
        let statu_dates = [];
        let weeks;
        let obj = {}
        let fortobj = {}
        let fortobj1 = {}
        let finaldata = '';
        let finaldata1 = '';

        let tempfortarray = [];
        let tempfortarray2 = [];
        console.log(alldetails[i].notificationfiledetails.length, 'notificationfileuploaddsd')

        /*Notification file Upload */
        if (alldetails[i].notificationfiledetails && alldetails[i].notificationfiledetails.length > 0) {
          const formData = new FormData();
          for (let m = 0; m < alldetails[i].notificationfiledetails.length; m++) {
            console.log(alldetails[i].notificationfiledetails[m], 'alldetails[i].notificationfiledetails[m]')
            formData.append("file" + [m], alldetails[i].notificationfiledetails[m]);
          }
          console.log(formData, 'notificationfileupload1')

          multiplefileuploadnotification({
            payload: formData,
            paramid: paramid
          })
        }
        /*Notification file Upload */

        //Statutory Dates
        if (alldetails[i].multipleselection == 1) {
          for (let j in repeat_multipleselect) {
            const data = {
              "statutory_date": repeat_multipleselect[j].sta_days,
              "statutory_month": repeat_multipleselect[j].sta_month,
              "trigger_before_days": parseInt(repeat_multipleselect[j].triggerdays),
              "repeat_by": alldetails[i].repeatby
            }
            statu_dates.push(data);
          }
        } else {
          if (alldetails[i].triggerdays != '') {
            const data = {
              "statutory_date": alldetails[i].sta_date,
              "statutory_month": alldetails[i].sta_month,
              "trigger_before_days": parseInt(alldetails[i].triggerdays),
              "repeat_by": alldetails[i].repeatby
            }
            statu_dates.push(data);
          } else {
            statu_dates = []
          }


        }
        //Weeks
        if (alldetails[i].week1days == '') {
          weeks = []
        } else {
          weeks = [
            {
              "statutory_week": alldetails[i].weekid,
              "statutory_day": alldetails[i].week1days,
              "statutory_start_day": alldetails[i].week1startday,
              "statutory_ex_days": alldetails[i].week1excludedays,
              "trigger_before_days": parseInt(alldetails[i].week1triggerdays)
            }
          ]
        }

        //FortNights
        if (alldetails[i].repeats_every_label == 'Fortnight(s)') {
          if (alldetails[i].fortmultipleselect == 1) {
            for (let k = 0; k < fortmultiple.length; k++) {
              let fort1 = {
                "month_id": fortmultiple[k].statutorydate,
                "fortnight_id": 1,
                "repeat_by_id": alldetails[i].fortrepeatby,
                "end_of_fortnight_id": alldetails[i].fortrepeatevery,
                "date_id": fortmultiple[k].statutorydays,
                "trigger_before_days": parseInt(fortmultiple[k].triggerdays)
              }
              tempfortarray.push(fort1);
            }
          } else {
            let fort1 = {
              "month_id": '',
              "fortnight_id": 1,
              "repeat_by_id": alldetails[i].fortrepeatby,
              "end_of_fortnight_id": alldetails[i].fortrepeatevery,
              "date_id": alldetails[i].fortdate,
              "trigger_before_days": parseInt(alldetails[i].forttriggerdays)
            }
            tempfortarray.push(fort1);
          }
        }

        if (alldetails[i].repeats_every_label == 'Fortnight(s)') {
          if (alldetails[i].fortmultipleselect1 == 1) {
            for (let k = 0; k < fortmultiple1.length > 0; k++) {
              let fort2 = {
                "month_id": fortmultiple1[k].statutorydate,
                "fortnight_id": 2,
                "repeat_by_id": alldetails[i].fortrepeatby1,
                "end_of_fortnight_id": alldetails[i].fortrepeatevery1,
                "date_id": fortmultiple1[k].statutorydays,
                "trigger_before_days": parseInt(fortmultiple1[k].triggerdays)
              }
              tempfortarray2.push(fort2);
            }
          } else {
            let fort2 = {
              "month_id": '',
              "fortnight_id": 2,
              "repeat_by_id": alldetails[i].fortrepeatby1,
              "end_of_fortnight_id": alldetails[i].fortrepeatevery1,
              "date_id": alldetails[i].fortdate1,
              "trigger_before_days": parseInt(alldetails[i].forttriggerdays1)
            }
            tempfortarray2.push(fort2);
          }
        }


        const data = {
          "comp_id": null,
          "action_type": 0,
          "s_provision": alldetails[i].sta_provision,
          "comp_task": alldetails[i].task,
          "description": alldetails[i].descriptions,
          "doc_name": '',
          "p_consequences": alldetails[i].penal,
          "c_law_ids": alldetails[i].law_ref,
          "reference": alldetails[i].link,
          "effective_date": moment(alldetails[i].effect_date).format("DD-MMM-YYYY"),
          "f_id": parseInt(alldetails[i].frequency),
          "criticality_id": parseInt(alldetails[i].criticality),
          "d_type_id": null,
          "duration": null,
          "r_type_id": null,
          "r_every": null,
          "is_approved": "",
          "is_active": true,
          "statu_dates": statu_dates,
          "statu_weeks": weeks,
          "statu_fortnights": [].concat(tempfortarray, tempfortarray2),
          "frequency": alldetails[i].frequencydata,
          "criticality_name": alldetails[i].criticality_name,
          "summary": "",
          "f_f_list": [],
          "is_file_removed": false,
          "is_notification_file_removed": false


        }
        temparray.push(data);
      }
      setNotificationFileUpload([])
      settabtempdata(temparray);
      setKey("4");
    } else {
      Toaster.error("Atleast one Compliance should be added");
    }
  };
  const tab3pre = () => {
    setKey("2");
  };

  useEffect(() => {
    if (tab4data.state) {
      let alldata;
      let temp = [];
      for (let i in tab4data.state) {
        const id = tab4data.state[i];
        alldata = _.filter(info.geography_info, { p_id: parseInt(id) });
        temp.push(alldata);
      }
      settab4data({
        ...tab4data,
        districtdata: temp,
      });
    }
  }, [tab4data.state]);

  useEffect(() => {
    if (tab4data.district) {
      let alldata;
      let temp = [];
      for (let i in tab4data.district) {
        const id = tab4data.district[i];
        alldata = _.filter(info.geography_info, { p_id: parseInt(id) });
        temp.push(alldata);
      }
      settab4data({
        ...tab4data,
        citydata: temp,
      });
    }
  }, [tab4data.district]);

  const addcompliacne = () => {
    setTab3FormSubmit(true);
    let temp = [];
    if (tab3.current.allValid()) {
      if (alldetails.length > 0) {
        if (alldetails[currentIndex]) {
          alldetails[currentIndex] = tab3data
          setalldetails([...alldetails]);
          setCurrentIndex(false)
        } else {
          setalldetails([...alldetails, tab3data]);

        }
        settablevisiable("block");
        setTab3FormSubmit(false);
        setCurrentIndex(false)
      } else {
        temp.push(tab3data);
        for (let i of temp) {
          i.filedetails = filedata
        }
        for (let j of temp) {
          j.notificationfiledetails = notificationfileupload
        }
        setalldetails(temp);
        settablevisiable("block");
        setTab3FormSubmit(false);
      }
      compliancestate();
      //}
      //}
    }
  };



  const compliancestate = () => {
    settab3data({
      ...tab3data,
      sta_provision: "",
      task: "",
      descriptions: "",
      document: "",
      format: "",
      penal: "",
      link: "",
      frequency: "",
      frequencydata: "",
      law_ref: "",
      effect_date: master && master.statutory_info[0] && moment(master.statutory_info[0].effective_date).format("YYYY-MM-DD"),
      criticality: "",
      criticality_name: "",
      week1days: "",
      week1startday: "",
      week1excludedays: "",
      week1triggerdays: "",
      fortnight: "1",
      fortrepeatevery: "",
      fortrepeatby: "",
      fortdate: "",
      forttriggerdays: "",
      fortsta_date: "",
      fortmultiple: "",
      fortshow: "none",
      fortnight1: "2",
      fortrepeatevery1: "",
      fortrepeatby1: "",
      fortdate1: "",
      forttriggerdays1: "",
      fortsta_date1: "",
      fortmultiple1: "",
      fortshow1: "none",
      sta_date: "",
      sta_month: "",
      triggerdays: "",
      repeats_every: "",
      repeats_every_label: "",
      repeats_months: "",
      multipleselection: "",
      repeatby: 1,
      tocomplete: "",
      week_days: "",
      week_start: "",
      week_exclude: "",
    });
    Setfiledata([]);
    setNotificationFileUpload([])

  };

  const addstatutory = (b_type) => {
    console.log(alldetails,'inserting into')
    setTab4FormSubmit(true);
    if (tab4.current.allValid()) {
      for(let jm in alldetails){
        if (alldetails && alldetails[jm].filedetails.length > 0) {
          const formData = new FormData();
          for (let i = 0; i < alldetails[jm].filedetails.length; i++) {
            formData.append("file" + [i], alldetails[jm].filedetails[i].fileformat);
          }
          multiplefileupload({
            payload: formData,
            paramid: paramid
          })
        } else {
          savestatutory('', b_type);
        }
      }
     
    }
  }

  useEffect(() => {
    let array = [];
    if (filedetails) {
      for (let i in filedetails.file_list) {
        const filepay = {
          "file_size": filedetails.file_list[i].file_size,
          "file_name": filedetails.file_list[i].file_name,
          "file_content": filedetails.file_list[i].file_content,
          "doc_name": "testing"
        }
        array.push(filepay);
      }
      setfileresponse(array);
      savestatutory(array,'')
    }
  }, [filedetails])

  useEffect(() => {
    let arraytemp = [];
    if (filedetailsnotification) {
      for (let j in filedetailsnotification.notification_file_list) {
        const filepaynotify = {
          "file_size": filedetailsnotification.notification_file_list[j].file_size,
          "file_name": filedetailsnotification.notification_file_list[j].file_name,
          "file_content": filedetailsnotification.notification_file_list[j].file_content
        }
        arraytemp.push(filepaynotify);
      }
      setFinalNotification([...arraytemp])
    }
  }, [filedetailsnotification])


  const addfile = () => {
    Setfiledata([...filedata, ...[{ compliance: "", fileformat: "" }]]);
  };
  let [repeat_multipleselect, setrepeat_multipleselect] = useState([]);

  const addmultipledates = () => {
    let display_array = []
    let multiple_array = []
    const years = 12 / tab3data.repeats_every
    if (repeatesvalue == 1) {
      for (let i = 0; i < years; i++) {
        const abc =
          <tr>
            <td className="d-flex">
              <Select
                allowClear={false}
                size="default"
                placeholder="Select Month"
                name="sta_month"
                className="form-control"
                style={{ width: "90px" }}
                onChange={(e) => {
                  repeat_multiple(e, i, 'select', 'sta_month')
                }} >
                {
                  months && months.map((item, i) => {
                    return <Option value={i + 1}>{item}</Option>
                  })
                }

              </Select>
              &nbsp;&nbsp;&nbsp;
              <Select
                allowClear={false}
                size="default"
                placeholder="Select Days"
                name="sta_date"
                className="form-control"
                style={{ width: "90px" }}
                onChange={(e) => {
                  repeat_multiple(e, i, 'select', 'sta_date')
                }}
              >
                {days &&
                  days.length > 0 &&
                  days.map((item) => {
                    return (
                      <Option value={item}>
                        {item}
                      </Option>
                    );
                  })}
              </Select>
            </td>
            <td className="pl-2">
              <Input
                type="text"
                name="Trigger Days"
                autoComplete={"off"}
                placeholder="Trigger Before Day(s)"
                style={{ width: "170px" }}
                onKeyPress={(event) => {
                  if (!/[0-9 +]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxlength="3"
                onChange={(e) => {
                  repeat_multiple(e, i, 'input', 'triggerdays')
                }}
              />
            </td>
          </tr>
        display_array.push(abc);
        multiple_array.push({
          sta_month: '',
          sta_days: '',
          triggerdays: ''
        })
      }
      repeat_multipleselect = multiple_array;
      setrepeat_multipleselect([...multiple_array])
    } else {
      for (let i = 0; i < years; i++) {
        const abc =
          <tr>
            <td className="d-flex">
              <Select
                allowClear={false}
                size="default"
                placeholder="Select Month"
                name="sta_month"
                className="form-control"
                style={{ width: "90px" }}
                onChange={(e) => {
                  repeat_multiple(e, i, 'select', 'sta_month')
                }}
              >
                {
                  months && months.map((item, i) => {
                    return <Option value={i + 1}>{item}</Option>
                  })
                }

              </Select>

            </td>
            <td className="pl-2">
              <Input
                type="text"
                name="Trigger Days"
                autoComplete={"off"}
                placeholder="Trigger Before Day(s)"
                style={{ width: "170px" }}
                onKeyPress={(event) => {
                  if (!/[0-9 +]/.test(event.key)) {
                    event.preventDefault();
                  }
                }}
                maxlength="3"
                onChange={(e) => {
                  repeat_multiple(e, i, 'input', 'triggerdays')
                }}
              />
            </td>
          </tr>
        display_array.push(abc);
      }
    }

    setformultiple(display_array);
  }

  const addmultiple = () => {
    setfortmultiple([
      ...fortmultiple, ...[{ statutorydate: "", statutorydays: '', triggerdays: "" }],
    ]);
  };
  const addmultiple1 = () => {
    setfortmultiple1([
      ...fortmultiple1, ...[{ statutorydate1: "", statutorydays1: '', triggerdays1: "" }],
    ]);
  };

  const onconditionChange = (e, i, type) => {
    let propertyName = e.target.name;
    let inputValue = e.target.value;

    let newArray = filedata;
    for (let j in newArray) {
      if (i == j) {
        if (type == "input") {
          newArray[j][propertyName] = inputValue;
        }
        if (type == "file") {
          var results = [];
          const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/;
          let files = e.target.files;
          let file_max_size = 26214400;
          for (var i = 0; i < files.length; i++) {
            var file = files[i];
            var file_name = file.name;
            const f_name = file_name.substring(0, file_name.lastIndexOf("."));
            if (fileRegex.test(f_name) === false) {
              Toaster.error("Invalid Format");
              return;
            }
            let file_size = file.size;
            if (file_size > file_max_size) {
              Toaster.error("Maximum File Sixe is 25MB");
              return;
            }
          }
          newArray[j][propertyName] = file;
        }
        break;
      }
    }
    Setfiledata([...newArray]);
  };

  const onstat_map = (e, i, type, e_name) => {
    let propertyName;
    let inputValue;
    if (e_name == "statutorydate") {
      propertyName = 'statutorydate'
      inputValue = e;
    } else if (e_name == "statutorydays") {
      propertyName = 'statutorydays'
      inputValue = e;
    }
    else {
      propertyName = e.target.name;
      inputValue = e.target.value;
    }
    let newArray = fortmultiple;
    for (let j in newArray) {
      if (i == j) {
        if (type == "input") {
          newArray[j][propertyName] = inputValue;
        }
        if (type == "select") {
          newArray[j][propertyName] = inputValue;
        }
        break;
      }
    }
    setfortmultiple([...newArray]);
  };

  const onstat_map1 = (e, i, type, e_name) => {
    let propertyName;
    let inputValue;
    if (e_name == "statutorydate") {
      propertyName = 'statutorydate'
      inputValue = e;
    } else if (e_name == "statutorydays") {
      propertyName = 'statutorydays'
      inputValue = e;
    }
    else {
      propertyName = e.target.name;
      inputValue = e.target.value;
    }

    let newArray = fortmultiple1;
    for (let j in newArray) {
      if (i == j) {
        if (type == "input") {
          newArray[j][propertyName] = inputValue;
        }
        if (type == "select") {
          newArray[j][propertyName] = inputValue;
        }
        break;
      }
    }
    setfortmultiple1([...newArray]);
  };



  const savestatutory = (filelist, b_type) => {
    let tempArray = tabtempdata
    if (filelist && filelist.length > 0) {
      for (let i of tempArray) {
        i.f_f_list = filelist
      }
    }
    if(finalNotification && finalNotification.length > 0){
      for (let j of tempArray) {
        j.notification_f_f_list = finalNotification
      }
    }
    settabtempdata(tempArray)
    const datas = [].concat(tab4data.state, tab4data.district, tab4data.city);
    let statedata = datas.filter(function (el) {
      return el != '';
    });

    let temarrCity = [];
    let temarrDistrict = [];
    let temarrState = [];
    let level_1 = [];

    for (let i = 0; i < statedata.length; i++) {
      const data = _.find(info.geography_info, { g_id: statedata[i] })
      if (data && data.l_position == 3) {
        temarrCity.push(data.g_id)
        temarrDistrict.push(data.p_id)
      }
      const level_2 = _.find(info.geography_info, { g_id: statedata[i] })
      if (level_2 && level_2.l_position == 2) {
        temarrState.push(level_2.g_id)
        temarrDistrict.push(level_2.p_id)
      }
      const level_1Array = _.find(info.geography_info, { g_id: statedata[i] })
      if (level_1Array && level_1Array.l_position == 1) {
        level_1.push(level_1Array.g_id)
      }
    }
    let updated = temarrState.filter(e => !temarrDistrict.includes(e))
    temarrCity.push(updated)

    let finalUpdated = level_1.filter(e => !temarrDistrict.includes(e))
    temarrCity.push(finalUpdated)


    const pay = {
      "session_token": authtoken,
      "request": [
        "SaveStatutoryMapping",
        {
          "c_id": parseInt(selectedata.country.value),
          "d_id": parseInt(selectedata.domain.value),
          "i_ids": selectedata.organization,
          "s_n_id": parseInt(selectedata.nature.value),
          "s_ids": [parseInt(tab2data.pri_Legislation.value)],
          "g_ids": [].concat.apply([], temarrCity),//statedata,
          "mappings": tab2data.sec_Legislation != "" ? [tab2data.sec_Legislation.children] : [],
          "compliances": tempArray,
          "tr_type": b_type == 'save' ? 0 : 1
        }
      ]
    }

    savestatutorymapping({
      payload: pay,
      paramid: paramid
    })
  };

  const listscreen = () => {
    window.location.reload();
  }
  const repeat_multiple = (e, i, type, e_name) => {
    let propertyName;
    let inputValue;
    let newArray = repeat_multipleselect;
    let ab;

    if (type == "select") {
      propertyName = e_name;
      inputValue = e;
      if (e_name == 'sta_month') {
        newArray[i].sta_month = inputValue;
      } else {
        newArray[i].sta_days = inputValue;
      }

    } else {
      propertyName = e_name;
      inputValue = e.target.value;
      newArray[i].triggerdays = inputValue;
    }

    setrepeat_multipleselect([...newArray]);
  };

  const uploadnotificationdoc = (e) => {
    var results = [];
    const fileRegex = /^[a-zA-Z0-9-_& ,.+#()[-\]{}$^=%@]*$/
    let files = e.target.files;
    let file_max_size = 26214400
    const formData = new FormData();
    console.log(e, 'fileslength')
    let tempfilenotify = []
    for (var i = 0; i < files.length; i++) {
      tempfilenotify.push(files[i])
    }
    setNotificationFileUpload([...tempfilenotify])
  }


  return (
    <>
      {listpage1 == false ? (
        <div className="page-wrapper">
          <div className="page-titles pb-2 pt-2">
            <div className="row">
              <div className="col-lg-8 col-md-6 col-12 align-self-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 d-flex align-items-center">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">
                        <i className="ri-home-3-line fs-5"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link>Transaction</Link>
                    </li>
                    <li
                      className="breadcrumb-item active breadcrumsheader"
                      aria-current="page"
                    >
                      <Link>Statutory Mapping- Add</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-1">
            <div className="row">
              <div className="col-12">
                <div className="card">
                  <div className="card-body">
                    <Tabs activeKey={key} type="card">
                      <TabPane tab="Select Domain" key="1">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label>
                                <b>Country:</b>
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label>
                                <b>Domain:</b>
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label>
                                <b>Organization:</b>
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  placeholder="Select Country"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(data, value) => {
                                    setselecteddata({
                                      ...selectedata,
                                      country: value,
                                    });
                                  }}
                                >
                                  {info &&
                                    info.country_info.length > 0 &&
                                    info.country_info.map((item) => {
                                      return (
                                        <Option key={item.c_id}>
                                          {item.c_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {validator.current.message(
                                  "Country",
                                  selectedata.country,
                                  "required",
                                  {
                                    className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Country Name Required",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  placeholder="Select Domain"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(data, value) => {
                                    setselecteddata({
                                      ...selectedata,
                                      domain: value,
                                    });
                                  }}
                                >
                                  {selectedata.domaindata &&
                                    selectedata.domaindata.length > 0 &&
                                    selectedata.domaindata.map((item) => {
                                      return (
                                        <Option key={item.d_id}>
                                          {item.d_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {validator.current.message(
                                  "Domain",
                                  selectedata.domain,
                                  "required",
                                  {
                                    className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Compfie Domain Name Required",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  mode="multiple"
                                  value={all || undefined}
                                  placeholder="Select Organization"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  maxTagCount="responsive"
                                  onChange={(option) => HandelChange(option)}
                                >
                                  {selectedata.organizationdata.length > 0 ? (
                                    <>
                                      <Option
                                        key="all"
                                        value="all"
                                        style={{ display: selectcheckbox }}
                                      >
                                        ---SELECT ALL---
                                      </Option>
                                      <Option
                                        key="unselect"
                                        value="unselect"
                                        style={{ display: unselectcheckbox }}
                                      >
                                        --UNSELECT ALL--
                                      </Option>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {selectedata.organizationdata &&
                                    selectedata.organizationdata.length > 0 &&
                                    selectedata.organizationdata.map((item) => {
                                      return (
                                        <Option
                                          value={item.org_id}
                                          key={item.org_id}
                                        >
                                          {item.org_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {validator.current.message(
                                  "Organization",
                                  selectedata.organization,
                                  "required",
                                  {
                                    className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Organization Required",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <label>
                                <b>Statutory Nature:</b>
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  placeholder="Select StatutoryNature"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(data, value) => {
                                    setselecteddata({
                                      ...selectedata,
                                      nature: value,
                                    });
                                  }}
                                >
                                  {selectedata.naturedata &&
                                    selectedata.naturedata.length > 0 &&
                                    selectedata.naturedata.map((item) => {
                                      return (
                                        <Option key={item.s_n_id}>
                                          {" "}
                                          {item.s_n_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {validator.current.message(
                                  "StatutoryNature",
                                  selectedata.nature,
                                  "required",
                                  {
                                    className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "Statutory Nature Required",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                          </div>
                          <Button
                            type="primary"
                            shape="round"
                            className="addbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              float: "left", 'margin-top': '24px'
                            }}
                            icon={<ArrowLeftOutlined />}
                            size="default"
                            onClick={listscreen}
                          >
                            Previous
                          </Button>
                          <Button
                            type="primary"
                            shape="round"
                            className="addbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              float: "right", 'margin-top': '24px'
                            }}
                            size="default"
                            onClick={Next1}
                          >
                            Next <ArrowRightOutlined />
                          </Button>
                        </div>
                      </TabPane>
                      <TabPane tab="Select Statutory" key="2">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label>
                                <b>Primary Legislation:</b>
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label>
                                <b>Secondary Legislation:</b>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "10px" }}>
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  placeholder="Primary Legislation"
                                  showSearch
                                  optionFilterProp="children"
                                  value={tab2data.pri_Legislation || undefined}
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(data, value) => {
                                    Settab2data({
                                      ...tab2data,
                                      pri_Legislation: value,
                                      pri_mappings: data.children,
                                      sec_Legislation: ''
                                    });
                                  }}
                                >
                                  {master &&
                                    master.statutory_info.length > 0 &&
                                    master.statutory_info.map((item) => {
                                      return (
                                        <Option key={item.s_id}>
                                          {item.s_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {tab2.current.message(
                                  "Primary Legislation",
                                  tab2data.pri_Legislation,
                                  "required",
                                  {
                                    className: `invalid-feedback ${Tab2FormSubmit ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required:
                                        "Atleast one Statute should be selected",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  placeholder="Secondary Legislation"
                                  value={tab2data.sec_Legislation || undefined}
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(data, value) => {
                                    Settab2data({
                                      ...tab2data,
                                      sec_Legislation: value,
                                    });
                                  }}
                                >
                                  {tab2data.sec_Legislationdata &&
                                    tab2data.sec_Legislationdata.length > 0 &&
                                    tab2data.sec_Legislationdata.map((item) => {
                                      return (
                                        <Option key={item.s_id}>
                                          {item.s_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                          </div>
                          <Button
                            type="primary"
                            shape="round"
                            className="addbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              float: "left",
                              "margin-top": "35px",
                            }}
                            icon={<ArrowLeftOutlined />}
                            size="default"
                            onClick={previous1}
                          >
                            Previous
                          </Button>
                          <Button
                            type="primary"
                            shape="round"
                            className="addbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              float: "right",
                              "margin-top": "35px",
                            }}

                            size="default"
                            onClick={Next2}
                          >
                            Next <ArrowRightOutlined />
                          </Button>
                        </div>
                      </TabPane>
                      <TabPane tab="Compliance" key="3">
                        <div className="row">
                          <div className="col-lg-12">
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Statutory Provision:</b>
                                  </label>
                                  <span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="text"
                                    id="stat_provision"
                                    name="stat_provision"
                                    value={tab3data.sta_provision}
                                    autoComplete={"off"}
                                    placeholder="Enter StatutoryProvision"
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        sta_provision: e.target.value,
                                      });
                                    }}
                                  />
                                  {tab3.current.message(
                                    "stat_provision",
                                    tab3data.sta_provision,
                                    "required",
                                    {
                                      className: `invalid-feedback ${Tab3FormSubmit ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required:
                                          "Statutory Provision Required",
                                      },
                                    }
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Compliance Task:</b>
                                  </label>
                                  <span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="text"
                                    id="comp_task"
                                    name="comp_task"
                                    autoComplete={"off"}
                                    value={tab3data.task}
                                    placeholder="Enter Compliance Task"
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        task: e.target.value,
                                      });
                                    }}
                                  />
                                  {tab3.current.message(
                                    "comp_task",
                                    tab3data.task,
                                    "required",
                                    {
                                      className: `invalid-feedback ${Tab3FormSubmit ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required: "Compliance Task Required",
                                      },
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Compliance Description :</b>
                                  </label>
                                  <span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="text"
                                    id="description"
                                    name="description"
                                    autoComplete={"off"}
                                    value={tab3data.descriptions}
                                    placeholder="Enter Compliance Description"
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        descriptions: e.target.value,
                                      });
                                    }}
                                  />
                                  {tab3.current.message(
                                    "description",
                                    tab3data.descriptions,
                                    "required",
                                    {
                                      className: `invalid-feedback ${Tab3FormSubmit ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required:
                                          "Compliance Description Required",
                                      },
                                    }
                                  )}
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Penal Consequences:</b>
                                  </label>
                                  <Input
                                    type="text"
                                    id="stat_date"
                                    name="stat_date"
                                    autoComplete={"off"}
                                    value={tab3data.penal}
                                    placeholder="Enter Penal Consequences"
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        penal: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Reference Link:</b>
                                  </label>
                                  <Input
                                    type="text"
                                    id="stat_date"
                                    name="stat_date"
                                    autoComplete={"off"}
                                    value={tab3data.link}
                                    placeholder="http://www.domain.com"
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        link: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Compliance Frequency :</b>
                                  </label>
                                  <span style={{ color: "red" }}>*</span>
                                  <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Select Compliance Frequency"
                                    name="frequency"
                                    className="form-control"
                                    id="frequency"
                                    value={tab3data.frequency || undefined}
                                    onChange={(data, value) => {
                                      settab3data({
                                        ...tab3data,
                                        frequency: value.value,
                                        frequencydata: value.children,
                                        repeats_every: "",
                                        repeats_months: '',
                                        repeats_every_label: ''
                                      });
                                      setfortmultiple([
                                        {
                                          ...fortmultiple,
                                          fortmultiple: "",
                                        },
                                      ]);
                                      setfortmultiple1([
                                        {
                                          ...fortmultiple1,
                                          fortmultiple1: "",
                                        },
                                      ]);
                                    }}
                                  >
                                    <Option value="1">One Time</Option>
                                    <Option value="2">Periodical</Option>
                                    <Option value="3">Review</Option>
                                    <Option value="4">Flexi Review</Option>
                                    <Option value="5">On Occurrence</Option>
                                  </Select>
                                  {tab3.current.message(
                                    "frequency",
                                    tab3data.frequency,
                                    "required",
                                    {
                                      className: `invalid-feedback ${Tab3FormSubmit ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required:
                                          "Compliance Frequency Required",
                                      },
                                    }
                                  )}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Compfie Law Reference:</b>
                                  </label>
                                  <Input
                                    type="text"
                                    id="stat_date"
                                    name="stat_date"
                                    autoComplete={"off"}
                                    value={tab3data.law_ref}
                                    placeholder="CompfieLaw ID"
                                    onKeyPress={(event) => {
                                      if (!/[0-9 +]/.test(event.key)) {
                                        event.preventDefault();
                                      }
                                    }}
                                    maxlength="50"
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        law_ref: e.target.value,
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Implementation Effective Date:</b>
                                  </label>
                                  <span style={{ color: "red" }}>*</span>
                                  <Input
                                    type="date"
                                    id="stat_date"
                                    name="stat_date"
                                    autoComplete={"off"}
                                    placeholder="CompfieLaw ID"
                                    min={tab3data.effect_date}
                                    value={tab3data.effect_date}
                                    onChange={(e) => {
                                      settab3data({
                                        ...tab3data,
                                        effect_date: e.target.value
                                      });
                                    }}
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Criticality:</b>
                                  </label>
                                  <span style={{ color: "red" }}>*</span>
                                  <Select
                                    allowClear={false}
                                    size="default"
                                    placeholder="Select Criticality"
                                    name="Criticality"
                                    className="form-control"
                                    id="Criticality"
                                    value={tab3data.criticality || undefined}
                                    onChange={(data, value) => {
                                      settab3data({
                                        ...tab3data,
                                        criticality: value.value,
                                        criticality_name: value.children,
                                      });
                                    }}
                                  >
                                    <Option value="1">A</Option>
                                    <Option value="2">B</Option>
                                    <Option value="3">C</Option>
                                  </Select>
                                  {tab3.current.message(
                                    "Criticality",
                                    tab3data.criticality,
                                    "required",
                                    {
                                      className: `invalid-feedback ${Tab3FormSubmit ? "show" : "hide"
                                        }`,
                                      messages: {
                                        required: "Criticality Required",
                                      },
                                    }
                                  )}
                                </div>
                              </div>

                              <div className="col-md-6">
                                <div className="mb-2">
                                  <label className="control-label">
                                    <b>Statutory Notification:</b>
                                  </label>
                                  <input type="file" name="notification" multiple className="form-control" onChange={(e) => {
                                    uploadnotificationdoc(e)
                                  }} />
                                  <div className="s_notification_scroll">
                                    {notificationfileupload && notificationfileupload.length > 0 && notificationfileupload.map((filelistnotify) => {

                                      return (
                                        <><span style={{ color: 'blue' }}>{filelistnotify.name}</span><br /></>
                                      )
                                    })}
                                  </div>

                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-6">
                                <div className="mb-2">
                                  <div
                                    className="d-flex justify-content-end mt-3"

                                  >
                                    <button className="btn btn-primary p-1 pt-0" onClick={addfile}>
                                      <PlusCircleOutlined />
                                    </button>
                                  </div>

                                  <table class="table striped">
                                    <thead>
                                      <tr>
                                        <th scope="col">S.No</th>
                                        <th
                                          style={{ width: "11rem" }}
                                          scope="col-2"
                                        >
                                          Compliance Document
                                        </th>
                                        <th scope="col">Format</th>
                                        <th scope="col">Actions </th>
                                        {/* <th><Button onClick={addfiles}>Test</Button></th> */}
                                      </tr>
                                    </thead>

                                    <tbody>
                                      {filedata.map((item, i) => {
                                        return (
                                          <tr>
                                            <td>{i + 1}</td>
                                            <td>
                                              <Input
                                                type="text"
                                                value={filedata[i].compliance}
                                                name="compliance"
                                                onChange={(e) => {
                                                  onconditionChange(
                                                    e,
                                                    i,
                                                    "input"
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td>
                                              {" "}
                                              <input
                                                style={{ width: "5rem" }}
                                                class="form-control form-control-sm"
                                                id="formFileSm"
                                                type="file"
                                                //value={filedata[i].fileformat}
                                                name="fileformat"
                                                onChange={(e) => {
                                                  onconditionChange(
                                                    e,
                                                    i,
                                                    "file"
                                                  );
                                                }}
                                              />
                                            </td>
                                            <td
                                              colSpan={2}
                                              onClick={() => {
                                                const newArray =
                                                  filedata.filter((item, j) => {
                                                    return j !== i;
                                                  });
                                                Setfiledata(newArray);
                                              }}
                                            >
                                              <CloseOutlined />
                                            </td>
                                          </tr>
                                        );
                                      })}
                                    </tbody>
                                  </table>
                                </div>
                              </div>
                              <div
                                className="col-md-6"
                                style={{
                                  "max-height": "20rem",
                                  "overflow-y": "scroll",
                                }}
                              >
                                <div className="mb-2">
                                  <h5>{tab3data.frequencydata}</h5>
                                  {tab3data.frequencydata == "One Time" ? (
                                    <div>
                                      <div>
                                        <label
                                          className="control-label"
                                          style={{ display: "flex" }}
                                        >
                                          <b>Statutory Date:</b>
                                        </label>
                                        <Select
                                          allowClear={false}
                                          size="default"
                                          placeholder="Select"
                                          name="date"
                                          className="form-control"
                                          id="date"
                                          style={{ width: "130px" }}
                                          value={tab3data.sta_month}
                                          onChange={(value) => {
                                            settab3data({
                                              ...tab3data,
                                              sta_month: value,
                                            });
                                          }}>
                                          {months && months.map((item, i) => {

                                            return <Option value={i + 1}>{item}</Option>
                                          })}

                                        </Select>
                                        &nbsp;&nbsp;&nbsp;
                                        <Select
                                          allowClear={false}
                                          size="default"
                                          placeholder="Select"
                                          name="date"
                                          className="form-control"
                                          id="date"
                                          style={{ width: "130px" }}
                                          value={tab3data.sta_date}
                                          onChange={(value) => {
                                            settab3data({
                                              ...tab3data,
                                              sta_date: value,
                                            });
                                          }}
                                        >
                                          {days &&
                                            days.length > 0 &&
                                            days.map((item) => {
                                              return (
                                                <Option value={item}>
                                                  {item}
                                                </Option>
                                              );
                                            })}
                                        </Select>
                                      </div>
                                      <div style={{ marginTop: "15px" }}>
                                        <label className="control-label">
                                          <b>
                                            Trigger Before
                                            Day(s):&nbsp;&nbsp;&nbsp;
                                          </b>
                                        </label>
                                        <Input
                                          type="text"
                                          name="Trigger Days"
                                          autoComplete={"off"}
                                          placeholder="Trigger Before Day(s)"
                                          value={tab3data.triggerdays}
                                          style={{ width: "170px" }}
                                          onKeyPress={(event) => {
                                            if (!/[0-9 +]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxlength="3"
                                          onChange={(e) => {
                                            settab3data({
                                              ...tab3data,
                                              triggerdays: e.target.value,
                                            });
                                          }}
                                        />
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {tab3data.frequencydata == "Periodical" ? (
                                    <div>
                                      <div>
                                        <label className="control-label">
                                          <b>Repeats Every:</b>
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        &nbsp;
                                        <Input
                                          type="text"
                                          name="Trigger Days"
                                          autoComplete={"off"}
                                          placeholder="Repeats Every"
                                          value={tab3data.repeats_every}
                                          style={{ width: "170px" }}
                                          onKeyPress={(event) => {
                                            if (!/[0-9 +]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxlength="3"
                                          onChange={(e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_every: e.target.value,
                                              repeats_months: '',
                                              repeats_every_label: '',
                                              multipleselection: ''
                                            });
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Select
                                          allowClear={false}
                                          size="default"
                                          placeholder="Select"
                                          name="date"
                                          disabled={
                                            tab3data.repeats_every == ""
                                              ? true
                                              : false
                                          }
                                          className="form-control"
                                          id="date"
                                          style={{ width: "130px" }}
                                          value={tab3data.repeats_months}
                                          onChange={(data, e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_months: e.value,
                                              repeats_every_label: e.children,
                                            });
                                          }}
                                        >
                                          <Option key="1">Day(s)</Option>
                                          <Option key="2">Month(s)</Option>
                                          <Option key="3">Year(s)</Option>
                                          <Option key="4">week(s)</Option>
                                          <Option key="5">Fortnight(s)</Option>
                                        </Select>
                                        <br />
                                        <br />
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label == ""
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <span className="mt-3">
                                            <input
                                              type="checkbox"
                                              className="checked"
                                              value={tab3data.multipleselection}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: 1,
                                                  });
                                                } else {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: '',
                                                  });
                                                }
                                              }}
                                            />
                                            &nbsp;&nbsp; Multiple Input
                                            Selection
                                          </span>
                                          <div className="row">
                                            <div className="mt-2 col">
                                              <label className="control-label">
                                                <b>Repeats By: &nbsp;&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.repeatby}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    repeatby: e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.repeatby == 1
                                                      }
                                                    />
                                                    &nbsp;Day of the month
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.repeatby == 2
                                                      }
                                                    />
                                                    &nbsp;End day of the month
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="mt-2 col">
                                              <label
                                                className="control-label"
                                                style={{ display: "flex" }}
                                              >
                                                <b>Statutory Date:</b>
                                              </label>
                                              <div className="d-flex">
                                                <Select
                                                  allowClear={false}
                                                  size="default"
                                                  placeholder="Select"
                                                  name="date"
                                                  className="form-select"
                                                  id="date"
                                                  onChange={(value) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      sta_month: value,
                                                    });
                                                  }}
                                                >
                                                  {months &&
                                                    months.length > 0 &&
                                                    months.map((item, i) => {
                                                      return <Option value={i + 1}>
                                                        {item}
                                                      </Option>;
                                                    })}
                                                </Select>
                                                <Select
                                                  allowClear={false}
                                                  size="default"
                                                  placeholder="Select"
                                                  name="date"
                                                  className="form-control"
                                                  id="date"
                                                  onChange={(value) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      sta_date: value,
                                                    });
                                                  }}
                                                >
                                                  {days &&
                                                    days.length > 0 &&
                                                    days.map((item, i) => {
                                                      return (
                                                        <Option value={item + 1}>
                                                          {item}
                                                        </Option>
                                                      );
                                                    })}
                                                </Select>
                                              </div>
                                            </div>
                                          </div>
                                          <div style={{ marginTop: "10px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Days */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Day(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Months */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Month(s)"
                                                ? "block"
                                                : "none",
                                          }}>
                                          {tab3data.repeats_every == 1 || tab3data.repeats_every == 2 || tab3data.repeats_every == 3 ||
                                            tab3data.repeats_every == 4 || tab3data.repeats_every == 6 ?
                                            <span className="mt-3">
                                              <input
                                                type="checkbox"
                                                className="checked"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    settab3data({
                                                      ...tab3data,
                                                      multipleselection: 1
                                                    })
                                                    addmultipledates()
                                                  } else {
                                                    settab3data({
                                                      ...tab3data,
                                                      multipleselection: '',
                                                    });
                                                  }
                                                }}
                                              />
                                              &nbsp;&nbsp; Multiple Input
                                              Selection
                                            </span> : ""}
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                                repeatesvalue = e.target.value
                                                addmultipledates()
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'none' : 'block' }}>
                                            <div
                                              className="mt-2"
                                              style={{
                                                display:
                                                  tab3data.repeatby == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                            >
                                              <label
                                                className="control-label"
                                                style={{ display: "flex" }}
                                              >
                                                <b>Statutory Date:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{
                                                  width: "140px",
                                                  display:
                                                    tab3data.repeatby == 1
                                                      ? "none"
                                                      : "block",
                                                }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    sta_month: e,
                                                  });
                                                }}
                                              >
                                                {
                                                  months && months.map((item, i) => {
                                                    return <Option value={item + 1}>{item}</Option>
                                                  })
                                                }

                                              </Select>
                                              &nbsp;&nbsp;&nbsp;
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "140px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    sta_date: e,
                                                  });
                                                }}
                                              >
                                                {days &&
                                                  days.length > 0 &&
                                                  days.map((item) => {
                                                    return (
                                                      <Option value={item}>
                                                        {item}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                          </div>
                                          <div style={{ marginTop: "15px", display: tab3data.multipleselection == 1 ? 'none' : 'block' }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'block' : 'none' }}>
                                            <table>
                                              <tr>
                                                <td>Statutory Date</td>
                                                <td>Trigger Before(days)</td>
                                              </tr>
                                              {formultiple}
                                            </table>
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Years */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Year(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="mt-2">
                                            <label
                                              className="control-label"
                                              style={{ display: "flex" }}
                                            >
                                              <b>Statutory Date:</b>
                                            </label>
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_month: e,
                                                });
                                              }} >
                                              {months && months.map((item, i) => {
                                                return <Option value={i + 1}>{item}</Option>
                                              })}
                                            </Select>
                                            &nbsp;&nbsp;&nbsp;
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{
                                                width: "140px",
                                                "margin-top": "-35px",
                                                "margin-left": "151px",
                                                display:
                                                  tab3data.repeatby == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_date: e,
                                                });
                                              }}
                                            >
                                              {days &&
                                                days.length > 0 &&
                                                days.map((item) => {
                                                  return (
                                                    <Option value={item}>
                                                      {item}
                                                    </Option>
                                                  );
                                                })}
                                            </Select>
                                          </div>
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Weeks */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "week(s)"
                                                ? "block"
                                                : "none",
                                          }}>
                                          <div className="row">
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Days:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1days: e
                                                  });
                                                }}
                                              >
                                                {weekdays &&
                                                  weekdays.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Trigger Before Days:</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="Trigger Days"
                                                autoComplete={"off"}
                                                placeholder="Trigger Before Day(s)"
                                                style={{ width: "68px" }}
                                                maxlength="3"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9 +]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1triggerdays:
                                                      e.target.value,
                                                  });
                                                }}
                                              />
                                            </div>

                                          </div>
                                          <div className="row mt-3" style={{ visibility: tab3data.week1days == 7 ? 'visible' : 'hidden' }}>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Start Day:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1startday: e,
                                                  });
                                                }}
                                              >
                                                {weekdays1 &&
                                                  weekdays1.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Exclude Days:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                mode="multiple"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1excludedays: e,
                                                  });
                                                }}
                                              >
                                                {weekdays1 &&
                                                  weekdays1.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>

                                          </div>

                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every} weeks
                                          </span>
                                        </div>
                                        {/* For Fortnights */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Fortnight(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div className="row">
                                            <div className="col">
                                              <label className="control-label">
                                                <b>FortNight:&nbsp;</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="FortNight"
                                                autoComplete={"off"}
                                                readOnly
                                                value='1'
                                                placeholder="FortNight"
                                                style={{ width: "50px" }}
                                              />
                                            </div>

                                            <div className="col" style={{ display: tab3data.fortmultipleselect == 1 ? 'none' : 'block' }}>
                                              {" "}
                                              <label className="control-label">
                                                <b>Repeats Every:&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.fortrepeatevery}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    fortrepeatevery:
                                                      e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.fortrepeatevery == 1
                                                      }
                                                    />
                                                    &nbsp;End of Fortninght
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label className="d-flex align-items-center">
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.fortrepeatevery == 2
                                                      }
                                                    />
                                                    &nbsp;Date &nbsp;
                                                    <Select
                                                      allowClear={false}
                                                      size="default"
                                                      placeholder="Select"
                                                      name="date"
                                                      className="form-control w-50"
                                                      id="date"
                                                      value={tab3data.fortdate}
                                                      onChange={(value) => {
                                                        settab3data({
                                                          ...tab3data,
                                                          fortdate: value,
                                                        });
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map((item) => {
                                                          return (
                                                            <Option
                                                              value={item}
                                                            >
                                                              {item}
                                                            </Option>
                                                          );
                                                        })}
                                                    </Select>
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="row">
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>Repeats By&nbsp;</b>
                                                </label>
                                                <form
                                                  value={tab3data.fortrepeatby}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      fortrepeatby:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <div class="radio radiofill">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="1"
                                                        checked={
                                                          tab3data.fortrepeatby ==
                                                          1
                                                        }
                                                      />
                                                      &nbsp;1-14
                                                    </label>
                                                  </div>
                                                  <div class="radio radiofill radio-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="2"
                                                        name="status"
                                                        checked={
                                                          tab3data.fortrepeatby ==
                                                          2
                                                        }
                                                      />
                                                      &nbsp;1-15
                                                    </label>
                                                  </div>
                                                </form>
                                              </div>
                                              <div className="col" style={{ display: tab3data.fortmultipleselect == 1 ? 'none' : 'block' }}>
                                                <label className="control-label">
                                                  <b>
                                                    Trigger before (Days):&nbsp;
                                                  </b>
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="FortNight"
                                                  autoComplete={"off"}
                                                  placeholder="FortNight"
                                                  style={{ width: "50px" }}
                                                  value={tab3data.forttriggerdays}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      forttriggerdays:
                                                        e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checked"
                                                  checked={tab3data.fortmultipleselect}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultipleselect: 1,
                                                        fortshow: "block",
                                                      });
                                                    } else {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultipleselect: 0,
                                                        fortshow: "none",
                                                      });
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp; Multiple Input
                                                Selection
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: tab3data.fortshow,
                                              }}
                                            >
                                              <span
                                                onClick={addmultiple}
                                                style={{
                                                  float: "right",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <PlusCircleOutlined />
                                              </span>
                                              {console.log(fortmultiple)}
                                              {fortmultiple && fortmultiple.map((item, i) => {
                                                return (
                                                  <table
                                                    style={{ width: "300px" }}
                                                  >
                                                    <tr>
                                                      <th>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple[i].statutorydate}
                                                          name="statutorydate"
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydate'
                                                            );
                                                          }}
                                                        >
                                                          {months &&
                                                            months.length > 0 &&
                                                            months.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={
                                                            fortmultiple[i]
                                                              .statutorydays
                                                          }
                                                          name="statutorydays"
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydays'
                                                            );
                                                          }}
                                                        >
                                                          {days &&
                                                            days.length > 0 &&
                                                            days.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j + 1}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                      </th>
                                                      <th>
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          autoComplete={"off"}
                                                          placeholder="Trigger before Days"
                                                          value={
                                                            fortmultiple[i]
                                                              .triggerdays
                                                          }
                                                          name="triggerdays"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "input",
                                                              'triggerdays'
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        onClick={() => {
                                                          const newArray =
                                                            fortmultiple.filter(
                                                              (item, j) => {
                                                                return j !== i;
                                                              }
                                                            );
                                                          setfortmultiple(
                                                            newArray
                                                          );
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </th>
                                                    </tr>
                                                  </table>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="row mt-4 border-top pt-3">
                                            <div className="col">
                                              <label className="control-label">
                                                <b>FortNight:&nbsp;</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="FortNight"
                                                autoComplete={"off"}
                                                readOnly
                                                value={tab3data.fortnight1}
                                                placeholder="FortNight"
                                                style={{ width: "50px" }}
                                              />
                                            </div>
                                            <div className="col" style={{ display: tab3data.fortmultipleselect1 == 1 ? 'none' : 'block' }}>
                                              {" "}
                                              <label className="control-label">
                                                <b>Repears Every:&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.fortrepeatevery1}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    fortrepeatevery1:
                                                      e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.fortrepeatevery1 ==
                                                        1
                                                      }
                                                    />
                                                    &nbsp;End of Fortninght
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label className="d-flex align-items-center">
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.fortrepeatevery1 ==
                                                        2
                                                      }
                                                    />
                                                    &nbsp;Date &nbsp;
                                                    <Select
                                                      allowClear={false}
                                                      size="default"
                                                      placeholder="Select"
                                                      name="date"
                                                      className="form-control w-50"
                                                      id="date"
                                                      value={tab3data.fortdate1}
                                                      onChange={(value) => {
                                                        settab3data({
                                                          ...tab3data,
                                                          fortdate1: value,
                                                        });
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map((item) => {
                                                          return (
                                                            <Option
                                                              value={item}
                                                            >
                                                              {item}
                                                            </Option>
                                                          );
                                                        })}
                                                    </Select>
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="row">
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>Repeats By&nbsp;</b>
                                                </label>
                                                <form
                                                  value={tab3data.fortrepeatby1}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      fortrepeatby1:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <div class="radio radiofill">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="1"
                                                        checked={
                                                          tab3data.fortrepeatby1 ==
                                                          1
                                                        }
                                                      />
                                                      &nbsp;15-End of the Month
                                                    </label>
                                                  </div>
                                                  <div class="radio radiofill radio-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="2"
                                                        name="status"
                                                        checked={
                                                          tab3data.fortrepeatby1 ==
                                                          2
                                                        }
                                                      />
                                                      &nbsp;16-End of the Month
                                                    </label>
                                                  </div>
                                                </form>
                                              </div>
                                              <div className="col" style={{ display: tab3data.fortmultipleselect1 == 1 ? 'none' : 'block' }}>
                                                <label className="control-label">
                                                  <b>
                                                    Trigger before (Days):&nbsp;
                                                  </b>
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="FortNight"
                                                  autoComplete={"off"}
                                                  placeholder="FortNight"
                                                  style={{ width: "50px" }}
                                                  value={tab3data.forttriggerdays1}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      forttriggerdays1:
                                                        e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checked"
                                                  checked={tab3data.fortmultipleselect1}
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultipleselect1: 1,
                                                        fortshow1: "block",
                                                      });
                                                    } else {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultipleselect1: 0,
                                                        fortshow1: "none",
                                                      });
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp; Multiple Input
                                                Selection
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: tab3data.fortshow1,
                                              }}
                                            >
                                              <span
                                                onClick={addmultiple1}
                                                style={{
                                                  float: "right",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <PlusCircleOutlined />
                                              </span>
                                              {fortmultiple1.map((item, i) => {
                                                return (
                                                  <table
                                                    style={{ width: "300px" }}
                                                  >
                                                    <tr>
                                                      <th>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple1[i].statutorydate}
                                                          name="statutorydate"
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydate'
                                                            );
                                                          }}
                                                        >
                                                          {months &&
                                                            months.length > 0 &&
                                                            months.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple1[i].statutorydays}
                                                          name="statutorydays"
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydays'
                                                            );
                                                          }}
                                                        >
                                                          {days &&
                                                            days.length > 0 &&
                                                            days.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j + 1}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                      </th>
                                                      <th>
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          autoComplete={"off"}
                                                          placeholder="Trigger before Days"
                                                          value={
                                                            fortmultiple1[i]
                                                              .triggerdays
                                                          }
                                                          name="triggerdays"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "input",
                                                              'triggerdays'
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        onClick={() => {
                                                          const newArray =
                                                            fortmultiple1.filter(
                                                              (item, j) => {
                                                                return j !== i;
                                                              }
                                                            );
                                                          setfortmultiple1(
                                                            newArray
                                                          );
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </th>
                                                    </tr>
                                                  </table>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {tab3data.frequencydata == "Review" ? (
                                    <div>
                                      <div>
                                        <label className="control-label">
                                          <b>Repeats Every:</b>
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        &nbsp;
                                        <Input
                                          type="text"
                                          name="Trigger Days"
                                          autoComplete={"off"}
                                          placeholder="Repeats Every"
                                          style={{ width: "170px" }}
                                          onKeyPress={(event) => {
                                            if (!/[0-9 +]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxlength="3"
                                          onChange={(e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_every: e.target.value,
                                              repeats_months: '',
                                              repeats_every_label: '',
                                              multipleselection: ''
                                            });
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Select
                                          allowClear={false}
                                          size="default"
                                          placeholder="Select"
                                          name="date"
                                          disabled={
                                            tab3data.repeats_every == ""
                                              ? true
                                              : false
                                          }
                                          className="form-control"
                                          id="date"
                                          style={{ width: "130px" }}
                                          onChange={(data, e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_months: e.value,
                                              repeats_every_label: e.children,
                                            });
                                          }}
                                        >
                                          <Option>Select</Option>
                                          <Option key="1">Day(s)</Option>
                                          <Option key="2">Month(s)</Option>
                                          <Option key="3">Year(s)</Option>
                                          <Option key="4">week(s)</Option>
                                          <Option key="5">Fortnight(s)</Option>
                                        </Select>
                                        <br />
                                        <br />
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label == ""
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <span className="mt-3">
                                            <input
                                              type="checkbox"
                                              className="checked"
                                              value={tab3data.multipleselection}
                                              checked={tab3data.multipleselection == 1}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: 1,
                                                  });
                                                } else {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: '',
                                                  });
                                                }
                                              }}
                                            />
                                            &nbsp;&nbsp; Multiple Input
                                            Selection
                                          </span>
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="mt-2">
                                            <label
                                              className="control-label"
                                              style={{ display: "flex" }}
                                            >
                                              <b>Statutory Date:</b>
                                            </label>
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(data, e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_month: e.value,
                                                });
                                              }}>
                                              {months && months.map((item, i) => {
                                                return <Option value={i + 1}>{item}</Option>
                                              })}
                                            </Select>
                                            &nbsp;&nbsp;&nbsp;
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(data, e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_date: e.value,
                                                });
                                              }}
                                            >
                                              {days &&
                                                days.length > 0 &&
                                                days.map((item) => {
                                                  return (
                                                    <Option value={item}>
                                                      {item}
                                                    </Option>
                                                  );
                                                })}
                                            </Select>
                                          </div>
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Days */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Day(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Months */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Month(s)"
                                                ? "block"
                                                : "none",
                                          }} >
                                          {tab3data.repeats_every == 1 || tab3data.repeats_every == 2 || tab3data.repeats_every == 3 ||
                                            tab3data.repeats_every == 4 || tab3data.repeats_every == 6 ?
                                            <span className="mt-3">
                                              <input
                                                type="checkbox"
                                                className="checked"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    settab3data({
                                                      ...tab3data,
                                                      multipleselection: 1
                                                    });
                                                    addmultipledates()
                                                  } else {
                                                    settab3data({
                                                      ...tab3data,
                                                      multipleselection: ''
                                                    });
                                                  }
                                                }}
                                              />
                                              &nbsp;&nbsp; Multiple Input
                                              Selection
                                            </span> : ''}
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                tab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                                repeatesvalue = e.target.value
                                                addmultipledates()
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab2data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'none' : 'block' }}>
                                            <div
                                              className="mt-2"
                                              style={{
                                                display:
                                                  tab3data.repeatby == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                            >
                                              <label
                                                className="control-label"
                                                style={{ display: "flex" }}
                                              >
                                                <b>Statutory Date:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{
                                                  width: "140px",
                                                  display:
                                                    tab3data.repeatby == 1
                                                      ? "none"
                                                      : "block",
                                                }}
                                                onChange={(data, e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    sta_month: e.value,
                                                  });
                                                }}>
                                                {months && months.map((item, i) => {
                                                  return <Option value={i + 1}>{item}</Option>
                                                })}
                                              </Select>
                                              &nbsp;&nbsp;&nbsp;
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "140px" }}
                                                onChange={(data, e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    sta_date: e.value,
                                                  });
                                                }}
                                              >
                                                {days &&
                                                  days.length > 0 &&
                                                  days.map((item) => {
                                                    return (
                                                      <Option value={item}>
                                                        {item}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div></div>
                                          <div style={{ marginTop: "15px", display: tab3data.multipleselection == 1 ? 'none' : 'block' }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'block' : 'none' }}>
                                            <table>
                                              <tr>
                                                <td>Statutory Date</td>
                                                <td>Trigger Before(days)</td>
                                              </tr>
                                              {formultiple}
                                            </table>
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Years */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Year(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <span className="mt-3">
                                            <input
                                              type="checkbox"
                                              className="checked"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection:
                                                      e.target.value,
                                                  });
                                                } else {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: ''
                                                  });
                                                }
                                              }}
                                            />
                                            &nbsp;&nbsp; Multiple Input
                                            Selection
                                          </span>
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                tab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="mt-2">
                                            <label
                                              className="control-label"
                                              style={{ display: "flex" }}
                                            >
                                              <b>Statutory Date:</b>
                                            </label>
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(data, e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_month: e.value,
                                                });
                                              }} >
                                              {months && months.map((item, i) => {
                                                return <Option value={i + 1}>{item}</Option>
                                              })}

                                            </Select>
                                            &nbsp;&nbsp;&nbsp;
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{
                                                width: "140px",
                                                "margin-top": "-35px",
                                                "margin-left": "151px",
                                                display:
                                                  tab3data.repeatby == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                              onChange={(data, e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_date: e.value,
                                                });
                                              }}
                                            >
                                              {days &&
                                                days.length > 0 &&
                                                days.map((item) => {
                                                  return (
                                                    <Option value={item}>
                                                      {item}
                                                    </Option>
                                                  );
                                                })}
                                            </Select>
                                          </div>
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'block' : 'none' }}>
                                            <table>
                                              <tr>
                                                <td>Statutory Date</td>
                                                <td>Trigger Before(days)</td>
                                              </tr>
                                              {formultiple}
                                            </table>
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Weeks */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "week(s)"
                                                ? "block"
                                                : "none",
                                          }}>
                                          <div className="row">
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Days:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1days: e
                                                  });
                                                }}
                                              >
                                                {weekdays &&
                                                  weekdays.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Trigger Before Days:</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="Trigger Days"
                                                autoComplete={"off"}
                                                placeholder="Trigger Before Day(s)"
                                                style={{ width: "68px" }}
                                                maxlength="3"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9 +]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1triggerdays:
                                                      e.target.value,
                                                  });
                                                }}
                                              />
                                            </div>

                                          </div>
                                          <div className="row mt-3" style={{ visibility: tab3data.week1days == 7 ? 'visible' : 'hidden' }}>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Start Day:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1startday: e,
                                                  });
                                                }}
                                              >
                                                {weekdays1 &&
                                                  weekdays1.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Exclude Days:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                mode="multiple"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1excludedays: e,
                                                  });
                                                }}
                                              >
                                                {weekdays1 &&
                                                  weekdays1.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>

                                          </div>

                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every} weeks
                                          </span>
                                        </div>
                                        {/* For Fortnights */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Fortnight(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div className="row">
                                            <div className="col">
                                              <label className="control-label">
                                                <b>FortNight:&nbsp;</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="FortNight"
                                                autoComplete={"off"}
                                                readOnly
                                                value='1'
                                                placeholder="FortNight"
                                                style={{ width: "50px" }}
                                              />
                                            </div>
                                            <div className="col">
                                              {" "}
                                              <label className="control-label">
                                                <b>Repears Every:&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.repeatby}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    fortrepeatevery:
                                                      e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.fortrepeatevery == 1
                                                      }
                                                    />
                                                    &nbsp;End of Fortninght
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label className="d-flex align-items-center">
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.fortrepeatevery == 2
                                                      }
                                                    />
                                                    &nbsp;Date &nbsp;
                                                    <Select
                                                      allowClear={false}
                                                      size="default"
                                                      placeholder="Select"
                                                      name="date"
                                                      className="form-control w-50"
                                                      id="date"
                                                      onChange={(value) => {
                                                        settab3data({
                                                          ...tab3data,
                                                          fortdate: value,
                                                        });
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map((item) => {
                                                          return (
                                                            <Option
                                                              value={item}
                                                            >
                                                              {item}
                                                            </Option>
                                                          );
                                                        })}
                                                    </Select>
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="row">
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>Repeats By&nbsp;</b>
                                                </label>
                                                <form
                                                  value={tab3data.repeatby}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      fortrepeatby:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <div class="radio radiofill">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="1"
                                                        checked={
                                                          tab3data.fortrepeatby ==
                                                          1
                                                        }
                                                      />
                                                      &nbsp;1-14
                                                    </label>
                                                  </div>
                                                  <div class="radio radiofill radio-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="2"
                                                        name="status"
                                                        checked={
                                                          tab3data.fortrepeatby ==
                                                          2
                                                        }
                                                      />
                                                      &nbsp;1-15
                                                    </label>
                                                  </div>
                                                </form>
                                              </div>
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>
                                                    Trigger before (Days):&nbsp;
                                                  </b>
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="FortNight"
                                                  autoComplete={"off"}
                                                  placeholder="FortNight"
                                                  style={{ width: "50px" }}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      forttriggerdays:
                                                        e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checked"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple: 1,
                                                        fortshow: "block",
                                                      });
                                                    } else {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple: 0,
                                                        fortshow: "none",
                                                      });
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp; Multiple Input
                                                Selection
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: tab3data.fortshow,
                                              }}
                                            >
                                              <span
                                                onClick={addmultiple}
                                                style={{
                                                  float: "right",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <PlusCircleOutlined />
                                              </span>
                                              {fortmultiple.map((item, i) => {
                                                return (
                                                  <table
                                                    style={{ width: "300px" }}
                                                  >
                                                    <tr>
                                                      <th>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple[i].statutorydate}
                                                          name="statutorydate"
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydate'
                                                            );
                                                          }}
                                                        >
                                                          {months &&
                                                            months.length > 0 &&
                                                            months.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={
                                                            fortmultiple[i]
                                                              .statutorydays
                                                          }
                                                          name="statutorydays"
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydays'
                                                            );
                                                          }}
                                                        >
                                                          {days &&
                                                            days.length > 0 &&
                                                            days.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j + 1}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                      </th>
                                                      <th>
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          autoComplete={"off"}
                                                          placeholder="Trigger before Days"
                                                          value={
                                                            fortmultiple[i]
                                                              .triggerdays
                                                          }
                                                          name="triggerdays"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "input",
                                                              'triggerdays'
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        onClick={() => {
                                                          const newArray =
                                                            fortmultiple.filter(
                                                              (item, j) => {
                                                                return j !== i;
                                                              }
                                                            );
                                                          setfortmultiple(
                                                            newArray
                                                          );
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </th>
                                                    </tr>
                                                  </table>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="row mt-4 border-top pt-3">
                                            <div className="col">
                                              <label className="control-label">
                                                <b>FortNight:&nbsp;</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="FortNight"
                                                autoComplete={"off"}
                                                readOnly
                                                value={tab3data.fortnight1}
                                                placeholder="FortNight"
                                                style={{ width: "50px" }}
                                              />
                                            </div>
                                            <div className="col">
                                              {" "}
                                              <label className="control-label">
                                                <b>Repears Every:&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.repeatby}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    fortrepeatevery1:
                                                      e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.fortrepeatevery1 ==
                                                        1
                                                      }
                                                    />
                                                    &nbsp;End of Fortninght
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label className="d-flex align-items-center">
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.fortrepeatevery1 ==
                                                        2
                                                      }
                                                    />
                                                    &nbsp;Date &nbsp;
                                                    <Select
                                                      allowClear={false}
                                                      size="default"
                                                      placeholder="Select"
                                                      name="date"
                                                      className="form-control w-50"
                                                      id="date"
                                                      onChange={(value) => {
                                                        settab3data({
                                                          ...tab3data,
                                                          fortdate1: value,
                                                        });
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map((item) => {
                                                          return (
                                                            <Option
                                                              value={item}
                                                            >
                                                              {item}
                                                            </Option>
                                                          );
                                                        })}
                                                    </Select>
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="row">
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>Repeats By&nbsp;</b>
                                                </label>
                                                <form
                                                  value={tab3data.repeatby}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      fortrepeatby1:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <div class="radio radiofill">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="1"
                                                        checked={
                                                          tab3data.fortrepeatby1 ==
                                                          1
                                                        }
                                                      />
                                                      &nbsp;15-End of the Month
                                                    </label>
                                                  </div>
                                                  <div class="radio radiofill radio-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="2"
                                                        name="status"
                                                        checked={
                                                          tab3data.fortrepeatby1 ==
                                                          2
                                                        }
                                                      />
                                                      &nbsp;16-End of the Month
                                                    </label>
                                                  </div>
                                                </form>
                                              </div>
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>
                                                    Trigger before (Days):&nbsp;
                                                  </b>
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="FortNight"
                                                  autoComplete={"off"}
                                                  placeholder="FortNight"
                                                  style={{ width: "50px" }}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      forttriggerdays1:
                                                        e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checked"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple1: 1,
                                                        fortshow1: "block",
                                                      });
                                                    } else {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple1: 0,
                                                        fortshow1: "none",
                                                      });
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp; Multiple Input
                                                Selection
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: tab3data.fortshow1,
                                              }}
                                            >
                                              <span
                                                onClick={addmultiple1}
                                                style={{
                                                  float: "right",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <PlusCircleOutlined />
                                              </span>
                                              {fortmultiple1.map((item, i) => {
                                                return (
                                                  <table
                                                    style={{ width: "300px" }}
                                                  >
                                                    <tr>
                                                      <th>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple1[i].statutorydate}
                                                          name="statutorydate"
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydate'
                                                            );
                                                          }}
                                                        >
                                                          {months &&
                                                            months.length > 0 &&
                                                            months.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple1[i].statutorydays}
                                                          name="statutorydays"
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydays'
                                                            );
                                                          }}
                                                        >
                                                          {days &&
                                                            days.length > 0 &&
                                                            days.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j + 1}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                      </th>
                                                      <th>
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          autoComplete={"off"}
                                                          placeholder="Trigger before Days"
                                                          value={
                                                            fortmultiple1[i]
                                                              .triggerdays
                                                          }
                                                          name="triggerdays"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "input",
                                                              'triggerdays'
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        onClick={() => {
                                                          const newArray =
                                                            fortmultiple1.filter(
                                                              (item, j) => {
                                                                return j !== i;
                                                              }
                                                            );
                                                          setfortmultiple1(
                                                            newArray
                                                          );
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </th>
                                                    </tr>
                                                  </table>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {tab3data.frequencydata == "Flexi Review" ? (
                                    <div>
                                      <div>
                                        <label className="control-label">
                                          <b>Repeats Every:</b>
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        &nbsp;
                                        <Input
                                          type="text"
                                          name="Trigger Days"
                                          autoComplete={"off"}
                                          placeholder="Repeats Every"
                                          style={{ width: "170px" }}
                                          onKeyPress={(event) => {
                                            if (!/[0-9 +]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxlength="3"
                                          onChange={(e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_every: e.target.value,
                                              repeats_every_label: '',
                                              multiplefileupload: '',
                                              repeats_months: ''
                                            });
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                        <Select
                                          allowClear={false}
                                          size="default"
                                          placeholder="Select"
                                          name="date"
                                          disabled={
                                            tab3data.repeats_every == ""
                                              ? true
                                              : false
                                          }
                                          className="form-control"
                                          id="date"
                                          style={{ width: "130px" }}
                                          value={tab3data.repeats_months}
                                          onChange={(data, e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_months: e.value,
                                              repeats_every_label: e.children,
                                            });
                                          }}
                                        >
                                          <Option>Select</Option>
                                          <Option key="1">Day(s)</Option>
                                          <Option key="2">Month(s)</Option>
                                          <Option key="3">Year(s)</Option>
                                          <Option key="4">week(s)</Option>
                                          <Option key="5">Fortnight(s)</Option>
                                        </Select>
                                        <br />
                                        <br />
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label == ""
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <span className="mt-3">
                                            <input
                                              type="checkbox"
                                              className="checked"
                                              value={tab3data.multipleselection}
                                              checked={tab3data.multipleselection == 1}
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection:
                                                      e.target.value,
                                                  });
                                                } else {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: ''
                                                  });
                                                }

                                              }}
                                            />
                                            &nbsp;&nbsp; Multiple Input
                                            Selection
                                          </span>
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="mt-2">
                                            <label
                                              className="control-label"
                                              style={{ display: "flex" }}
                                            >
                                              <b>Statutory Date:</b>
                                            </label>
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(data, e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_month: e.value,
                                                });
                                              }}>
                                              {months && months.map((item, i) => {
                                                return <Option value={i + 1}>{item}</Option>
                                              })}
                                            </Select>
                                            &nbsp;&nbsp;&nbsp;
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(data, e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_date: e.value,
                                                });
                                              }}
                                            >
                                              {days &&
                                                days.length > 0 &&
                                                days.map((item) => {
                                                  return (
                                                    <Option value={item}>
                                                      {item}
                                                    </Option>
                                                  );
                                                })}
                                            </Select>
                                          </div>
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Days */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Day(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Months */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Month(s)"
                                                ? "block"
                                                : "none",
                                          }}>
                                          {tab3data.repeats_every == 1 || tab3data.repeats_every == 2 || tab3data.repeats_every == 3 ||
                                            tab3data.repeats_every == 4 || tab3data.repeats_every == 6 ?
                                            <span className="mt-3">
                                              <input
                                                type="checkbox"
                                                className="checked"
                                                onChange={(e) => {
                                                  if (e.target.checked) {
                                                    settab3data({
                                                      ...tab3data,
                                                      multipleselection:
                                                        e.target.value,
                                                    });
                                                  } else {
                                                    settab3data({
                                                      ...tab3data,
                                                      multipleselection: ''
                                                    });
                                                  }

                                                }}
                                              />
                                              &nbsp;&nbsp; Multiple Input
                                              Selection
                                            </span> : ''}
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                                repeatesvalue = e.target.value
                                                addmultipledates()
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'none' : 'block' }}>
                                            <div
                                              className="mt-2"
                                              style={{
                                                display:
                                                  tab3data.repeatby == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                            >
                                              <label
                                                className="control-label"
                                                style={{ display: "flex" }}
                                              >
                                                <b>Statutory Date:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{
                                                  width: "140px",
                                                  display:
                                                    tab3data.repeatby == 1
                                                      ? "none"
                                                      : "block",
                                                }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    sta_month: e.target.value,
                                                  });
                                                }}  >
                                                {months && months.map((item, i) => {
                                                  return
                                                  <Option value={i + 1}>{item}</Option>
                                                })} </Select>
                                              &nbsp;&nbsp;&nbsp;
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "140px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    sta_date: e.target.value,
                                                  });
                                                }}
                                              >
                                                {days &&
                                                  days.length > 0 &&
                                                  days.map((item) => {
                                                    return (
                                                      <Option value={item}>
                                                        {item}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                          </div>
                                          <div style={{ marginTop: "15px", display: tab3data.multipleselection == 1 ? 'none' : 'block' }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <div style={{ display: tab3data.multipleselection == 1 ? 'block' : 'none' }}>
                                            <table>
                                              <tr>
                                                <td>Statutory Date</td>
                                                <td>Trigger Before(days)</td>
                                              </tr>
                                              {formultiple}
                                            </table>
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Years */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Year(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <span className="mt-3">
                                            <input
                                              type="checkbox"
                                              className="checked"
                                              onChange={(e) => {
                                                if (e.target.checked) {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection:
                                                      e.target.value,
                                                  });
                                                } else {
                                                  settab3data({
                                                    ...tab3data,
                                                    multipleselection: '',
                                                  });
                                                }

                                              }}
                                            />
                                            &nbsp;&nbsp; Multiple Input
                                            Selection
                                          </span>
                                          <div className="mt-2">
                                            <label className="control-label">
                                              <b>Repeats By: &nbsp;&nbsp;</b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  repeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.repeatby == 1
                                                    }
                                                  />
                                                  &nbsp;Day of the month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.repeatby == 2
                                                    }
                                                  />
                                                  &nbsp;End day of the month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="mt-2">
                                            <label
                                              className="control-label"
                                              style={{ display: "flex" }}
                                            >
                                              <b>Statutory Date:</b>
                                            </label>
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{ width: "140px" }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_month: e.target.value,
                                                });
                                              }}
                                            >
                                              {months && months.map((item, i) => {
                                                return
                                                <Option value={i + 1}>{item}</Option>
                                              })}
                                            </Select>
                                            &nbsp;&nbsp;&nbsp;
                                            <Select
                                              allowClear={false}
                                              size="default"
                                              placeholder="Select"
                                              name="date"
                                              className="form-control"
                                              id="date"
                                              style={{
                                                width: "140px",
                                                "margin-top": "-35px",
                                                "margin-left": "151px",
                                                display:
                                                  tab3data.repeatby == 2
                                                    ? "none"
                                                    : "block",
                                              }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  sta_date: e.target.value,
                                                });
                                              }}
                                            >
                                              {days &&
                                                days.length > 0 &&
                                                days.map((item) => {
                                                  return (
                                                    <Option value={item}>
                                                      {item}
                                                    </Option>
                                                  );
                                                })}
                                            </Select>
                                          </div>
                                          <div style={{ marginTop: "15px" }}>
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Day(s):&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Trigger Before Day(s)"
                                              style={{ width: "170px" }}
                                              onKeyPress={(event) => {
                                                if (!/[0-9 +]/.test(event.key)) {
                                                  event.preventDefault();
                                                }
                                              }}
                                              maxlength="3"
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  triggerdays: e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every}{" "}
                                            {tab3data.repeats_every_label}
                                          </span>
                                        </div>
                                        {/* For Weeks */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "week(s)"
                                                ? "block"
                                                : "none",
                                          }}>
                                          <div className="row">
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Days:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1days: e
                                                  });
                                                }}
                                              >
                                                {weekdays &&
                                                  weekdays.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Trigger Before Days:</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="Trigger Days"
                                                autoComplete={"off"}
                                                placeholder="Trigger Before Day(s)"
                                                style={{ width: "68px" }}
                                                maxlength="3"
                                                onKeyPress={(event) => {
                                                  if (!/[0-9 +]/.test(event.key)) {
                                                    event.preventDefault();
                                                  }
                                                }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1triggerdays:
                                                      e.target.value,
                                                  });
                                                }}
                                              />
                                            </div>

                                          </div>
                                          <div className="row mt-3" style={{ visibility: tab3data.week1days == 7 ? 'visible' : 'hidden' }}>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Start Day:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1startday: e,
                                                  });
                                                }}
                                              >
                                                {weekdays1 &&
                                                  weekdays1.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>
                                            <div className="col-6">
                                              <label className="control-label me-2">
                                                <b>Exclude Days:</b>
                                              </label>
                                              <Select
                                                allowClear={false}
                                                size="default"
                                                placeholder="Select"
                                                name="date"
                                                mode="multiple"
                                                className="form-control"
                                                id="date"
                                                style={{ width: "100px" }}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    week1excludedays: e,
                                                  });
                                                }}
                                              >
                                                {weekdays1 &&
                                                  weekdays1.map((week, i) => {
                                                    return (
                                                      <Option value={i}>
                                                        {week}
                                                      </Option>
                                                    );
                                                  })}
                                              </Select>
                                            </div>

                                          </div>

                                          <span className="mt-2">
                                            <b>Summary:</b>&nbsp;&nbsp;Repeats
                                            every {tab3data.repeats_every} weeks
                                          </span>
                                        </div>
                                        {/* For FortNight */}
                                        <div
                                          style={{
                                            display:
                                              tab3data.repeats_every_label ==
                                                "Fortnight(s)"
                                                ? "block"
                                                : "none",
                                          }}
                                        >
                                          <div className="row">
                                            <div className="col">
                                              <label className="control-label">
                                                <b>FortNight:&nbsp;</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="FortNight"
                                                autoComplete={"off"}
                                                readOnly
                                                value='1'
                                                placeholder="FortNight"
                                                style={{ width: "50px" }}
                                              />
                                            </div>
                                            <div className="col">
                                              {" "}
                                              <label className="control-label">
                                                <b>Repears Every:&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.repeatby}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    fortrepeatevery:
                                                      e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.fortrepeatevery == 1
                                                      }
                                                    />
                                                    &nbsp;End of Fortninght
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label className="d-flex align-items-center">
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.fortrepeatevery == 2
                                                      }
                                                    />
                                                    &nbsp;Date &nbsp;
                                                    <Select
                                                      allowClear={false}
                                                      size="default"
                                                      placeholder="Select"
                                                      name="date"
                                                      className="form-control w-50"
                                                      id="date"
                                                      onChange={(value) => {
                                                        settab3data({
                                                          ...tab3data,
                                                          fortdate: value,
                                                        });
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map((item) => {
                                                          return (
                                                            <Option
                                                              value={item}
                                                            >
                                                              {item}
                                                            </Option>
                                                          );
                                                        })}
                                                    </Select>
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="row">
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>Repeats By&nbsp;</b>
                                                </label>
                                                <form
                                                  value={tab3data.repeatby}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      fortrepeatby:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <div class="radio radiofill">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="1"
                                                        checked={
                                                          tab3data.fortrepeatby ==
                                                          1
                                                        }
                                                      />
                                                      &nbsp;1-14
                                                    </label>
                                                  </div>
                                                  <div class="radio radiofill radio-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="2"
                                                        name="status"
                                                        checked={
                                                          tab3data.fortrepeatby ==
                                                          2
                                                        }
                                                      />
                                                      &nbsp;1-15
                                                    </label>
                                                  </div>
                                                </form>
                                              </div>
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>
                                                    Trigger before (Days):&nbsp;
                                                  </b>
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="FortNight"
                                                  autoComplete={"off"}
                                                  placeholder="FortNight"
                                                  style={{ width: "50px" }}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      forttriggerdays:
                                                        e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checked"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple: 1,
                                                        fortshow: "block",
                                                      });
                                                    } else {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple: 0,
                                                        fortshow: "none",
                                                      });
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp; Multiple Input
                                                Selection
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: tab3data.fortshow,
                                              }}
                                            >
                                              <span
                                                onClick={addmultiple}
                                                style={{
                                                  float: "right",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <PlusCircleOutlined />
                                              </span>
                                              {fortmultiple.map((item, i) => {
                                                return (
                                                  <table
                                                    style={{ width: "300px" }}
                                                  >
                                                    <tr>
                                                      <th>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple[i].statutorydate}
                                                          name="statutorydate"
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydate'
                                                            );
                                                          }}
                                                        >
                                                          {months &&
                                                            months.length > 0 &&
                                                            months.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={
                                                            fortmultiple[i]
                                                              .statutorydays
                                                          }
                                                          name="statutorydays"
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydays'
                                                            );
                                                          }}
                                                        >
                                                          {days &&
                                                            days.length > 0 &&
                                                            days.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j + 1}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                      </th>
                                                      <th>
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          autoComplete={"off"}
                                                          placeholder="Trigger before Days"
                                                          value={
                                                            fortmultiple[i]
                                                              .triggerdays
                                                          }
                                                          name="triggerdays"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          onChange={(e) => {
                                                            onstat_map(
                                                              e,
                                                              i,
                                                              "input",
                                                              'triggerdays'
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        onClick={() => {
                                                          const newArray =
                                                            fortmultiple.filter(
                                                              (item, j) => {
                                                                return j !== i;
                                                              }
                                                            );
                                                          setfortmultiple(
                                                            newArray
                                                          );
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </th>
                                                    </tr>
                                                  </table>
                                                );
                                              })}
                                            </div>
                                          </div>
                                          <div className="row mt-4 border-top pt-3">
                                            <div className="col">
                                              <label className="control-label">
                                                <b>FortNight:&nbsp;</b>
                                              </label>
                                              <Input
                                                type="text"
                                                name="FortNight"
                                                autoComplete={"off"}
                                                readOnly
                                                value={tab3data.fortnight1}
                                                placeholder="FortNight"
                                                style={{ width: "50px" }}
                                              />
                                            </div>
                                            <div className="col">
                                              {" "}
                                              <label className="control-label">
                                                <b>Repears Every:&nbsp;</b>
                                              </label>
                                              <form
                                                value={tab3data.repeatby}
                                                onChange={(e) => {
                                                  settab3data({
                                                    ...tab3data,
                                                    fortrepeatevery1:
                                                      e.target.value,
                                                  });
                                                }}
                                              >
                                                <div class="radio radiofill">
                                                  <label>
                                                    <input
                                                      type="radio"
                                                      value="1"
                                                      checked={
                                                        tab3data.fortrepeatevery1 ==
                                                        1
                                                      }
                                                    />
                                                    &nbsp;End of Fortninght
                                                  </label>
                                                </div>
                                                <div class="radio radiofill radio-inline">
                                                  <label className="d-flex align-items-center">
                                                    <input
                                                      type="radio"
                                                      value="2"
                                                      name="status"
                                                      checked={
                                                        tab3data.fortrepeatevery1 ==
                                                        2
                                                      }
                                                    />
                                                    &nbsp;Date &nbsp;
                                                    <Select
                                                      allowClear={false}
                                                      size="default"
                                                      placeholder="Select"
                                                      name="date"
                                                      className="form-control w-50"
                                                      id="date"
                                                      onChange={(value) => {
                                                        settab3data({
                                                          ...tab3data,
                                                          fortdate1: value,
                                                        });
                                                      }}
                                                    >
                                                      {days &&
                                                        days.length > 0 &&
                                                        days.map((item) => {
                                                          return (
                                                            <Option
                                                              value={item}
                                                            >
                                                              {item}
                                                            </Option>
                                                          );
                                                        })}
                                                    </Select>
                                                  </label>
                                                </div>
                                              </form>
                                            </div>
                                            <div className="row">
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>Repeats By&nbsp;</b>
                                                </label>
                                                <form
                                                  value={tab3data.repeatby}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      fortrepeatby1:
                                                        e.target.value,
                                                    });
                                                  }}
                                                >
                                                  <div class="radio radiofill">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="1"
                                                        checked={
                                                          tab3data.fortrepeatby1 ==
                                                          1
                                                        }
                                                      />
                                                      &nbsp;15-End of the Month
                                                    </label>
                                                  </div>
                                                  <div class="radio radiofill radio-inline">
                                                    <label>
                                                      <input
                                                        type="radio"
                                                        value="2"
                                                        name="status"
                                                        checked={
                                                          tab3data.fortrepeatby1 ==
                                                          2
                                                        }
                                                      />
                                                      &nbsp;16-End of the Month
                                                    </label>
                                                  </div>
                                                </form>
                                              </div>
                                              <div className="col">
                                                <label className="control-label">
                                                  <b>
                                                    Trigger before (Days):&nbsp;
                                                  </b>
                                                </label>
                                                <Input
                                                  type="text"
                                                  name="FortNight"
                                                  autoComplete={"off"}
                                                  placeholder="FortNight"
                                                  style={{ width: "50px" }}
                                                  onChange={(e) => {
                                                    settab3data({
                                                      ...tab3data,
                                                      forttriggerdays1:
                                                        e.target.value,
                                                    });
                                                  }}
                                                />
                                              </div>
                                            </div>
                                            <div className="d-flex justify-content-between">
                                              <div>
                                                <input
                                                  type="checkbox"
                                                  className="checked"
                                                  onChange={(e) => {
                                                    if (
                                                      e.target.checked == true
                                                    ) {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple1: 1,
                                                        fortshow1: "block",
                                                      });
                                                    } else {
                                                      settab3data({
                                                        ...tab3data,
                                                        fortmultiple1: 0,
                                                        fortshow1: "none",
                                                      });
                                                    }
                                                  }}
                                                />
                                                &nbsp;&nbsp; Multiple Input
                                                Selection
                                              </div>
                                            </div>
                                            <div
                                              style={{
                                                display: tab3data.fortshow1,
                                              }}
                                            >
                                              <span
                                                onClick={addmultiple1}
                                                style={{
                                                  float: "right",
                                                  marginTop: "-20px",
                                                }}
                                              >
                                                <PlusCircleOutlined />
                                              </span>
                                              {fortmultiple1.map((item, i) => {
                                                return (
                                                  <table
                                                    style={{ width: "300px" }}
                                                  >
                                                    <tr>
                                                      <th>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple1[i].statutorydate}
                                                          name="statutorydate"
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydate'
                                                            );
                                                          }}
                                                        >
                                                          {months &&
                                                            months.length > 0 &&
                                                            months.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                        <Select
                                                          allowClear={false}
                                                          size="default"
                                                          placeholder="Select"
                                                          className="form-control mt-2"
                                                          id="date"
                                                          style={{
                                                            width: "80px",
                                                          }}
                                                          value={fortmultiple1[i].statutorydays}
                                                          name="statutorydays"
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "select",
                                                              'statutorydays'
                                                            );
                                                          }}
                                                        >
                                                          {days &&
                                                            days.length > 0 &&
                                                            days.map(
                                                              (item, j) => {
                                                                return (
                                                                  <Option
                                                                    value={j + 1}
                                                                  >
                                                                    {item}
                                                                  </Option>
                                                                );
                                                              }
                                                            )}
                                                        </Select>
                                                      </th>
                                                      <th>
                                                        <Input
                                                          className="mt-2"
                                                          type="text"
                                                          autoComplete={"off"}
                                                          placeholder="Trigger before Days"
                                                          value={
                                                            fortmultiple1[i]
                                                              .triggerdays
                                                          }
                                                          name="triggerdays"
                                                          style={{
                                                            width: "100px",
                                                          }}
                                                          onChange={(e) => {
                                                            onstat_map1(
                                                              e,
                                                              i,
                                                              "input",
                                                              'triggerdays'
                                                            );
                                                          }}
                                                        />
                                                      </th>
                                                      <th
                                                        onClick={() => {
                                                          const newArray =
                                                            fortmultiple1.filter(
                                                              (item, j) => {
                                                                return j !== i;
                                                              }
                                                            );
                                                          setfortmultiple1(
                                                            newArray
                                                          );
                                                        }}
                                                      >
                                                        <CloseOutlined />
                                                      </th>
                                                    </tr>
                                                  </table>
                                                );
                                              })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                  {tab3data.frequencydata == "On Occurrence" ? (
                                    <div>
                                      <div>
                                        <label className="control-label">
                                          <b>To Complete within:</b>
                                        </label>
                                        <span style={{ color: "red" }}>*</span>
                                        &nbsp;&nbsp;
                                        <Input
                                          type="text"
                                          name="complete"
                                          autoComplete={"off"}
                                          placeholder="To complete"
                                          style={{ width: "90px" }}
                                          onKeyPress={(event) => {
                                            if (!/[0-9 +]/.test(event.key)) {
                                              event.preventDefault();
                                            }
                                          }}
                                          maxlength="3"
                                          onChange={(e) => {
                                            settab3data({
                                              ...tab3data,
                                              tocomplete: e.target.value,
                                            });
                                          }}
                                        />
                                        &nbsp;
                                        <Select
                                          allowClear={false}
                                          size="default"
                                          placeholder="Select"
                                          name="date"
                                          disabled={
                                            tab3data.tocomplete == ""
                                              ? true
                                              : false
                                          }
                                          className="form-control"
                                          id="date"
                                          style={{ width: "130px" }}
                                          onChange={(data, e) => {
                                            settab3data({
                                              ...tab3data,
                                              repeats_months: e.value,
                                              repeats_every_label: e.children,
                                            });
                                          }}
                                        >
                                          <Option>Select</Option>
                                          <Option key="1">Day(s)</Option>
                                          <Option key="2">Hour(s)</Option>
                                          <Option key="3">Month(s)</Option>
                                          <Option key="4">Year(s)</Option>
                                          <Option key="5">week(s)</Option>
                                          <Option key="6">Fortnight(s)</Option>
                                        </Select>
                                        <br />
                                        <br />
                                      </div>
                                      <span className="mt-3">
                                        <b>Summary:</b>&nbsp;&nbsp;To complete
                                        within {tab3data.tocomplete}{" "}
                                        {tab3data.repeats_every_label}
                                      </span>
                                      <div
                                        style={{
                                          display:
                                            tab3data.repeats_every_label ==
                                              "week(s)"
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <div className="mt-2">
                                          <label
                                            className="control-label"
                                            style={{ display: "flex" }}
                                          >
                                            <b>Repeats Every:</b>
                                          </label>
                                          <Select
                                            allowClear={false}
                                            size="default"
                                            placeholder="Select"
                                            name="date"
                                            className="form-control"
                                            id="date"
                                            style={{ width: "140px" }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                week1days: e.target.value,
                                              });
                                            }}
                                          >
                                            <Option>Select</Option>
                                            <Option key="1">Before</Option>
                                            <Option key="2">After</Option>
                                            <Option key="3">Current</Option>
                                          </Select>
                                          &nbsp;
                                          <label
                                            className="control-label"
                                            style={{ display: "flex" }}
                                          >
                                            <b>Repeats By(weeks):</b>
                                          </label>
                                          <Select
                                            allowClear={false}
                                            size="default"
                                            placeholder="Select"
                                            name="date"
                                            className="form-control"
                                            id="date"
                                            style={{
                                              width: "140px",
                                              display:
                                                tab3data.repeatby == 2
                                                  ? "none"
                                                  : "block",
                                            }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                week1startday: e.target.value,
                                              });
                                            }}
                                          >
                                            <Option value="1">1</Option>
                                            <Option value="2">2</Option>
                                            <Option value="3">3</Option>
                                            <Option value="4">4</Option>
                                          </Select>
                                          <label
                                            className="control-label"
                                            style={{ display: "flex" }}
                                          >
                                            <b>Trigger Before Days:</b>
                                          </label>
                                          <Input
                                            type="text"
                                            name="Trigger Days"
                                            autoComplete={"off"}
                                            placeholder="Trigger Before Day(s)"
                                            style={{ width: "170px" }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                week1triggerdays:
                                                  e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                        <span className="mt-3">
                                          <b>Summary:</b>&nbsp;&nbsp;Repeats
                                          Every{tab3data.tocomplete} weeks
                                        </span>
                                      </div>
                                      <div
                                        style={{
                                          display:
                                            tab3data.repeats_every_label ==
                                              "Fortnight(s)"
                                              ? "block"
                                              : "none",
                                        }}
                                      >
                                        <div style={{ marginTop: "15px" }}>
                                          <label className="control-label">
                                            <b>Fortnight:&nbsp;&nbsp;&nbsp;</b>
                                          </label>
                                          <Input
                                            type="text"
                                            name="Trigger Days"
                                            autoComplete={"off"}
                                            placeholder="Fortnight"
                                            style={{ width: "50px" }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                fortnight: e.target.value,
                                              });
                                            }}
                                          />
                                          &nbsp;&nbsp;
                                          <label className="control-label">
                                            <b>
                                              Repeats Every:&nbsp;&nbsp;&nbsp;
                                            </b>
                                          </label>
                                          <Select
                                            allowClear={false}
                                            size="default"
                                            placeholder="Select"
                                            name="date"
                                            disabled={
                                              tab3data.tocomplete == ""
                                                ? true
                                                : false
                                            }
                                            className="form-control"
                                            id="date"
                                            style={{ width: "130px" }}
                                            onChange={(data, e) => {
                                              settab3data({
                                                ...tab3data,
                                                fortrepeatevery: e.value,
                                              });
                                            }}
                                          >
                                            <Option>Select</Option>
                                            <Option key="1">Before</Option>
                                            <Option key="2">After</Option>
                                            <Option key="3">Current</Option>
                                          </Select>
                                          <br />
                                          <label className="control-label">
                                            <b>
                                              Repeats By
                                              (Days)&nbsp;&nbsp;&nbsp;
                                            </b>
                                          </label>
                                          <Input
                                            type="text"
                                            name="Repeats Every"
                                            autoComplete={"off"}
                                            placeholder="Fortnight"
                                            style={{ width: "70px" }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                fortrepeatby: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="row">
                                          <div className="col">
                                            <label className="control-label">
                                              <b>
                                                Repeats By&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                tab3data({
                                                  ...tab3data,
                                                  fortrepeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.fortrepeatby == 1
                                                    }
                                                  />
                                                  &nbsp;1-14
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.fortrepeatby == 2
                                                    }
                                                  />
                                                  &nbsp;1-15
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="col">
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Days&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Fortnight"
                                              style={{ width: "50px" }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  forttriggerdays:
                                                    e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                        <div style={{ marginTop: "15px" }}>
                                          <label className="control-label">
                                            <b>Fortnight:&nbsp;&nbsp;&nbsp;</b>
                                          </label>
                                          <Input
                                            type="text"
                                            name="Trigger Days"
                                            autoComplete={"off"}
                                            placeholder="Fortnight"
                                            style={{ width: "50px" }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                fortnight1: e.target.value,
                                              });
                                            }}
                                          />
                                          &nbsp;&nbsp;
                                          <label className="control-label">
                                            <b>
                                              Repeats Every:&nbsp;&nbsp;&nbsp;
                                            </b>
                                          </label>
                                          <Select
                                            allowClear={false}
                                            size="default"
                                            placeholder="Select"
                                            name="date"
                                            disabled={
                                              tab3data.tocomplete == ""
                                                ? true
                                                : false
                                            }
                                            className="form-control"
                                            id="date"
                                            style={{ width: "130px" }}
                                            onChange={(data, e) => {
                                              settab3data({
                                                ...tab3data,
                                                fortrepeatevery: e.value,
                                              });
                                            }}
                                          >
                                            <Option>Select</Option>
                                            <Option key="1">Before</Option>
                                            <Option key="2">After</Option>
                                            <Option key="3">Current</Option>
                                          </Select>
                                          <br />
                                          <label className="control-label">
                                            <b>
                                              Repeats By
                                              (Days)&nbsp;&nbsp;&nbsp;
                                            </b>
                                          </label>
                                          <Input
                                            type="text"
                                            name="Repeats Every"
                                            autoComplete={"off"}
                                            placeholder="Fortnight"
                                            style={{ width: "70px" }}
                                            onChange={(e) => {
                                              settab3data({
                                                ...tab3data,
                                                fortrepeatby: e.target.value,
                                              });
                                            }}
                                          />
                                        </div>
                                        <div className="row">
                                          <div className="col">
                                            <label className="control-label">
                                              <b>
                                                Repeats By&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <form
                                              value={tab3data.repeatby}
                                              onChange={(e) => {
                                                tab3data({
                                                  ...tab3data,
                                                  fortrepeatby: e.target.value,
                                                });
                                              }}
                                            >
                                              <div class="radio radiofill">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="1"
                                                    checked={
                                                      tab3data.fortrepeatby == 1
                                                    }
                                                  />
                                                  &nbsp;15 - End of the Month
                                                </label>
                                              </div>
                                              <div class="radio radiofill radio-inline">
                                                <label>
                                                  <input
                                                    type="radio"
                                                    value="2"
                                                    name="status"
                                                    checked={
                                                      tab3data.fortrepeatby == 2
                                                    }
                                                  />
                                                  &nbsp;16 - End of the Month
                                                </label>
                                              </div>
                                            </form>
                                          </div>
                                          <div className="col">
                                            <label className="control-label">
                                              <b>
                                                Trigger Before
                                                Days&nbsp;&nbsp;&nbsp;
                                              </b>
                                            </label>
                                            <Input
                                              type="text"
                                              name="Trigger Days"
                                              autoComplete={"off"}
                                              placeholder="Fortnight"
                                              style={{ width: "50px" }}
                                              onChange={(e) => {
                                                settab3data({
                                                  ...tab3data,
                                                  forttriggerdays:
                                                    e.target.value,
                                                });
                                              }}
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ) : (
                                    ""
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                          <div style={{ display: tablevisiable }}>
                            <Table
                              className="userprivclass"
                              columns={complianceColumn}
                              dataSource={alldetails}
                              pagination={false}
                              bordered
                            />
                          </div>
                          <Tooltip title="Add" color="#1890ff">
                            <Button
                              type="primary"
                              shape="round"
                              className="addbutton"
                              icon={<PlusOutlined />}
                              size="default"
                              style={{
                                width: "110px",
                                "margin-left": "417px",
                                "margin-top": "13px",
                                background: "#0096FF",
                              }}
                              onClick={addcompliacne}
                            >
                              Add
                            </Button>
                          </Tooltip>
                        </div>
                        <Button
                          type="primary"
                          shape="round"
                          className="addbutton"
                          style={{
                            background: "#0096FF",
                            borderColor: "#0096FF",
                            float: "left",
                            "margin-top": "35px",
                          }}
                          icon={<ArrowLeftOutlined />}
                          size="default"
                          onClick={tab3pre}
                        >
                          Previous
                        </Button>
                        <Button
                          type="primary"
                          shape="round"
                          className="addbutton"
                          style={{
                            background: "#0096FF",
                            borderColor: "#0096FF",
                            float: "right",
                            "margin-top": "35px",
                          }}

                          size="default"
                          onClick={tab3next}
                        >
                          Next <ArrowRightOutlined />
                        </Button>
                      </TabPane>
                      <TabPane tab="Applicable Location" key="4">
                        <div className="col-md-12">
                          <div className="row">
                            <div className="col-md-4">
                              <label>
                                <b>State:</b>
                                <span style={{ color: "red" }}>*</span>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label>
                                <b>District:</b>
                              </label>
                            </div>
                            <div className="col-md-4">
                              <label>
                                <b>City:</b>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  mode="multiple"
                                  placeholder="Select State"
                                  showSearch
                                  style={{ width: "300px" }}
                                  maxTagCount="responsive"
                                  value={tab4data.state || undefined}
                                  onChange={(option) => Handelstate(option)}
                                >
                                  <Option
                                    key="all"
                                    value="all"
                                    style={{ display: selectstate }}
                                  >
                                    ---SELECT ALL---
                                  </Option>
                                  <Option
                                    key="unselect"
                                    value="unselect"
                                    style={{ display: unselectstate }}
                                  >
                                    --UNSELECT ALL--
                                  </Option>
                                  {tab4data.statedata &&
                                    tab4data.statedata.length > 0 &&
                                    tab4data.statedata.map((item) => {
                                      return (
                                        <Option
                                          value={item.g_id}
                                          key={item.g_id}
                                        >
                                          {item.g_name}
                                        </Option>
                                      );
                                    })}
                                </Select>
                                {tab4.current.message(
                                  "State",
                                  tab4data.state,
                                  "required",
                                  {
                                    className: `invalid-feedback ${Tab4FormSubmit ? "show" : "hide"
                                      }`,
                                    messages: {
                                      required: "State Name Required",
                                    },
                                  }
                                )}
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  mode="multiple"
                                  size="default"
                                  placeholder="Select District"
                                  value={tab4data.district || undefined}
                                  showSearch
                                  optionFilterProp="children"
                                  maxTagCount="responsive"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(option) => Handeldistrict(option)}
                                >
                                  {tab4data.districtdata &&
                                    tab4data.districtdata.length > 0 ? (
                                    <>
                                      <Option
                                        key="all"
                                        value="all"
                                        style={{ display: selectdistrict }}
                                      >
                                        ---SELECT ALL---
                                      </Option>
                                      <Option
                                        key="unselect"
                                        value="unselect"
                                        style={{ display: unselectdistrict }}
                                      >
                                        --UNSELECT ALL--
                                      </Option>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {tab4data.statelabel &&
                                    tab4data.statelabel.length > 0 &&
                                    tab4data.statelabel.map((label, index) => {
                                      return (
                                        <OptGroup label={label.g_name}>
                                          {tab4data.districtdata[index] &&
                                            tab4data.districtdata[index]
                                              .length > 0 &&
                                            tab4data.districtdata[index].map(
                                              (item) => {
                                                return (
                                                  <Option value={item.g_id}>
                                                    {item.g_name}
                                                  </Option>
                                                );
                                              }
                                            )}
                                        </OptGroup>
                                      );
                                    })}
                                </Select>
                              </div>
                            </div>
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  mode="multiple"
                                  placeholder="Select City"
                                  showSearch
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  maxTagCount="responsive"
                                  value={tab4data.city || undefined}
                                  onChange={(option) => Handelcity(option)}
                                >
                                  {tab4data.citydata &&
                                    tab4data.citydata.length > 0 ? (
                                    <>
                                      <Option
                                        key="all"
                                        value="all"
                                        style={{ display: selectcity }}
                                      >
                                        ---SELECT ALL---
                                      </Option>
                                      <Option
                                        key="unselect"
                                        value="unselect"
                                        style={{ display: unselectcity }}
                                      >
                                        --UNSELECT ALL--
                                      </Option>
                                    </>
                                  ) : (
                                    ""
                                  )}
                                  {tab4data.districtlabel &&
                                    tab4data.districtlabel.length > 0 &&
                                    tab4data.districtlabel.map(
                                      (label, index) => {
                                        return (
                                          <OptGroup label={label.g_name}>
                                            {tab4data.citydata[index] &&
                                              tab4data.citydata[index].length >
                                              0 &&
                                              tab4data.citydata[index].map(
                                                (item) => {
                                                  return (
                                                    <Option value={item.g_id}>
                                                      {item.g_name}
                                                    </Option>
                                                  );
                                                }
                                              )}
                                          </OptGroup>
                                        );
                                      }
                                    )}
                                </Select>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "15px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <label>
                                <b>Area:</b>
                              </label>
                            </div>
                          </div>
                        </div>
                        <div
                          className="col-md-12"
                          style={{ marginTop: "10px" }}
                        >
                          <div className="row">
                            <div className="col-md-4">
                              <div className="form-group">
                                <Select
                                  allowClear={false}
                                  size="default"
                                  placeholder="Select Area"
                                  showSearch
                                  mode="multiple"
                                  optionFilterProp="children"
                                  filterOption={(input, option) =>
                                    option.children
                                      .toLowerCase()
                                      .includes(input.toLowerCase())
                                  }
                                  style={{ width: "300px" }}
                                  onChange={(data, value) => {
                                    settab4data({
                                      ...tab4data,
                                      area: value,
                                    });
                                  }}>

                                </Select>
                              </div>
                            </div>
                          </div>
                          <Button
                            type="primary"
                            shape="round"
                            className="addbutton"
                            style={{
                              background: "#0096FF",
                              borderColor: "#0096FF",
                              float: "left",
                              "margin-top": "35px",
                            }}
                            icon={<ArrowLeftOutlined />}
                            size="default"
                            onClick={tab4pre}
                          >
                            Previous
                          </Button>
                          <button
                            className="btn btn-light-success rounded-pill px-4 text-success"
                            style={{
                              float: "right",
                              "margin-top": "35px",
                              "margin-left": "14px",
                            }}
                            onClick={() => { addstatutory('save') }}
                          >
                            Save
                          </button>
                          &nbsp;&nbsp;
                          <button
                            className="btn btn-light-success rounded-pill px-4 text-success"
                            style={{ float: "right", "margin-top": "35px" }}
                            onClick={() => { addstatutory('submit') }}
                          >
                            Submit
                          </button>
                        </div>
                      </TabPane>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        ""
      )}
    </>
  );
};
export default connect(mapStateToProps, {
  savestatutorymapping,
  multiplefileupload,
  multiplefileuploadnotification
})(AddStatutoryMapping);
