import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { GETUSERS, GETREJECTEDLIST, GETBUCONSTANT,REJECTEDDOWNLOADCOUNT,
    REJECTEDFILENAME,DOWNLOADLINK } from '../../types/index'

const initialState = {
    Rejectedsummary: {
        getusers: '',
        domains: '',
        rejectedlist: '',
        downcount:'',
        rejectedfilename:'',
        downloadlink:''
    },
    Buconstant: {
        getbuconstant: '',
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case GETUSERS: {
            return {
                ...state,
                Rejectedsummary: {
                    getusers: payload['countries'],
                    domains: payload['domains']
                },
            };
        }

        case GETREJECTEDLIST: {
            return {
                ...state,
                Rejectedsummary: {
                    rejectedlist: payload['rejected_data'],
                },
            }
        }

        case GETBUCONSTANT: {
            return {
                ...state,
                Buconstant: {
                    getbuconstant: payload
                }
            }
        }

        case REJECTEDDOWNLOADCOUNT: {
            return {
                ...state,
                Rejectedsummary: {
                    downcount: payload,
                },
            };
        }
        case REJECTEDFILENAME: {
            return {
                ...state,
                Rejectedsummary: {
                    rejectedfilename: payload,
                },
            };
        }
        case DOWNLOADLINK: {
            return {
                ...state,
                Rejectedsummary: {
                    downloadlink: payload,
                },
            };
        }
        default:
            return state;
    }
}