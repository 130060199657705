import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { getapprovetaskcategorymappingfilter, ApproveTaskCategoryMapping, ApproveTaskCategoryCompliance, saveapprovetask } from '../../Store/Action/Transactions/Approvetaskcategorymapping';
import Taskcategoryedit from './Taskcategoryedit';
import Taskcategoryadd from './Taskcategoryadd';
import {
    LikeOutlined, DislikeOutlined, ExclamationCircleTwoTone,
    PlusOutlined, InfoCircleOutlined, StopOutlined, SearchOutlined, PlusCircleOutlined, CloseCircleOutlined, ArrowLeftOutlined, CheckOutlined, CloseOutlined, MinusCircleOutlined, PlayCircleOutlined
} from '@ant-design/icons';
import { update } from 'lodash';
import TextArea from 'antd/lib/input/TextArea';
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import axios from 'axios';
import url from '../../Libs/URL';
import { decode as base64_decode } from 'base-64';
import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster';


const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.Approvetaskcategorymapping.getapprovetaskcategorymappingfilterdata,
    showdatas: state.Approvetaskcategorymapping.showdata,
    showtaskcompliances: state.Approvetaskcategorymapping.showtaskcompliance

})
const Approvetaskcategorymapping = ({
    getapprovetaskcategorymappingfilter,
    lists,
    showdatas,
    showtaskcompliances,
    ApproveTaskCategoryMapping,
    ApproveTaskCategoryCompliance,
    saveapprovetask
}) => {
    console.log(lists, 'lists')
    console.log(showdatas, 'showdatas')
    console.log(showdatas.apptaskcatmapping, 'showtaskcompliances');
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const validator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [AddFormSubmit2, setAddFormSubmit2] = useState(false);
    const [complianceview, setComplianceView] = useState(false)
    const [subcategory, setSubcategory] = useState([]);
    const [tabledata, setTableData] = useState([]);
    const [passwordata, Setpassword] = useState({
        passvalue: '',
    })
    console.log(AddFormSubmit2, 'AddFormSubmit2');
    let [count, setcount] = useState(0)
    const [taskcategorymapping, setTaskCategoryMapping] = useState({
        country: '',
        countryName: '',
        category: '',
        categoryname: '',
        subcategory: '',
        subcategoryname: '',
        recordCountry: '',
        recordCategoryId: '',
        recordCategory: '',
        recordSubCategory: '',
        recordSubCategoryId: '',
    })
    console.log(taskcategorymapping, 'taskcategorymappung')
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [show, setShow] = useState(false)
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filteredDomain, setFilteredDomain] = useState([]);
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [filteredAct, setFilteredAct] = useState([]);
    const [finalArray, setFinalArray] = useState([]);
    const [datas, setDatas] = useState([]);
    const [updateStatus, setUpdateStatus] = useState([]);
    console.log(updateStatus, 'updateStatus');
    const [updateStatusParent, setUpdateStatusParent] = useState([])
    const [displaynone, setDisplayNone] = useState('displaynone')
    const [displayNoneChild, setDisplayNoneChild] = useState([])
    const [passwordModal, setPasswordModal] = useState(false)
    const formValidator1 = useRef(new SimpleReactValidator());
    const [selectedArray, setSelectedArray] = useState([])
    const [rejectedArray, setRejectedArray] = useState([])
    const [holdArray, setHoldArray] = useState([])
    const [updateParentRemarks, setUpdateParentRemarks] = useState([])
    const [updateRemarks, setUpdateRemarks] = useState([])
    const [updateChildRemarks, setUpdateChildRemarks] = useState([])
    const [updateRemarksChild, setUpdateRemarksChild] = useState([])
    const [finalRemarks, setFinalRemarks] = useState([])
    const [triggerValidation, setTriggerValidation] = useState([])
    const formValidator2 = useRef(new SimpleReactValidator());
    const [triggerValidationParent, setTriggerValidationParent] = useState([])
    const [finalSelectedArray, setFinalSelectedArray] = useState([])

    console.log(finalRemarks, 'finalArray') //compliance_id


    useEffect(() => {
        formValidator1.current.showMessages()
        formValidator2.current.showMessages()
        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetApproveTaskCategoryMappingFilter",
                    {}
                ]
            }
            getapprovetaskcategorymappingfilter({
                payload: payload,
                caller: paramid,
            })

        }
    }, [isAuth]);

    useEffect(() => {
        if (showdatas) {
            let unique = []
            if (showdatas && showdatas.apptaskcatmapping && showdatas.apptaskcatmapping.length > 0) {
                unique = _.uniqBy(showdatas && showdatas.apptaskcatmapping, function (e) {
                    return e.task_group_id && e.task_sub_group_id;
                });
            }
            setTableData(unique)
        }
    }, [showdatas])

    useEffect(() => {
        if (showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0) {
            let uniqueObjArray = [...new Map(showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0 && showtaskcompliances.apptaskcatcompliance.map((item) => [item["domain_name"], item])).values()];
            console.log(uniqueObjArray, 'uniqueObjArray');
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.domain_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0 && showtaskcompliances.apptaskcatcompliance, { domain_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            console.log(uniqueIds, 'tempArry')
            setFilteredDomain([...filteredDomain, tempArr])
            setComplianceView(true)
        }
    }, [showtaskcompliances.apptaskcatcompliance])

    useEffect(() => {
        if (filteredDomain.length > 0) {
            let temp = []
            for (let i in filteredDomain) {
                console.log(filteredDomain[i], 'filteredArray[i]');
                temp = filteredDomain[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredDomain])

    useEffect(() => {
        let tempArr = []
        if (showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length > 0 && item.map((items) => [items["act_name"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.act_name
                })
                console.log(uniqueIds, 'uniqueIds')
                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { act_name: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })
            setFilteredAct(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let countrylisttempdataNew = [];
        if (showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push({ 'act_name': item.act_name, 'domain_name': item.domain_name })
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        showtaskcompliances.apptaskcatcompliance && showtaskcompliances.apptaskcatcompliance.length > 0 && showtaskcompliances.apptaskcatcompliance.map((child, childIndex) => {
                            if (data.act_name === child.act_name && data.domain_name == child.domain_name) {
                                entityArray = {
                                    id: child.id,
                                    country_name: child.country_name,
                                    task_group_id: child.task_group_id,
                                    task_sub_group_id: child.task_sub_group_id,
                                    task_group_name: child.task_group_name,
                                    task_sub_group_name: child.task_sub_group_name,
                                    domain_name: child.domain_name,
                                    domain_id: child.domain_id,
                                    mode: child.mode,
                                    approved_by: child.approved_by,
                                    rejected_remarks: child.rejected_remarks,
                                    is_active: child.is_active,
                                    act_name: child.act_name,
                                    statutory_id: child.statutory_id,
                                    compliance_id: child.compliance_id,
                                    statutory_provision: child.statutory_provision,
                                    c_task_name: child.c_task_name,
                                    description: child.description,
                                    parent_names: child.parent_names,
                                    parent_id: i,
                                    is_demap: child.is_demap,
                                    act_id: child.act_id,
                                    serial_num: childIndex + 1
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    countrylisttempdataNew.push(temp)
                });

                var merged = [].concat.apply([], countrylisttempdataNew);
                setFinalArray(finalarray)
                setDatas(merged)
            }
        }
    }, [showtaskcompliances.apptaskcatcompliance, filteredAct])


    useEffect(() => {
        validator.current.showMessages()
    }, [])

    const viewdata = (data) => {
        console.log(data, 'datadata')

        setTaskCategoryMapping({
            ...taskcategorymapping,
            recordCountry: data.country_name,
            recordCategory: data.task_group_name,
            recordSubCategory: data.task_sub_group_name,
            recordCategoryId: data.task_group_id,
            recordSubCategoryId: data.task_sub_group_id,
        })
        ApproveTaskCategoryCompliance({
            payload: {
                "session_token": authtoken,
                "request": [
                    "GetApproveTaskCategoryCompliance",
                    {
                        "c_id": data.country_id,
                        "task_group_id": data.task_group_id,
                        "task_sub_group_id": data.task_sub_group_id,
                        "r_count": 0,
                        "page_count": 100
                    }
                ]
            },
            paramid: paramid
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '50px',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Country',
            dataIndex: 'country_name',
            key: 'country_name',
            ellipsis: true,
        },
        {
            title: 'Task Category',
            dataIndex: 'task_group_name',
            key: 'task_group_name',
            ellipsis: true,

        },
        {
            title: 'Task Sub Category',
            dataIndex: 'task_sub_group_name',
            key: 'task_sub_group_name',
            ellipsis: true,
        },
        {
            title: '',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            render: (text, record, index) => {
                return (
                    <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }} size='default' onClick={() => viewdata(record)} >View</Button>
                );
            }
        },
    ];
    const previous = () => {
        window.location.reload()
    }
    const childStatusChange = (parentId, changeto, data) => {
        if (changeto == 2) {
            setDisplayNone('')
            setDisplayNoneChild('displaynone')
        }
        updateStatusParent[parentId] = changeto
        setUpdateStatusParent([...updateStatusParent])
        for (let i in data) {
            updateStatus[data[i].id] = changeto
            setUpdateStatus([...updateStatus])
            displayNoneChild[data[i].id] = 'displaynone'
            setDisplayNoneChild([...displayNoneChild])
        }
    }

    const parentStatusChange = (parentId, childId, changeto, childData) => {


        /*Before set into updateStatus state while onload */
        updateChildRemarks[childId] = undefined
        setUpdateChildRemarks([...updateChildRemarks])
        if (childData.length != 1) {

            /* remove parent once child input enables*/
            Object.keys(updateParentRemarks).forEach(key => {
                if (key == parentId) {
                    delete updateParentRemarks[parentId];
                }
            });


            let temp = [];
            let tempParent = [];
            for (let i in childData) {
                tempParent.push(childData[i].parent_id)
            }
            let finalArrayParent = [...new Set(tempParent)]
            for (let j in childData) {
                if (finalArrayParent[0] == parentId) {
                    temp.push(updateStatus[childData[j].id])
                }
            }
            let data = temp.filter(function (element) {
                return element !== undefined;
            });


            let unqiueArrayValueCheck = data.every((val, i, arr) => val === arr[0])
            console.log(data, 'unqiueArrayValueCheck')

            if (childData.length == data.length) {
                if (unqiueArrayValueCheck == true) {
                    updateStatusParent[parentId] = changeto
                    setUpdateStatusParent([...updateStatusParent])
                    if (changeto == 2) {
                        setAddFormSubmit2(false)
                        for (let l in childData) {
                            if (finalArrayParent[0] == parentId) {
                                displayNoneChild[childData[l].id] = 'displaynone'
                                setDisplayNoneChild([...displayNoneChild])
                            }
                        }
                    }

                } else {
                    console.log('second_else')
                    updateStatusParent[parentId] = 0
                    setUpdateStatusParent([...updateStatusParent])
                    for (let l in childData) {
                        if (finalArrayParent[0] == parentId) {
                            displayNoneChild[childData[l].id] = ''
                            setDisplayNoneChild([...displayNoneChild])
                        }
                    }
                }

            } else {

                updateStatusParent[parentId] = 0
                setUpdateStatusParent([...updateStatusParent])
                for (let l in childData) {
                    if (finalArrayParent[0] == parentId) {
                        displayNoneChild[childData[l].id] = ''
                        setDisplayNoneChild([...displayNoneChild])
                    }
                }
            }


        } else {
            updateStatusParent[parentId] = changeto
            setUpdateStatusParent([...updateStatusParent])
            if (changeto == 2) {
                for (let m in childData) {
                    displayNoneChild[childData[m].id] = 'displaynone'
                }
                setDisplayNoneChild([...displayNoneChild])
            }
        }

    }

    useEffect(() => {
        if (updateParentRemarks != null) {
            console.log(Object.keys(updateParentRemarks), 'updateParentRemarks')
        } else {
            console.log('else', 'updateParentRemarks')
        }
    }, [updateParentRemarks])

    const verifypassword = () => {

        /* Remarks Required Functin */
        for (let element in datas) {
            if (updateStatus[datas[element].id] == 2) {
                console.log(datas[element].id, 'elementid')
                if (updateChildRemarks[datas[element].id] == '' || updateChildRemarks[datas[element].id] == undefined) {
                    triggerValidation[datas[element].id] = 'Remarks Requireds'
                    setTriggerValidation([...triggerValidation])
                } else {
                    triggerValidation[datas[element].id] = undefined
                    setTriggerValidation([...triggerValidation])
                }
            } else {
                triggerValidation[datas[element].id] = undefined
                setTriggerValidation([...triggerValidation])
            }
        }
        /* Remarks Required Functin */

        /*for splitting values select reject hold*/
        Object.keys(updateStatus).forEach(key => {
            if (updateStatus[key] === undefined) {
                delete updateStatus[key];
            }
        });
        let selectedArr = [];
        let rejectedArr = [];
        let holdArr = [];
        for (let split in updateStatus) {
            if (updateStatus[split] == 1) {
                selectedArr.push(parseInt(split))
            } else if (updateStatus[split] == 2) {
                rejectedArr.push(parseInt(split))
            } else if (updateStatus[split] == 0) {
                holdArr.push(parseInt(split))
            }
            console.log(updateStatus, 'objectKeyss')
        }
        setSelectedArray([...selectedArr])
        setRejectedArray([...rejectedArr])
        setHoldArray([...holdArr])
        /*for splitting values select reject hold*/        
        if (triggerValidation.filter(Boolean).length == 0 && triggerValidationParent.filter(Boolean).length == 0 && datas.length != holdArr.length) {
            setPasswordModal(true);

        } else {
            if(datas.length == holdArr.length){
                Toaster.error('No compliance selected')
            }else{
                Toaster.error(triggerValidation.filter(Boolean)[triggerValidation.filter(Boolean).length - 1]);
                Toaster.error(triggerValidationParent.filter(Boolean)[triggerValidationParent.filter(Boolean).length - 1]);
            }
           
        }

    }

    const passwordcancel = () => {
        setPasswordModal(false)
        Setpassword({
            ...passwordata,
            passvalue: ''
        })
        setAddFormSubmit1(false)
    }

    const submitpassword = () => {

        setAddFormSubmit1(true)
        if (formValidator1.current.allValid()) {
            var finalMerged = [].concat.apply([], [selectedArray, rejectedArray, holdArray]);
            console.log(finalMerged, 'finalMerged')
            let tempFinalArray = []
            for (let finalArrayform in datas) {
                if (!finalMerged.includes(parseInt(datas[finalArrayform].id))) {
                    tempFinalArray.push(datas[finalArrayform].id)
                }
            }
            setFinalSelectedArray([...tempFinalArray])

            let tempComps_id = []
            let fids = []
            for (let finalcompsid in tempFinalArray) {
                /* there is no way to get domain_name so get 0th array in the records*/
                var findComp = _.find(datas, { id: parseInt(tempFinalArray[finalcompsid]) })
                tempComps_id.push(findComp.compliance_id)
                fids.push(0)
            }

            /*for splitting values select reject hold*/
            /*final Payload Process*/
            let finalTempRemarkArr = []
            Object.keys(updateParentRemarks).forEach(key => {
                let tempRemarksArr = []
                if (updateParentRemarks[key] != undefined) {
                    let getChildData = _.find(finalArray, { index: parseInt(key) })
                    for (let splitremarks = 0; splitremarks < getChildData.child.length; splitremarks++) {
                        tempRemarksArr.push(updateParentRemarks[key])
                    }
                    console.log(tempRemarksArr, 'tempRemarksArr')

                    finalTempRemarkArr.push(tempRemarksArr)
                }
            });
            setUpdateRemarks([...[].concat.apply([], finalTempRemarkArr)])

            // let finalTempRemarkArrChild = []
            // Object.keys(updateChildRemarks).forEach(key => {
            //     if (updateChildRemarks[key] != undefined) {
            //         finalTempRemarkArrChild.push(updateChildRemarks[key])
            //     }
            // });
            // setUpdateRemarksChild([...finalTempRemarkArrChild])
            // let array3 = updateRemarks.concat(updateRemarksChild)
            // setFinalRemarks([...updateRemarks, ...updateRemarksChild])
            let finalTempRemarkArrChild = []

            for (let remarksPayload in updateChildRemarks) {
                if (updateChildRemarks[remarksPayload] != undefined) {
                    finalTempRemarkArrChild.push(updateChildRemarks[remarksPayload])
                }
            }


            const passpay = {
                "session_token": authtoken,
                "request": [
                    "VerifyPassword",
                    {
                        "password": passwordata.passvalue
                    }
                ]
            }

            const encryptkey = localStorage.getItem('formkey')
            let encoded = base64_decode(encryptkey);
            const data1 = EncryptDecrypt.encryptdata(passpay, encoded);
            const head = {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            }

            axios.post(`${url.CommonURL}/api/general`, data1, {
                headers: head
            }).then((response) => {
                const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response.data, encoded))
                if (decryptData[0] == 'InvalidPassword') {
                    Toaster.error('Invalid Password')
                    Setpassword({
                        ...passwordata,
                        passvalue: ''
                    })
                    setAddFormSubmit1(false)
                } else if(decryptData[0] == 'VerifyPasswordSuccess'){
                    const finalPayload = {
                        "session_token": authtoken,
                        "request": [
                            "SaveApproveTaskCategoryMapping",
                            {
                                "c_id": parseInt(taskcategorymapping.country),
                                "task_group_id": parseInt(taskcategorymapping.recordCategoryId),
                                "task_sub_group_id": parseInt(taskcategorymapping.recordSubCategoryId),
                                "compliance_ids": tempFinalArray,
                                "rejected_ids": rejectedArray,
                                "remarks_list": finalTempRemarkArrChild,
                                "country_name": taskcategorymapping.countryName,
                                "task_group_name": taskcategorymapping.recordCategory,
                                "task_sub_group_name": taskcategorymapping.recordSubCategory,
                                "domain_name": datas[0].domain_name, //there is no way to get the domain name so get 0th element from array.
                                "employee_name": callername.login_response.user_group_name, //needs to get it from local storage
                                "f_ids": fids,
                                "comp_ids": tempComps_id

                            }
                        ]
                    }
                    console.log(finalPayload,'finalPayload')

                    saveapprovetask({
                        payload: finalPayload,
                        paramid: paramid,
                    })
                }else{
                    Toaster.error(decryptData[0])
                }
            })
                .catch((error) => {
                    console.log(error, 'error')
                    // dispatch({
                    //   type: ERROR_FINDING_USER
                    // })
                })
        } else {
            console.log('in')
        }


    }

    const updateChildRemarksFunction = (data, value) => {
        console.log(value, 'valuevalue')
        for (let childele = 0; childele < data.length; childele++) {
            console.log(data[childele].id, 'data[childele]')
            updateChildRemarks[data[childele].id] = value
        }
        setUpdateChildRemarks([...updateChildRemarks])

    }


    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link>Transactions</Link>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <Link><b>Approve Task Category Mapping</b></Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        {complianceview == true ?
                            <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                                <div>
                                    <Tooltip title="" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                        icon={<ArrowLeftOutlined />} size='default' onClick={previous} >
                                        Back
                                    </Button></Tooltip></div>

                            </div>
                            : ''}
                    </div>
                </div>

                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                {complianceview == false ?
                                    <Collapse defaultActiveKey={["1"]} >
                                        <Panel header="Approve Task Category Mapping" key={1}>
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Country</b> <span style={{ color: "red" }}>*</span></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Country"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                value={taskcategorymapping.countryName || undefined}
                                                                onChange={(value, data) => {
                                                                    setTaskCategoryMapping
                                                                        ({
                                                                            ...taskcategorymapping,
                                                                            country: value,
                                                                            countryName: data.children,
                                                                            category: '',
                                                                            categoryname: '',
                                                                            subcategory: '',
                                                                            subcategoryname: ''
                                                                        })
                                                                }}
                                                            >
                                                                {lists && lists.countries && lists.countries.length > 0 && lists.countries.map((item, i) => {
                                                                    return (
                                                                        <Option title=" " key={item.country_id}>
                                                                            {item.country_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                            {validator.current.message(
                                                                'country',
                                                                taskcategorymapping.country,
                                                                ['required'],
                                                                {
                                                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                    messages: {
                                                                        required: 'Country Required',
                                                                    }
                                                                })}
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Task Category</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Task Category"
                                                                showSearch
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                value={taskcategorymapping.categoryname || undefined}
                                                                onChange={(value, data) => {
                                                                    let subcat = _.filter(lists.task_sub_cat, { task_group_id: Number(value) })
                                                                    setSubcategory(subcat)
                                                                    setTaskCategoryMapping
                                                                        ({
                                                                            ...taskcategorymapping,
                                                                            category: value,
                                                                            categoryname: data.children,
                                                                            subcategory: '',
                                                                            subcategoryname: ''
                                                                        })
                                                                }}
                                                                disabled={taskcategorymapping.country == '' ? true : false}
                                                            >
                                                                {lists && lists.task_group && lists.task_group.length > 0 && lists.task_group.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.task_group_id}>
                                                                            {item.task_group_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Task Sub Category</b></label>
                                                        <div className="form-group">
                                                            <Select
                                                                allowClear={false}
                                                                size="default"
                                                                placeholder="Enter Task Subcategory"
                                                                showSearch
                                                                disabled={taskcategorymapping.category == '' ? true : false}
                                                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                                style={{ width: '100%' }}
                                                                value={taskcategorymapping.subcategoryname || undefined}
                                                                onChange={(value, data) => {
                                                                    setTaskCategoryMapping
                                                                        ({
                                                                            ...taskcategorymapping,
                                                                            subcategory: value,
                                                                            subcategoryname: data.children
                                                                        })
                                                                }}
                                                            >
                                                                {subcategory && subcategory.length > 0 && subcategory.map((item, i) => {
                                                                    return (
                                                                        <Option key={item.task_sub_group_id}>
                                                                            {item.task_sub_group_name}
                                                                        </Option>
                                                                    );
                                                                })}
                                                            </Select>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                                <div className='row'>
                                                    <div className="col-md-6">
                                                        <Button
                                                            onClick={() => {
                                                                const payload =
                                                                {
                                                                    "session_token": authtoken,
                                                                    "request": [
                                                                        "GetApproveTaskCategoryMapping",
                                                                        {
                                                                            "c_id": Number(taskcategorymapping.country),
                                                                            "task_group_id": taskcategorymapping.category ? Number(taskcategorymapping.category) : null,
                                                                            "task_sub_group_id": taskcategorymapping.subcategory ? Number(taskcategorymapping.subcategory) : null
                                                                        }
                                                                    ]
                                                                }

                                                                setAddFormSubmit(true);
                                                                if (validator.current.allValid()) {
                                                                    setShow(true)
                                                                    ApproveTaskCategoryMapping({
                                                                        payload: payload,
                                                                        paramid: paramid
                                                                    })
                                                                }
                                                            }}
                                                            type="primary"
                                                            shape="round"
                                                            className='addbutton'
                                                            style={{
                                                                marginTop: "20px"
                                                            }}
                                                        >
                                                            Show
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                            {show == true ?
                                                <>
                                                    <div className="col-lg-12" style={{ marginTop: '5%' }}>
                                                        <div className="row">
                                                            <Table
                                                                className='userprivclass'
                                                                columns={columns}
                                                                dataSource={tabledata && tabledata.length > 0 && tabledata}
                                                                bordered
                                                                pagination={false}
                                                            />
                                                        </div>
                                                    </div>
                                                </>
                                                : ""}
                                        </Panel>
                                    </Collapse>
                                    : false}
                            </div>
                            <br />
                            {complianceview == true ?

                                <>
                                    <div style={{ marginTop: '-30px' }}>
                                        <Card title="Approve Task Category Mapping - View">
                                            <div className='col-md-12'>
                                                <div className='row'>
                                                    <div className='col-md-4'>
                                                        <label><b>Country</b> : {taskcategorymapping.recordCountry}</label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Task Category</b> : {taskcategorymapping.recordCategory}</label>
                                                    </div>
                                                    <div className='col-md-4'>
                                                        <label><b>Task Sub Category</b> : {taskcategorymapping.recordSubCategory}</label>
                                                    </div>
                                                </div>
                                            </div>
                                            <br />
                                            <div className='ant-table-wrapper'>
                                                {filteredArrayState && filteredArrayState.length > 0 ? filteredArrayState.map((item, i) => {
                                                    return (<Collapse defaultActiveKey={1} >
                                                        <Panel header={item[0].domain_name} key={1}>

                                                            <div className='ant-table ant-table-bordered'>
                                                                <div className='ant-table-container'>
                                                                    <div className='ant-table-content fh-approve-cm'>
                                                                        <table className='widthHeigh'>
                                                                            <colgroup>
                                                                                <col style={{ width: '10px' }}></col>
                                                                            </colgroup>
                                                                            <thead className='ant-table-thead'>
                                                                                <tr>
                                                                                    <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                                    <th className='ant-table-cell' style={{ width: '200px' }}>Statutory Provision</th>
                                                                                    <th className='ant-table-cell' style={{ width: '200px' }}><center>Compliance Task</center></th>
                                                                                    <th className='ant-table-cell' style={{ width: '200px' }}><center>Compliance Description</center></th>
                                                                                    <th className='ant-table-cell' style={{ width: '50px' }}><center><CheckOutlined /></center></th>
                                                                                    <th className='ant-table-cell' style={{ width: '200px' }}><center>Remarks <span style={{ color: 'red' }}>*</span></center></th>
                                                                                </tr>
                                                                            </thead>
                                                                            <tbody className='ant-table-tbody'>
                                                                                <>
                                                                                    {
                                                                                        finalArray && finalArray.length > 0 && finalArray.map((itemdata, i) => {
                                                                                            let data = _.find(itemdata.child, { domain_name: item[0].domain_name })
                                                                                            if (data != undefined) {
                                                                                                if (data.act_name == itemdata.parent['act_name']) {


                                                                                                    return <>
                                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                            {updateStatusParent[itemdata.index] == 1 ? <td colSpan={6} className='ant-table-cell'><span onClick={(e) => {
                                                                                                                childStatusChange(itemdata.index, 2, itemdata.child)
                                                                                                            }}><CheckOutlined /></span>  <b>{itemdata.parent['act_name']}</b></td> : updateStatusParent[itemdata.index] == 2 ? <td colSpan={6} className='ant-table-cell'><div className='row'><div className='col-lg-8'><span onClick={(e) => {
                                                                                                                childStatusChange(itemdata.index, 0, itemdata.child)
                                                                                                            }}><CloseOutlined /></span>  <b>{itemdata.parent['act_name']}</b></div> <div className='col-lg-4'><Input type='text' placeholder='Enter Remarks' className={'parentClass_' + itemdata.index} style={{ width: '50%', marginLeft: '50%' }}
                                                                                                                onBlur={(e) => {
                                                                                                                    if (e.target.value != null) {
                                                                                                                        updateParentRemarks[itemdata.index] = e.target.value
                                                                                                                        setUpdateParentRemarks([...updateParentRemarks])

                                                                                                                        triggerValidationParent[itemdata.index] = undefined
                                                                                                                        setTriggerValidationParent([...triggerValidationParent])

                                                                                                                        updateChildRemarksFunction(itemdata.child, e.target.value)
                                                                                                                    } else {
                                                                                                                        triggerValidationParent[itemdata.index] = 'Remarks Required'
                                                                                                                        setTriggerValidationParent([...triggerValidationParent])
                                                                                                                    }

                                                                                                                }}
                                                                                                            /></div></div></td> : updateStatusParent[itemdata.index] == 0 ? <td colSpan={6} className='ant-table-cell'><span onClick={(e) => {
                                                                                                                childStatusChange(itemdata.index, 1, itemdata.child)
                                                                                                            }}><i className='fas fa-h-square' style={{ color : 'black' }} /></span>  <b>{itemdata.parent['act_name']}</b></td> : <td colSpan={6} className='ant-table-cell'><span onClick={(e) => {
                                                                                                                childStatusChange(itemdata.index, 2, itemdata.child)
                                                                                                            }}><CheckOutlined /></span>  <b>{itemdata.parent['act_name']}</b></td>}

                                                                                                        </tr>
                                                                                                        {itemdata.child.map((items, j) => {
                                                                                                            return <>
                                                                                                                <tr className={items.is_demap == 1 ? 'unmap ant-table-row ant-table-row-level-0' : 'actual ant-table-row ant-table-row-level-0'}>
                                                                                                                    <td className='ant-table-cell'><center>{items.serial_num}</center></td>
                                                                                                                    <td title={items.statutory_provision} className='ant-table-cell ellipsis ant-table-cell-with-append'>
                                                                                                                        {items.statutory_provision}</td>
                                                                                                                    {items.is_demap == 1 ? 
                                                                                                                    <td title={items.c_task_name} className='ant-table-cell ellipsis'><Tooltip title={'Unmapped'}><ExclamationCircleTwoTone /></Tooltip> {items.c_task_name}</td>
                                                                                                                    : <td title={items.c_task_name} className='ant-table-cell ellipsis'>{items.c_task_name}</td>
                                                                                                                    }
                                                                                                                    <td title={items.description} className='ant-table-cell ellipsis'>{items.description}</td>
                                                                                                                    {updateStatus[items.id] == 1 ?
                                                                                                                        <td><center><span onClick={(e) => {
                                                                                                                            updateStatus[items.id] = 2
                                                                                                                            setUpdateStatus([...updateStatus])
                                                                                                                            parentStatusChange(items.parent_id, items.id, 2, itemdata.child)
                                                                                                                        }}><CheckOutlined /></span></center></td>
                                                                                                                        : updateStatus[items.id] == 2 ? <td><center><span onClick={(e) => {
                                                                                                                            updateStatus[items.id] = 0
                                                                                                                            setUpdateStatus([...updateStatus])
                                                                                                                            parentStatusChange(items.parent_id, items.id, 0, itemdata.child)
                                                                                                                        }}><CloseOutlined /></span></center></td>
                                                                                                                            : updateStatus[items.id] == 0 ? <td><center><span onClick={(e) => {
                                                                                                                                updateStatus[items.id] = 1
                                                                                                                                setUpdateStatus([...updateStatus])
                                                                                                                                parentStatusChange(items.parent_id, items.id, 1, itemdata.child)
                                                                                                                            }}><i className='fas fa-h-square' style={{ color : 'black' }} /></span></center></td> : <td><center><span onClick={(e) => {
                                                                                                                                updateStatus[items.id] = 2
                                                                                                                                setUpdateStatus([...updateStatus])
                                                                                                                                parentStatusChange(items.parent_id, items.id, 2, itemdata.child)
                                                                                                                            }}><CheckOutlined /></span></center></td>}

                                                                                                                    <td className='ant-table-cell ellipsis'>
                                                                                                                        {updateStatus[items.id] == 2 ?
                                                                                                                            <>
                                                                                                                                <TextArea name="remarks" id={'childId_' + items.id} className={displayNoneChild[items.id]} placeholder='Enter Remarks' maxLength={500}
                                                                                                                                    onBlur={(e) => {
                                                                                                                                        console.log(e, 'e.target.value')
                                                                                                                                        if (e.target.value != null) {
                                                                                                                                            updateChildRemarks[items.id] = e.target.value;
                                                                                                                                            setUpdateChildRemarks([...updateChildRemarks])
                                                                                                                                            triggerValidation[items.id] = undefined
                                                                                                                                            setTriggerValidation([...triggerValidation])
                                                                                                                                        } else {
                                                                                                                                            triggerValidation[items.id] = 'Remarks Required'
                                                                                                                                            setTriggerValidation([...triggerValidation])
                                                                                                                                        }

                                                                                                                                    }}
                                                                                                                                ></TextArea>
                                                                                                                            </>
                                                                                                                            : false}
                                                                                                                    </td>
                                                                                                                </tr>
                                                                                                            </>
                                                                                                        })}
                                                                                                    </>
                                                                                                }
                                                                                            }
                                                                                        })
                                                                                    }
                                                                                </>
                                                                            </tbody>

                                                                        </table>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                        </Panel>
                                                    </Collapse>
                                                    )
                                                }) : ''}
                                            </div>
                                            <br />
                                            <div className='row'>
                                                <div className='col-lg-12'>
                                                    <div className='col-lg-4' style={{ float: 'left' }}>
                                                        Showing 1 to {datas.length} of {datas.length} entries
                                                    </div>
                                                    <div className='col-lg-8' style={{ marginLeft: '44%' }}>
                                                        <Button type="primary" shape="round" className='addbutton'
                                                            style={{ background: "#198754", borderColor: "#198754" }}
                                                            icon={<PlayCircleOutlined />} size='default'
                                                            onClick={verifypassword}
                                                        >
                                                            Submit
                                                        </Button>
                                                    </div>
                                                </div>
                                            </div>
                                        </Card>
                                    </div>

                                </>
                                : ''}
                        </div>
                    </div>
                </div>

                <Modal open={passwordModal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="mb-6">
                                <label>Enter Password</label>
                                <span style={{ "color": "red" }}>*</span>
                                <input
                                    type="password"
                                    id="password"
                                    name='passwordvalue'
                                    className="form-control"
                                    placeholder="Enter password"
                                    onChange={(e) => {
                                        Setpassword({
                                            ...passwordata,
                                            passvalue: e.target.value

                                        })
                                    }}
                                    value={passwordata.passvalue}
                                />
                                {formValidator1.current.message(
                                    'Password',
                                    passwordata.passvalue,
                                    ['required'],
                                    {
                                        className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                        messages: {
                                            regex: 'Enter Password'
                                        }
                                    })}
                            </div>
                        </div>

                    </div>
                    <br />
                    <div className="form-actions">
                        <Button type="primary" shape="round" className='addbutton'
                            style={{ background: "#198754", borderColor: "#198754", marginLeft: "35%" }}
                            icon={<PlayCircleOutlined />} size='default'
                            onClick={submitpassword}
                        >
                            Submit
                        </Button>
                    </div>
                </Modal>

            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    getapprovetaskcategorymappingfilter,
    ApproveTaskCategoryMapping,
    ApproveTaskCategoryCompliance,
    saveapprovetask    
})(Approvetaskcategorymapping);