import { Fragment, useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import { Link, useLocation } from 'react-router-dom'
import { Input, Button, Tooltip } from 'antd';
import Swal from 'sweetalert2';
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import SimpleReactValidator from "simple-react-validator";
import { toast } from 'react-toastify';
import { Updatepassword } from './../Store/Action/Profile'
const _ = require("lodash");
const mapStateToProps = () => ({});
const ChangePassword = ({
    Updatepassword
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const { Search } = Input;
    const validator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [password, setPassword] = useState({
        current_password: '',
        new_password: '',
        confirm_password: ''
    })
    const [show, setShow] = useState(false)
    // const [Entity, setEntity] = useState("All Legal Entity")
    const [modalVisible, setModaVisible] = useState(false)

    const userDetailOnSubmit = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            // setSelectUnit(true)
        }
    }
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const sessionArr = sessionParsedValue.login_response
    const sessionArr123 = sessionParsedValue && sessionParsedValue.login_response && sessionParsedValue.login_response.menu
    const account = Object.values(sessionArr123)
    const getUrl = account[0]['My Accounts']
    console.log(getUrl, "getUrl");
    const password12 = _.filter(getUrl, { form_name: 'Change Password' })
    const [formdata, Setformdata] = useState({
        form_url: '',
        form_key: ''
    });
    const [changepassword, Setchangepassword] = useState();

    useEffect(() => {
        if (isAuth) {
            const ChangePassword = _.filter(getUrl, { form_name: 'Change Password' })
            Setchangepassword(ChangePassword[0].form_url)
            Setformdata({
                ...formdata,
                form_url: password12 && password12[0] && password12[0].form_url,
                form_key: password12 && password12[0] && password12[0].form_key
            })
        }
    }, [isAuth])

    const onsubmit = () => {
        console.log("hello");
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            console.log("hiii");
            if (password.new_password === password.confirm_password) {
                Updatepassword({
                    payload: 
                        // authtoken,
                        [
                            "ChangePassword",
                            {
                                "session_token": authtoken,
                                "current_password": password.current_password,
                                "new_password": password.new_password
                            }
                        ]
                    ,
                    paramid: paramid,
                    formkey: formdata.form_key,
                    form_url:formdata.form_url
                })
            }
            else {
                toast.error("New password and Confirn password Should Match")
            }
        }
    }
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    console.log(password, "passwordpassword");
    return (
        <Fragment>
            <div>
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>My Accounts</Link>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <Link><b>Change Password</b></Link>
                                        </li>
                                    </ol>
                                </nav>
                                {/* <h1 className="mb-0 fw-bold">Change Password </h1> */}
                            </div>

                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">
                                    <div className="card-body" style={{ padding: "30px 15px" }}>
                                        <div className="col-md-12">
                                            <div className='row'>
                                                <div className='col-md-1'></div>
                                                <div className="col-md-3">
                                                    <label htmlFor="" style={{ float: "right" }}><b>Current Password </b><span style={{ "color": "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <Input
                                                        type="password"
                                                        placeholder='Enter Current Password'
                                                        onChange={(e) => {
                                                            setPassword({
                                                                ...password,
                                                                current_password: e.target.value
                                                            })
                                                        }}
                                                    />
                                                    {validator.current.message(
                                                        'currrent password',
                                                        password.current_password,
                                                        [
                                                            'required',
                                                            // {
                                                            //     regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
                                                            // },
                                                        ],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Current Password Required',
                                                               // regex: 'Minimum eight and maximum fifteen characters, at least one uppercase letter, one lowercase letter, one number and one special character (@$!%*?&)',
                                                            }
                                                        })}

                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "30px" }}>
                                            <div className='row'>
                                                <div className='col-md-1'></div>
                                                <div className="col-md-3">
                                                    <label htmlFor="" style={{ float: "right" }}><b>New Password </b><span style={{ "color": "red" }}>*</span>                                           <Tooltip placement="right" style={{ width: "200px" }}
                                                        title={
                                                            <div>
                                                                <p>* The password is at least 8 characters long.</p>
                                                                <p>* The password has at least one uppercase letter.</p>
                                                                <p>* The password has at least one lowercase letter.</p>
                                                                <p>* The password has at least one digit.</p>
                                                                <p>* The password has at least one special character.</p>
                                                            </div>
                                                        } >
                                                        <ExclamationCircleTwoTone />
                                                    </Tooltip></label>

                                                </div>
                                                <div className='col-md-4'>
                                                    <Input
                                                        type="password"
                                                        name="new_password"
                                                        placeholder='Enter New Password'
                                                        onChange={(e) => {
                                                            setPassword({
                                                                ...password,
                                                                new_password: e.target.value
                                                            })
                                                        }}
                                                    />

                                                    {validator.current.message(
                                                        'new password',
                                                        password.new_password,
                                                        [
                                                            'required',
                                                            {
                                                                regex: /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,15}$/,
                                                            },
                                                        ],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'New Password Required',
                                                                regex: 'Password Should Contain Alphabet Number and Special Characeter',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                        <div className='col-md-12' style={{ marginTop: "30px" }}>
                                            <div className='row'>
                                                <div className='col-md-1'></div>
                                                <div className="col-md-3">
                                                    <label htmlFor="" style={{ float: "right" }}><b>Confirm Password</b> <span style={{ "color": "red" }}>*</span></label>
                                                </div>
                                                <div className='col-md-4'>
                                                    <Input
                                                        type="password"
                                                        name="confirm_password"
                                                        placeholder='Enter Confirm Password'
                                                        onChange={(e) => {
                                                            setPassword({
                                                                ...password,
                                                                confirm_password: e.target.value
                                                            })
                                                        }} />
                                                    {validator.current.message(
                                                        'confirm_password',
                                                        password.confirm_password,
                                                        ['required', `in:${password.new_password}`],
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Confirm Password Required',
                                                                in: "New Password and Confirm password doesn't match",
                                                            }
                                                        })}
                                                </div>
                                                <div className='col-md-4'></div>
                                            </div>

                                        </div>
                                        {/* <div className="col-md-12"  style={{marginTop:"30px"}}>
                                            <div className="row">
                                                <div
                                                    className="
                col-lg-6 col-md-5
                d-none d-md-flex
               ">
                                                    <a className="btn btn-light-primary text-primary btn-lg px-4 fs-4" onClick={userDetailOnSubmit}


                                                    >Submit</a>
                                                </div>

                                            </div>
                                        </div> */}
                                        <div className='col-md-12' style={{ marginTop: "40px" }}>
                                            <div className='row'>
                                                <div className='col-md-3'></div>
                                                <div className='col-md-6'>
                                                    <Button type="primary" onClick={onsubmit} shape="round" className='addbutton' style={{ background: "#32a852", borderColor: "#32a852", width: "130px", marginLeft: "160px" }}>
                                                        <Link to={changepassword ? changepassword : ''}>
                                                            Submit
                                                        </Link>
                                                    </Button>
                                                </div>
                                                <div className='col-md-3'></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>
        </Fragment>
    )
}
export default connect(mapStateToProps, {
    Updatepassword
})(ChangePassword);