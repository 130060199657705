import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import { APPROVE_STATUTORY_MAPPING_FILTER, SUCCESS_MAPPING, APPROVE_STATUTORY_MAPPING_SHOW, COMPLIANCE_INFO_RESET, COMPLIANCE_INFO, COMPLIANCEHISTORY } from '../../types/index'

const initialState = {
    // getapprovestatutorymappingfilterdata: '',
    // statutorymappingfilter: '',
    // showtaskcompliance: ''
    approvestatutorymapping: {
        approvestatutorymappingfilter: [],
        showdata: [],
        compliance: [],
        success: [],
        history: []
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPROVE_STATUTORY_MAPPING_FILTER:
            {
                return {
                    ...state,
                    approvestatutorymapping: {
                        ...state.approvestatutorymapping,
                        approvestatutorymappingfilter: payload,
                    },
                };
            }
        case APPROVE_STATUTORY_MAPPING_SHOW:
            {
                return {
                    ...state,
                    approvestatutorymapping: {
                        ...state.approvestatutorymapping,
                        showdata: payload,
                    },
                };
            }

        case COMPLIANCE_INFO:
            {
                return {
                    ...state,
                    approvestatutorymapping: {
                        ...state.approvestatutorymapping,
                        compliance: payload,
                    },
                };
            }
        case COMPLIANCE_INFO_RESET:
            {
                return {
                    ...state,
                    approvestatutorymapping: {
                        ...state.approvestatutorymapping,
                        compliance: [],
                    },
                };
            }
        case SUCCESS_MAPPING:
            {
                return {
                    ...state,
                    approvestatutorymapping: {
                        ...state.approvestatutorymapping,
                        success: payload,
                    },
                };
            }
        case COMPLIANCEHISTORY: {
            return {
                ...state,
                approvestatutorymapping: {
                    ...state.approvestatutorymapping,
                    history: payload,
                },
            };
        }
        default:
            return state;
    }
}

// export default function(state = initialState, action) {

//     const { type, payload } = action;
//     switch (type) {
//         case MONTHLYCOMPLIANCEFILTER:
//             {
//                 return {
//                     ...state,
//                     unitlistreports: {
//                         ...state.unitlistreports,
//                         unitlist: payload,
//                     },
//                 };

//             }