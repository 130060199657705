import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { gettaskmapping, getviewtaskmapping, unmapaction, reassignaction } from '../../Store/Action/Transactions/Taskcategory';
import { Toaster } from '../../Libs/Toaster'
import { ArrowLeftOutlined } from '@ant-design/icons';


const _ = require("lodash");

const mapStateToProps = (state) => ({
    viewlist: state.Taskcategory.getedittaskcategorymapping.task_edit_compliance_data,
    others: state.Taskcategory.others,
    taskCategory: state.Taskcategory.getedittaskcategorymapping.task_cat,
    taskSubCategory: state.Taskcategory.getedittaskcategorymapping.task_sub_cat,

})
const Taskcategoryedit = ({
    viewlist,
    others,
    unmapaction,
    taskCategory,
    taskSubCategory,
    reassignaction
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const [filteredDomain, setFilteredDomain] = useState([]);
    const [filteredAct, setFilteredAct] = useState([]);
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [finalArray, setFinalArray] = useState([]);
    const [finalArrayNew, setFinalArrayNew] = useState([]);
    const [domainList, setDomainList] = useState([]);
    const validator = useRef(new SimpleReactValidator());
    const [visible, setVisible] = useState(false);
    const [temparr, setTemparr] = useState([])
    console.log(temparr, 'temparr')
    const [selectedvalue, setSelectedvalue] = useState({
        country: '',
        taskcategory: '',
        tasksubcategory: ''
    })
    const { Panel } = Collapse;
    const [count, setCount] = useState(1);
    const [datas, setDatas] = useState([]);
    const [checkedRow, setCheckedRow] = useState([]);
    const [checkAll, setCheckAll] = useState('');
    const [complianceId, setComplianceId] = useState([]);
    const [assignpayload, setAssignPayload] = useState([])
    const { Option } = Select;
    const [taskCatSubCat, setTaskCatSubcat] = useState({
        taskCat: [],
        taskSubCat: []
    });
    const [taskSubCatList, setTaskSubCatList] = useState([]);
    const [AddFormSubmit, setAddFormSubmit] = useState(false);

    console.log(taskSubCatList, 'taskSubCatList')

    useEffect(() => {
        validator.current.showMessages()
        if (viewlist && viewlist.length > 0) {
            let uniqueObjArray = [...new Map(viewlist && viewlist.length > 0 && viewlist.map((item) => [item["domain_name"], item])).values()];
            console.log(uniqueObjArray, 'uniqueObjArray');
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.domain_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(viewlist && viewlist.length > 0 && viewlist, { domain_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            console.log(uniqueIds, 'tempArry')
            setFilteredDomain([...filteredDomain, tempArr])
        }
    }, [viewlist])

    useEffect(() => {
        if (filteredDomain.length > 0) {
            let temp = []
            for (let i in filteredDomain) {
                console.log(filteredDomain[i], 'filteredArray[i]');
                temp = filteredDomain[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredDomain])

    useEffect(() => {
        let tempArr = []
        if (viewlist && viewlist && viewlist.length > 0) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length > 0 && item.map((items) => [items["act_name"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.act_name
                })
                console.log(uniqueIds, 'uniqueIds')
                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { act_name: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })
            setFilteredAct(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let countrylisttempdataNew = [];
        if (viewlist && viewlist.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        console.log(filteredAct, 'filteredAct');
                        viewlist && viewlist.length > 0 && viewlist.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "index": childIndex + 1,
                                    "domain_name": child.domain_name,
                                    "domain_id": child.domain_id,
                                    "is_active": child.is_active,
                                    "act_name": child.act_name,
                                    "compliance_id": child.compliance_id,
                                    "statutory_provision": child.statutory_provision,
                                    "c_task_name": child.c_task_name,
                                    "description": child.description,
                                    "parent_names": child.parent_names,
                                    "country_id": child.country_id,
                                    "act_id": child.act_id,
                                    "parentIndex": i,
                                    "childId": childIndex
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    countrylisttempdataNew.push(temp)
                });
                var merged = [].concat.apply([], countrylisttempdataNew);
                setFinalArray(finalarray)
                setDatas(merged)
            }
        }
    }, [viewlist, filteredAct])

    const downloadselectAllCheckBox = (checked, parent) => {
        let array
        let tempArray = temparr
        var ele = document.getElementsByName('checked' + parent)
        if (checked === true) {
            setCheckedRow(true)

            array = _.filter(datas, { parentIndex: parent });
            console.log(parent, 'arrays')
            for (let i in array) {
                tempArray.push(array[i])
            }

            setTemparr([...tempArray])
            for (var i = 0; i < ele.length; i++) {
                if (ele[i].type === 'checkbox')
                    ele[i].checked = true;
            }
        } else {
            setCheckedRow(false)
            for (var x = tempArray.length - 1; x >= 0; x--) {
                if (tempArray[x].parentIndex === parent) {
                    tempArray.splice(x, 1)
                }
            }
            setTemparr([...tempArray])
            for (var j = 0; j < ele.length; j++) {
                if (ele[j].type === 'checkbox')
                    ele[j].checked = false;
            }
        }
    }

    const downloadcheckBoxOnChange = (checked, child) => {
        let array
        let tempArray = temparr
        if (checkedRow === true) {
            if (checked === true) {
                array = _.find(datas, { childId: child });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].childId === child) {
                        tempArray.splice(i, 1)
                    }
                }
            }

            setTemparr([...tempArray])
        }
        else {
            setCheckAll('')
            if (checked === true) {
                array = _.find(datas, { childId: child });
                tempArray.push(array)
            } else {
                for (let i in tempArray) {
                    if (tempArray[i].childId === child) {
                        tempArray.splice(i, 1)
                    }
                }
            }
            setTemparr([...tempArray])
        }
    }

    useEffect(() => {
        if (temparr && temparr.length > 0) {
            let tempArray = []
            for (let i in temparr) {
                tempArray.push(temparr[i].compliance_id)
            }
            setAssignPayload(tempArray)
        }
    }, [temparr])

    const unmap = () => {
        if (assignpayload.length > 0) {
            let payload2 = {
                "session_token": authtoken,
                "request": [
                    "UnmapTaskCategoryMapping",
                    {
                        "task_group_id": others.taskcategoryId,
                        "task_sub_group_id": others.tasksubcategoryId,
                        "compliance_ids": assignpayload,
                        "is_approved": 1,
                        "is_demap": 1,

                    }
                ]
            }
            let payloadnew = {
                "session_token": authtoken,
                "request": [
                    "GetEditTaskMapping",
                    {
                        "c_id": others.countryId,
                        "task_group_id": others.taskcategoryId,
                        "task_sub_group_id": others.tasksubcategoryId,
                        "r_count": 0,
                        "page_count": 100,
                        "total_count": 0,
                    }
                ]
            }
            unmapaction({
                payload: payload2,
                caller: paramid,
                payloadnew: payloadnew,
                country: others.country,
                taskcategory: others.taskcategory,
                tasksubcategory: others.tasksubcategory,
                taskcategoryId: others.taskcategoryId,
                tasksubcategoryId: others.tasksubcategoryId,
                countryId: others.countryId
            })
            var ele2 = document.getElementsByClassName('checked')
            for (var j = 0; j < ele2.length; j++) {
                if (ele2[j].type === 'checkbox')
                    ele2[j].checked = false;
            }
        } else {
            Toaster.error('No compliance selected')
        }

    }

    const reassign = () => {
        if (assignpayload.length > 0) {
            setVisible(true)
        } else {
            Toaster.error('No compliance selected')
        }
    }

    const modalcancel = () => {
        setVisible(false)
    }

    const gettasksubcat = (data) => {
        setTaskSubCatList([]);
        setTaskCatSubcat({
            ...taskCatSubCat,
            taskCat: parseInt(data)
        })
        let filteredArr = _.filter(taskSubCategory && taskSubCategory.length > 0 && taskSubCategory, { task_group_id: parseInt(data) })
        setTaskSubCatList([...filteredArr])
    }

    const submitrecord = () => {
        setAddFormSubmit(true);
        if (validator.current.allValid()) {
            let payload2 = {
                "session_token": authtoken,
                "request": [
                    "SaveTaskCategoryMapping",
                    {
                        "task_group_id": taskCatSubCat.taskCat,
                        "task_sub_group_id": parseInt(taskCatSubCat.taskSubCat),
                        "compliance_ids": assignpayload,
                        "is_approved": 1,
                    }
                ]
            }
            let payloadnew = {
                "session_token": authtoken,
                "request": [
                    "GetEditTaskMapping",
                    {
                        "c_id": others.countryId,
                        "task_group_id": others.taskcategoryId,
                        "task_sub_group_id": others.tasksubcategoryId,
                        "r_count": 0,
                        "page_count": 100,
                        "total_count": 0,
                    }
                ]
            }
            reassignaction({
                payload: payload2,
                caller: paramid,
                payloadnew: payloadnew,
                country: others.country,
                taskcategory: others.taskcategory,
                tasksubcategory: others.tasksubcategory,
                taskcategoryId: others.taskcategoryId,
                tasksubcategoryId: others.tasksubcategoryId,
                countryId: others.countryId
            })
            var ele2 = document.getElementsByClassName('checked')
            for (var j = 0; j < ele2.length; j++) {
                if (ele2[j].type === 'checkbox')
                    ele2[j].checked = false;
            }
            setVisible(false)
        }


    }

    const previouspage = () => {
        window.location.reload();
    }

    const searchRecords = (e) => {
        const filterTable = viewlist.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (filterTable && filterTable.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        console.log(filteredAct, 'filteredAct');
                        filterTable && filterTable.length > 0 && filterTable.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "domain_name": child.domain_name,
                                    "domain_id": child.domain_id,
                                    "is_active": child.is_active,
                                    "act_name": child.act_name,
                                    "compliance_id": child.compliance_id,
                                    "statutory_provision": child.statutory_provision,
                                    "c_task_name": child.c_task_name,
                                    "description": child.description,
                                    "parent_names": child.parent_names,
                                    "country_id": child.country_id,
                                    "act_id": child.act_id
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                });
                setFinalArray(finalarray)
            }
        } else {
            setFinalArray([])
        }
    }


    return (

        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-1">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large' }}>
                                    <Link><b>Task Category Mapping - Edit</b></Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                        <div>
                            <Tooltip title="" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                icon={<ArrowLeftOutlined />} size='default' onClick={previouspage}>
                                Previous
                            </Button></Tooltip></div>

                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="card">
                            <div className="card-body" style={{ padding: "5px 15px" }}>
                                <Card type="inner">
                                    <div className='row'>
                                        <div className='col-lg-3'><b>Country :</b> {others.country}</div>
                                        <div className='col-lg-4'><b>Task Category :</b> {others.taskcategory}</div>
                                        <div className='col-lg-5'><b>Task Subcategory :</b> {others.tasksubcategory}</div>
                                    </div>
                                </Card>
                                <Card type="inner">
                                    <div className='col-md-3'>
                                        <div className='form-group has-feedback col-sm-12 no-padding' style={{ position: 'relative' }}>
                                            <input className="form-control input-sm" onChange={searchRecords} type="text" id="editsearch" placeholder="Compliance Task" maxlength="50" autocomplete="off" />
                                            <i class="ri-search-line" style={{ position: 'absolute', top: '8px', right: '9px' }}></i>
                                        </div>
                                    </div>
                                </Card>
                                <div className='ant-table-wrapper'>
                                    {filteredArrayState && filteredArrayState.length > 0 ? filteredArrayState.map((item, i) => {
                                        return (
                                            <Collapse defaultActiveKey={['0']}>

                                                <Panel header={item[0].domain_name}>
                                                    <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                        <div className='ant-table-container'>
                                                            <div className='ant-table-content fh1 userprivclass1'>
                                                                <table className='widthHeigh table-fixed'>
                                                                    <colgroup>
                                                                        <col style={{ width: '10px' }}></col>
                                                                    </colgroup>
                                                                    <thead className='ant-table-thead'>
                                                                        <tr>
                                                                            <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                            <th className='ant-table-cell' style={{ width: '100px' }}>Statutory Provision</th>
                                                                            <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Task</th>
                                                                            <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Description</th>
                                                                        </tr>
                                                                    </thead>
                                                                    <tbody className='ant-table-tbody'>
                                                                        <>
                                                                            {
                                                                                finalArray && finalArray.length > 0 ? finalArray.map((itemdata, i) => {
                                                                                    let data = _.find(itemdata.child, { domain_name: item[0].domain_name })
                                                                                    if (data != undefined) {
                                                                                        if (data.act_name == itemdata.parent) {
                                                                                            return <>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                    <td colSpan={4} className='ant-table-cell'><input type="checkbox" onChange={(e) => {
                                                                                                        let checked = e.target.checked;
                                                                                                        downloadselectAllCheckBox(checked, itemdata.index)
                                                                                                    }} /> <b>{itemdata.parent}</b></td>
                                                                                                </tr>
                                                                                                {itemdata.child.map((items, j) => {
                                                                                                    return <>
                                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                            <td className='ant-table-cell'><input type="checkbox" className='checked' name={'checked' + items.parentIndex} onChange={(e) => {
                                                                                                                let checked = e.target.checked;
                                                                                                                downloadcheckBoxOnChange(checked, items.childId)
                                                                                                            }} /><center>{items.index}</center></td>
                                                                                                            <td title={items.statutory_provision} className='ant-table-cell ellipsis ant-table-cell-with-append'>
                                                                                                                {items.statutory_provision}</td>
                                                                                                            <td title={items.c_task_name} className='ant-table-cell ellipsis'>{items.c_task_name}</td>
                                                                                                            <td title={items.description} className='ant-table-cell ellipsis'>{items.description}</td>

                                                                                                        </tr>
                                                                                                    </>

                                                                                                }
                                                                                                )}


                                                                                            </>
                                                                                        }
                                                                                    }

                                                                                }) : <tr>
                                                                   
                                                                                <td  colSpan={4} style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                            </tr>
                                                                            }
                                                                        </>
                                                                    </tbody>
                                                                </table>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </Panel>
                                            </Collapse>
                                        )
                                    }) : ''}
                                </div>
                                <br />
                                <div className='row justify-content-center'>
                                    <div style={{ 'margin-bottom': '-17px' }}>
                                        {datas && datas.length > 0 ?
                                            'Showing 1 to  ' + datas.length + '  of ' + datas.length + '  entries ' : ''}
                                    </div>
                                    <div className='col-lg-2'>
                                        <Button type="primary" shape="round" className='addbutton'
                                            style={{ background: "#3CB371", borderColor: "#3CB371", width: "100%", height: "40px" }} onClick={reassign}
                                        >Reassign Task
                                        </Button>
                                    </div>
                                    <div className='col-lg-2'>
                                        <Button type="primary" shape="round" className='addbutton'
                                            style={{ background: "white", color: 'blue', borderColor: "#3CB371", width: "100%", height: "40px" }} onClick={unmap}
                                        >Unmap Task
                                        </Button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title={false} visible={visible} footer={null} maskClosable={false} onCancel={modalcancel} className="remarksClass">
                    <div className='row'>
                        <div className='col-lg-6'>
                            <label htmlFor=""><b>Task Category</b></label>  <span style={{ "color": "red" }}>*</span>
                            <Select
                                allowClear={true}
                                size="default"
                                placeholder="Select Business Group"
                                onChange={(data, value) => {
                                    gettasksubcat(data)
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%' }}
                            >
                                {taskCategory && taskCategory.length > 0 && taskCategory.map((item, i) => {

                                    return (
                                        <Option key={item.task_group_id}>
                                            {item.task_group_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {validator.current.message(
                                'taskcategory',
                                taskCatSubCat.taskCat,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Task Category is required',
                                    }
                                })}
                        </div>
                        <div className='col-lg-6'>
                            <label htmlFor=""><b>Task Subcategory</b></label>  <span style={{ "color": "red" }}>*</span>
                            <Select
                                allowClear={true}
                                size="default"
                                placeholder="Select Business Group"
                                onChange={(data, value) => {
                                    setTaskCatSubcat({
                                        ...taskCatSubCat,
                                        taskSubCat: data
                                    })
                                }}
                                showSearch
                                optionFilterProp="children"
                                filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                style={{ width: '100%' }}
                            >
                                {taskSubCatList && taskSubCatList.length > 0 && taskSubCatList.map((item, i) => {

                                    return (
                                        <Option key={item.task_sub_group_id}>
                                            {item.task_sub_group_name}
                                        </Option>
                                    );
                                })}
                            </Select>
                            {validator.current.message(
                                'taskcategory',
                                taskCatSubCat.taskSubCat,
                                'required',
                                {
                                    className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                    messages: {
                                        required: 'Task Subcategory is required',
                                    }
                                })}
                        </div>
                        <div className='col-md-2' style={{ marginLeft: '80%', marginTop: '3%' }} >
                            <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                size='default' onClick={submitrecord}> Submit
                            </Button>
                        </div>
                    </div>
                </Modal >
            </div>
        </div>
    )
}
export default connect(mapStateToProps, {
    unmapaction,
    reassignaction
})(Taskcategoryedit);