import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Button, Table } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import ApproveStatutoryBulkuploadview from './Approvestat_bulk_view'
import { update } from 'lodash';
import { DownloadOutlined } from "@ant-design/icons";
import URL from '../../Libs/URL'
import { clientunitlist, clientviewlist } from '../../Store/Action/Transactions/Assignclientunit';
import AssignClientUnitView from './Assignclientunitview'
const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.Assignclientunit.assignclient.list
})
const AssignClientUnit = ({
    clientunitlist,
    clientviewlist,
    list
}) => {
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const [key, setKey] = useState("1");
    const { Panel } = Collapse;
    const { Option } = Select;
    const [currentpage, setcurrentpage] = useState('list');

    const columns = [
        {
            title: "#",
            width: '50px',
            render: (text, record, index) => {
                return (
                    <span>
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: "Domain",
            dataIndex: "domain_name",
            key: "domain_name",
            ellipsis: true,
            width: '200px',
        },
        {
            title: "Group",
            dataIndex: "group_name",
            key: "group_name",
            ellipsis: true,
            width: '200px',
        },
        {
            title: "Legal Entity",
            dataIndex: "legal_entity_name",
            key: "legal_entity_name",
            ellipsis: true,
            width: '200px',
        },
        {
            title: "Business Group",
            dataIndex: "business_group_name",
            key: "business_group_name",
            ellipsis: true,
            width: '200px',
        },
        {
            title: "Unassigned Unit(s)/Vendor(s)",
            dataIndex: "unassigned_units",
            key: "unassigned_units",
            ellipsis: true,
            width: '250px',
            align: 'center'
        },
        {
            title: "Assign",
            dataIndex: "",
            key: "",
            ellipsis: true,
            width: '150px',
        },
        {
            title: "Domain Executive",
            align: 'center',
            width: '100px',
            render: (text, record) => {
                return <Button
                    type="primary"
                    shape="round"
                    className='addbutton' onClick={() => { showview(record) }}>
                    View
                </Button>
            },
        },
    ]

    useEffect(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetUnassignedUnits",
                    {}
                ]
            }
            clientunitlist({
                payload: pay,
                caller: paramid
            })
        }
    }, [isAuth])

    const showview = (data) => {
        const pay = {
            "session_token":authtoken,
            "request": [
                "GetAssignedUnits",
                {
                    "domain_id": data.domain_id,
                    "client_id": data.client_id,
                    "legal_entity_id": data.legal_entity_id
                }
            ]
        }
        clientviewlist({
            payload: pay,
            caller: paramid
        })

        setcurrentpage('view');
    }

    return (
        <>
            {currentpage == 'list' ? (
                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Transactions</Link>
                                        </li>
                                        <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                            <Link><b>Assign Client Unit/Vendor</b></Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row" >
                            <div className="col-12">
                                <div className="card">
                                    <div className='card-body' style={{ padding: '0px' }}>
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={list && list.unassigned_units_list}
                                            bordered
                                            scroll={{ x: 900 }}
                                            pagination={false}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>) : <AssignClientUnitView />}
        </>
    )
}
export default connect(mapStateToProps, {
    clientunitlist,
    clientviewlist
})(AssignClientUnit);