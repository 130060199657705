import { BULKUPLOAD, USER, BULKREPORTDATA, EXPORTBULKREPORTDATA, EXPORTBULKREPORTDATA_RESET } from './../../types/index'
const initialState = {
    statutorybulkupload: {
        bulkupload: [],
        user: [],
        reportdata: [],
        exportdatas: []
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case BULKUPLOAD:
            return {
                ...state,
                statutorybulkupload: {
                    ...state.statutorybulkupload,
                    bulkupload: payload,
                },
            };
        case USER:
            return {
                ...state,
                statutorybulkupload: {
                    ...state.statutorybulkupload,
                    user: payload,
                },
            };
        case BULKREPORTDATA:
            return {
                ...state,
                statutorybulkupload: {
                    ...state.statutorybulkupload,
                    reportdata: payload,
                },
            };
        case EXPORTBULKREPORTDATA:
            return {
                ...state,
                statutorybulkupload: {
                    ...state.statutorybulkupload,
                    exportdatas: payload,
                },
            };
        case EXPORTBULKREPORTDATA_RESET:
            return {
                ...state,
                statutorybulkupload: {
                    ...state.statutorybulkupload,
                    exportdatas: [],
                },
            };
        default:
            return state;
    }
}