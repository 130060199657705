import HTTP from '../../../Libs/http'
import { Toaster } from '../../../Libs/Toaster'
import url from './../../../Libs/URL'
import EncryptDecrypt from './../../../Libs/Encrypt&Decrypt'
import { decode as base64_decode } from 'base-64';
import { GETAPPROVETASKCATEGORYMAPPINGFILTER, APPROVE_TASK_CATEGORY_COMPLIANCE, APPROVE_TASK_CATEGORY_MAPPING_SHOW, TOGGLE_COMMON_LOADER } from './../../types/index';
import alertmessage from '../../../Libs/Altermessage';

export const getapprovetaskcategorymappingfilter = ({ payload, executeCancel, caller, payloadnew }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/knowledge_transaction`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: GETAPPROVETASKCATEGORYMAPPINGFILTER,
                    payload: decryptData[1],
                });

            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const ApproveTaskCategoryMapping = ({ payload, executeCancel, paramid, payloadnew }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/knowledge_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'GetApproveTaskCategoryMappingSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: APPROVE_TASK_CATEGORY_MAPPING_SHOW,
                    payload: decryptData,
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const ApproveTaskCategoryCompliance = ({ payload, executeCancel, paramid, payloadnew }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/knowledge_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        console.log(head, 'createRequest')
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'GetApproveTaskCategoryComplianceSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                dispatch({
                    type: APPROVE_TASK_CATEGORY_COMPLIANCE,
                    payload: decryptData,
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const saveapprovetask = ({ payload, executeCancel, paramid, payloadnew }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const encryptkey = localStorage.getItem('formkey')
        const head = {
            headers: {
                'Caller-Name': paramid,
                'Content-Type': 'application/json'
            },
        }
        let encoded = base64_decode(encryptkey);
        const data1 = EncryptDecrypt.encryptdata(payload, encoded);
        const createRequest = HTTP.post({
            url: `${url.CommonURL}/api/knowledge_transaction`,
            headers: head,
            executeCancel,
            body: data1
        })
        console.log(head, 'createRequest')
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
                executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            //Toaster.error("Service Unavailable")
            alertmessage.sweetalert();
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            if (decryptData[0] === 'SaveAppTaskCategoryMappingSuccess') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.success('Task Category Mapping Approved / Rejected Successfully');
                setTimeout(() => {
                    window.location.reload(false);
                }, 4000);
            } else if (decryptData[0] === 'InvalidSessionToken') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                alertmessage.sweetalert();

            } else {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error(decryptData[0])
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};