export const LOGINSUCCESS = 'LOGINSUCCESS'
export const TOGGLE_COMMON_LOADER = 'TOGGLE_COMMON_LOADER'
export const TASK_DUE_DATE = 'TASK_DUE_DATE'
export const NOTIFICATIONS = 'NOTIFICATIONS'
export const ESCALATION = 'ESCALATION'
export const REMAINDER = 'REMAINDER'
export const MESSAGE = 'MESSAGE'
export const NOTIFICATIONSHOWALL = 'NOTIFICATIONSHOWALL'
export const GETUSERS = 'GETUSERS'
export const GETREJECTEDLIST = 'GETREJECTEDLIST'
export const GETBUCONSTANT = 'GETBUCONSTANT'
export const ADDTASKDUEDATE = 'ADDTASKDUEDATE'
export const TASKDUEDATE = 'TASKDUEDATE'
export const GETTASKMAPPING = 'GETTASKMAPPING'
export const GETVIEWTASKMAPPING = 'GETVIEWTASKMAPPING'
export const TASK_HISTORY = 'TASK_HISTORY'
export const STATUTORYNOTIFICATIONSFILTERS = 'STATUTORYNOTIFICATIONSFILTERS'
export const STATUTORYNOTIFICATIONSREPORTDATA = 'STATUTORYNOTIFICATIONSREPORTDATA'
export const GETEDITTASKMAPPING = 'GETEDITTASKMAPPING'
export const MAPPING_LIST = 'MAPPING_LIST'
export const MAPPING_INFO = 'MAPPING_INFO'
export const MAPPINTMASTER = 'MAPPINTMASTER'
export const BULKUPLOAD = 'BULKUPLOAD'
export const USER = 'USER'
export const BULKREPORTDATA = 'BULKREPORTDATA'
export const STATUTORYMAPPINGREPORT = 'STATUTORYMAPPINGREPORT'
export const PROFILE = 'PROFILE'
export const STATUTORYMAPPINGREPORTSHOW = 'STATUTORYMAPPINGREPORTSHOW'
export const EXPORTBULKREPORTDATA = 'EXPORTBULKREPORTDATA'
export const EXPORTBULKREPORTDATA_RESET = 'EXPORTBULKREPORTDATA_RESET'
export const MAPPINGBULKLIST = 'MAPPINGBULKLIST'
export const GETTASKCATEGORYMAPPINGFILTER = 'GETTASKCATEGORYMAPPINGFILTER'
export const BULKUPLOADDOMAIN = 'BULKUPLOADDOMAIN'
export const GETTASKCATEGORYMAPPING = 'GETTASKCATEGORYMAPPING'
export const REJECTEDDOWNLOADCOUNT = 'REJECTEDDOWNLOADCOUNT'
export const REJECTEDFILENAME = 'REJECTEDFILENAME'
export const DOWNLOADLINK = 'DOWNLOADLINK'
export const UPLOADFILENAME = 'UPLOADFILENAME'
export const ERRORDATA = 'ERRORDATA'
export const FILEUPLOADSUCCESS = 'FILEUPLOADSUCCESS'
export const FETCHINGEDITDETAILS = 'FETCHINGEDITDETAILS'
export const GETAPPROVETASKCATEGORYMAPPINGFILTER = 'GETAPPROVETASKCATEGORYMAPPINGFILTER'
export const APPROVE_TASK_CATEGORY_MAPPING_SHOW = "APPROVE_TASK_CATEGORY_MAPPING_SHOW"
export const APPROVE_TASK_CATEGORY_COMPLIANCE = 'APPROVE_TASK_CATEGORY_COMPLIANCE'
export const APPROVE_STATUTORY_MAPPING_FILTER = 'APPROVE_STATUTORY_MAPPING_FILTER'
export const APPROVE_STATUTORY_MAPPING_SHOW = 'APPROVE_STATUTORY_MAPPING_SHOW'
export const APPROVE_BULK_UPLOAD = 'APPROVE_BULK_UPLOAD'
export const APPROVE_BULK_UPLOAD_EXE = 'APPROVE_BULK_UPLOAD_EXE'
export const BULK_UPLOAD_LIST = 'BULK_UPLOAD_LIST'
export const BULK_UPLOAD_RESPONSE = 'BULK_UPLOAD_RESPONSE'
export const COMPLIANCE_INFO = 'COMPLIANCE_INFO'
export const COMPLIANCE_INFO_RESET = 'COMPLIANCE_INFO_RESET'
export const BULK_REJECT_RESPONSE = 'BULK_REJECT_RESPONSE'
export const BULK_UPLOAD_VIEW = 'BULK_UPLOAD_VIEW'
export const BULK_UPLOAD_FILTER = 'BULK_UPLOAD_FILTER'
export const BULK_UPLOAD_APPROVE = 'BULK_UPLOAD_APPROVE'
export const BULK_UPLOAD_Reject = 'BULK_UPLOAD_REJECT'
export const SUCCESS_MAPPING = 'SUCCESS_MAPPING'
export const ASSIGN_CLIENT_LIST = 'ASSIGN_CLIENT_LIST'
export const ASSIGN_CLIEBT_VIEW = 'ASSIGN_CLIEBT_VIEW'
export const ASSIGN_CLIENT_UNIT_DETAILS = 'ASSIGN_CLIENT_UNIT_DETAILS'
export const AMENDMENT_DATA = 'AMENDMENT_DATA'
export const FILEUPLOADSUCCESSNOTIFICATION = 'FILEUPLOADSUCCESSNOTIFICATION'
export const COMPLIANCEHISTORY = 'COMPLIANCEHISTORY'
export const ASSIGN_STATU_UPLOAD_LIST = 'ASSIGN_STATU_UPLOAD_LIST'
export const USER_MAPPING_FILTER = 'USER_MAPPING_FILTER'
export const AMENDMENT_UPLOAD_SUCCESS = 'AMENDMENT_UPLOAD_SUCCESS'
export const LEGAL_ENTITY_FILTER = 'LEGAL_ENTITY_FILTER'
export const AMENDMENT_UPLOAD_ERROR = 'AMENDMENT_UPLOAD_ERROR'
export const USER_MAPPING_REPORT_SHOW = 'USER_MAPPING_REPORT_SHOW'
export const EXPORT_USER_MAPPING_REPORT = 'EXPORT_USER_MAPPING_REPORT'
export const EXPORT_USER_MAPPING_REPORT_RESET = 'EXPORT_USER_MAPPING_REPORT_RESET'
export const MAPPING_UPLOAD_LIST = 'MAPPING_UPLOAD_LIST'
export const GETTASKCATEGORYMAPPINGSHOWMORE = 'GETTASKCATEGORYMAPPINGSHOWMORE'
export const STATUTORY_MAPPING_HISTORY = 'STATUTORY_MAPPING_HISTORY'