import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { InfoCircleOutlined } from "@ant-design/icons";
import { assignlist } from '../../Store/Action/Transactions/AssignStatutoryBulkupload'

import URL from '../../Libs/URL'
const _ = require("lodash");

const mapStateToProps = (state) => ({
    list: state.Assignstutorybulk.assignstatutorybulkupload.list
})
const ApproveAssignBulkupload = ({
    assignlist,
    list

}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const { Panel } = Collapse;
    const { Option } = Select;
    const formValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false);

    const [searchdata, setsearchdata] = useState({
        group: '',
        legalid: '',
        legaldata: '',
        product: '',
        productdata: ''
    });

    useEffect(() => {
        formValidator.current.showMessages();
    }, []);

    const searchlist = () => {
        setAddFormSubmit(true);
        if (formValidator.current.allValid()) {
            console.log(searchdata, 'data');
        }
    }

    useState(() => {
        if (isAuth) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetClientInfo",
                    {}
                ]
            }
            assignlist({
                payload: pay,
                caller: paramid
            })
        }
    }, [isAuth])

    useEffect(() => {
        if (searchdata.group) {
            const legal = _.filter(list && list.bu_legalentites, { cl_id: searchdata.group })
            setsearchdata({
                ...searchdata,
                legaldata: legal
            })
        }
    }, [searchdata.group])

    return (
        <>
            <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link>Transactions</Link>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <Link><b>Approve / Reject Assigned Statutory - Bulk Upload</b></Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                <Collapse defaultActiveKey={["1"]} >
                                    <Panel header="Approve / Reject Assigned Statutory" key={1}>
                                        <div className='col-md-12'>
                                            <div className='row'>
                                                <div className='col-md-4'>
                                                    <label><b>Client Group:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            name='clientgroup'
                                                            placeholder="Enter ClientGroup"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            onChange={(data, value) => {
                                                                setsearchdata({
                                                                    ...searchdata,
                                                                    group: value.value
                                                                })
                                                            }}>
                                                            {list && list.bu_clients && list.bu_clients.map((item, i) => {
                                                                return <Option key={item.cl_id}>{item.cl_name}</Option>
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'clientgroup',
                                                            searchdata.group,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Client Group Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Legal Entity:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            name='legalentity'
                                                            placeholder="Enter LegalEntity"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            onChange={(data, value) => {
                                                                setsearchdata({
                                                                    ...searchdata,
                                                                    legalid: value.value
                                                                })
                                                            }}>
                                                            {searchdata && searchdata.legaldata && searchdata.legaldata.map((item, i) => {
                                                                return <Option key={item.le_id}>{item.le_name}</Option>
                                                            })}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'legalentity',
                                                            searchdata.group,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Legal Entity Name Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                                <div className='col-md-4'>
                                                    <label><b>Product:</b> <span style={{ color: "red" }}>*</span></label>
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            name='Product'
                                                            placeholder="Enter Product"
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            onChange={(data, value) => {
                                                                setsearchdata({
                                                                    ...searchdata,
                                                                    product: value.value
                                                                })
                                                            }}>
                                                            {/* {searchdata && searchdata.countrydata && searchdata.countrydata.map((item, i) => {
                                                                return <Option key={item.country_id}>{item.country_name}</Option>
                                                            })} */}
                                                        </Select>
                                                        {formValidator.current.message(
                                                            'Product',
                                                            searchdata.product,
                                                            ['required'],
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Product Name Required'
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-md-12' style={{ textAlign: "center", marginLeft: "26%" }}>
                                            <div className='row'>
                                                <div className="col-md-6">
                                                    <Button
                                                        type="primary"
                                                        shape="round"
                                                        className='addbutton'
                                                        style={{
                                                            marginTop: "20px"
                                                        }}
                                                        onClick={searchlist}>
                                                        Show
                                                    </Button>
                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default connect(mapStateToProps, {
    assignlist
})(ApproveAssignBulkupload);