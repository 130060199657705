import { Fragment, useState, useEffect, useRef } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import { InfoCircleOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { unitviewlist } from '../../Store/Action/Transactions/Assignclientunit'
const _ = require("lodash");

const mapStateToProps = (state) => ({
    viewlist: state.Assignclientunit.assignclient.viewlist,
    list: state.Assignclientunit.assignclient.list,
    unitlist: state.Assignclientunit.assignclient.unitlist
})
const AssignClientUnitView = ({
    viewlist,
    list,
    unitviewlist,
    unitlist
}) => {
    const isAuth = localStorage.getItem("isAuthenticated");
    const authtoken = localStorage.getItem("authToken");
    const location = useLocation();
    const paramid = location.pathname;
    const [key, setKey] = useState("1");
    const { Panel } = Collapse;
    const { Option } = Select;
    const [modalVisible, setmodalVisible] = useState(false);
    const [domainexeid, setdomainexeid] = useState({
        exename: '',
        domainname: ''
    });

    const columns = [
        {
            title: "#",
            width: '50px',
            render: (text, record, index) => {
                return (
                    <span>
                        {index + 1}
                    </span>
                );
            },
        },
        {
            title: "Domain Executive",
            dataIndex: "employee_name",
            key: "employee_name",
            ellipsis: true,
            width: '200px',
        },
        {
            title: "Domain",
            dataIndex: "domain_name",
            key: "domain_name",
            ellipsis: true,
            width: '200px',
            render: (text, record) => {
                let domainexe = _.filter(list.unassigned_units_list, { domain_id: record.domain_id });
                return <span>
                    {domainexe && domainexe.length > 0 ? domainexe[0].domain_name : ''}
                </span>

            },
        },
        {
            title: "Group",
            dataIndex: "domain_name",
            key: "domain_name",
            ellipsis: true,
            width: '200px',
            render: (text, record) => {
                let domain = _.filter(list.unassigned_units_list, { client_id: record.client_id });
                return <span>
                    {domain && domain.length > 0 ? domain[0].group_name : ''}
                </span>

            },
        },
        {
            title: "Business Group",
            dataIndex: "business_group_name",
            key: "business_group_name",
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: "Legal Entity",
            dataIndex: "legal_entity_name",
            key: "legal_entity_name",
            ellipsis: true,
            width: '200px',
        },
        {
            title: "No. of Unit(s)",
            dataIndex: "unit_count",
            key: "unit_count",
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: "No. of Vendor(s)",
            dataIndex: "vndr_unit_count",
            key: "vndr_unit_count",
            ellipsis: true,
            width: '200px',
            align: 'center'
        },
        {
            title: "View",
            align: 'center',
            width: '100px',
            align: 'center',
            render: (text, record) => {
                return <Button
                    type="primary"
                    shape="round"
                    className='addbutton' onClick={() => { showviewlist(record) }}>
                    View
                </Button>
            },
        },
    ]

    const showviewlist = (record) => {
        const pay = {
            "session_token": authtoken,
            "request": [
                "GetAssignedUnitDetails",
                {
                    "legal_entity_id": record.legal_entity_id,
                    "user_id": record.user_id,
                    "client_id": record.client_id,
                    "domain_id": record.domain_id
                }
            ]
        }
        unitviewlist({
            payload: pay,
            caller: paramid
        })
        setmodalVisible(true)
    }

    const modalcancel = () => {
        setmodalVisible(false);
    }



    useEffect(() => {
        if (list && viewlist) {
            let domainexe = _.filter(list.unassigned_units_list, { domain_id: viewlist.assigned_units_list && viewlist.assigned_units_list[0].domain_id });
            let domain = _.filter(list.unassigned_units_list, { client_id: viewlist.assigned_units_list && viewlist.assigned_units_list[0].client_id });
            setdomainexeid({
                ...domainexeid,
                exename: domainexe,
                domainname: domain
            })
        }
    }, [list, viewlist])

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link><b>Assign Client Unit/Vendor</b></Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center 
                        justify-content-end">
                        <Button
                            type="primary"
                            shape="round"
                            className='addbutton'
                            style={{ background: "#0096FF", borderColor: "#0096FF", marginRight: "10px" }}
                            icon={<ArrowLeftOutlined />}
                            size='default'
                            onClick={()=>{window.location.reload()}}>
                            Previous
                        </Button>

                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row" >
                    <div className="col-12">
                        <div className="card">
                            <div className='card-body' style={{ padding: '0px' }}>
                                <Table
                                    className='userprivclass'
                                    columns={columns}
                                    dataSource={viewlist && viewlist.assigned_units_list}
                                    bordered
                                    scroll={{ x: 700 }}
                                    pagination={false}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Modal title="Assigned Client Unit/Vendor" visible={modalVisible} footer={null} maskClosable={false} className="Assignunit"
                onCancel={modalcancel}>
                <div className="row">
                    <div className="col-lg-12">
                        <div className='col-md-12 mt-2'>
                            <div className='row'>
                                <div className='col-md-6 mt-2'>
                                    <label><b>Group:&nbsp;&nbsp;</b></label>
                                    {domainexeid && domainexeid.domainname != '' && domainexeid.domainname && domainexeid.domainname[0].group_name}


                                </div>
                                <div className='col-md-6'>
                                    <label><b>Domain:&nbsp;</b></label>
                                    {domainexeid && domainexeid.exename != '' && domainexeid.exename && domainexeid.exename[0].domain_name}
                                </div>
                            </div>
                        </div>
                        <div className='col-md-12 mt-2'>
                            <div className='row'>
                                <div className='col-md-6'>
                                    <label><b>Business Group:&nbsp;&nbsp;&nbsp;</b></label>
                                    {viewlist && viewlist.assigned_units_list && viewlist.assigned_units_list[0].business_group_name}
                                </div>
                                <div className='col-md-6'>
                                    <label><b>Legal Entity:&nbsp;&nbsp;&nbsp;</b></label>
                                    {viewlist && viewlist.assigned_units_list && viewlist.assigned_units_list[0].legal_entity_name}
                                </div>
                            </div>
                        </div>
                        <br />
                        <div className="row" >
                            <div className="col-12">
                                <table style={{ tableLayout: 'auto' }}>
                                    <thead className='ant-table-thead'>
                                        <tr>
                                            <th className='ant-table-cell'>Unit/Vendor Code</th>
                                            <th className='ant-table-cell'><center>Unit/Vendor Name</center></th>
                                            <th className='ant-table-cell'><center>Product</center></th>
                                            <th className='ant-table-cell'><center>Organization Type</center></th>
                                            <th className='ant-table-cell'><center>Unit/Vendor Location</center></th>
                                        </tr>
                                    </thead>
                                    <tbody className='ant-table-tbody'>
                                        {unitlist && unitlist.assigned_unit_details_list ?
                                            <>
                                                <tr className='ant-table-row ant-table-row-level-0'>
                                                    <td className='ant-table-cell' colSpan={2}><b>Legal Entity :-{unitlist.assigned_unit_details_list[0].legal_entity_name}</b>
                                                    </td>
                                                    <td colSpan={2}><b>Division :-</b>{unitlist.assigned_unit_details_list[0].division_name == null ? '-' : unitlist.assigned_unit_details_list[0].division_name}</td>
                                                    <td>  <b>Category :- {unitlist.assigned_unit_details_list[0].category_name == null ? '-' : unitlist.assigned_unit_details_list[0].category_name}</b></td>
                                                </tr></> : ""}
                                        {unitlist && unitlist.assigned_unit_details_list && unitlist.assigned_unit_details_list.map((item) => {
                                            return <tr className='ant-table-row ant-table-row-level-0'>
                                                <>
                                                    <td className='ant-table-cell custom-width'>{item.unit_code}</td>
                                                    <td className='ant-table-cell fs'><Tooltip
                                                        className="toolTipClass"
                                                        placement="topRight"
                                                        title={item.address}>
                                                        <InfoCircleOutlined />
                                                        {item.unit_name}</Tooltip></td>
                                                    <td className='ant-table-cell fs'>{item.product}</td>
                                                    <td className='ant-table-cell fs'><b>{item.domain_names[0]}</b><br />{item.org_names_list[0]}</td>
                                                    <td className='ant-table-cell fs'>{item.geography_name}</td>
                                                </>

                                            </tr>
                                        })
                                        }
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </Modal >
        </div >
    )
}
export default connect(mapStateToProps, {
    unitviewlist
})(AssignClientUnitView);