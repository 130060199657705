import React, { Fragment } from "react";
import { connect, useDispatch } from "react-redux";
import moment from "moment";
import { useState, useRef, useEffect } from "react";
import SimpleReactValidator from "simple-react-validator";
import { Link, useLocation } from "react-router-dom";
import Addstatutorymapping from "./Addstatutorymapping";
import Editstatutorymapping from "./Editstatutorymapping";
import { Collapse, Select, Button, Table, Tooltip, Pagination, Modal, Input } from "antd";
import {
  getmaplist,
  getinfodetails,
  getmasterlist,
  deletestatutorymapping,
  getcomplianceedit, gethistorydata, deactivestatuschange
} from "./../../Store/Action/Transactions/Statutorymapping";
import {
  LikeOutlined,
  DislikeOutlined,
  InfoCircleOutlined,
  PlusOutlined,
  DeleteOutlined, FormOutlined, BookOutlined, PlayCircleOutlined,
  PlusCircleOutlined,
  CheckCircleFilled
} from "@ant-design/icons";
import Swal from "sweetalert2";
import { set } from "lodash";
const _ = require("lodash");

const mapStateToProps = (state) => ({
  list: state.Statutorymapping.statutorymap.list,
  info: state.Statutorymapping.statutorymap.info,
  history: state.Statutorymapping.statutorymap.historydata,
});

const StatutoryMapping = ({
  getmaplist,
  getinfodetails,
  list,
  info,
  getmasterlist,
  deletestatutorymapping,
  getcomplianceedit,
  gethistorydata,
  history,
  deactivestatuschange
}) => {
  const isAuth = localStorage.getItem("isAuthenticated");
  const authtoken = localStorage.getItem("authToken");
  const location = useLocation();
  const paramid = location.pathname;
  const [key, setKey] = useState("1");
  const { Panel } = Collapse;
  const { Option } = Select;
  const [dataTableProperties, setDataTableProperties] = useState({
    fixedHeader: true,
    pagesize: 10,
    sizechanger: true,
    pageSizeOptions: ["10", "20", "30"],
  });
  const [pageSize, setPageSize] = useState(25);
  const [current, setCurrent] = useState(1);
  const [pageState, setpageState] = useState(false);
  const [listpage, setlistpage] = useState('list');
  const [iconEnable, setIconEnable] = useState(false);
  const [clearText, setClearText] = useState("");
  const formValidator = useRef(new SimpleReactValidator());
  const formValidator1 = useRef(new SimpleReactValidator());
  const [AddFormSubmit, setAddFormSubmit] = useState(false);
  const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
  const [modalVisible, setmodalVisible] = useState(false);
  const [searchdata, setsearchdata] = useState({
    country: "",
    domain: "",
    domaindata: "",
    organization: "",
    organizationdata: "",
    nature: "",
    naturedata: "",
    statutory: "",
    approvalstatus: 6,
    status: 3,
    taskcode: "",
  });
  const [deactivemodal, setdeactivemodal] = useState(false);
  const [effectivedata, seteffectivedata] = useState({
    comp_id: '',
    selecteddate: ''
  });

  const columns = [
    {
      title: "Task Code",
      dataIndex: "task_code",
      width: '140px',
      key: "task_code",
      render: (text, record) => {
        return (
          <div>
            {record.approval_status_text == "Rejected" ? (
              <span style={{ color: "red" }}>{text}</span>
            ) : (
              <span>{text}</span>
            )}
          </div>
        );
      },
    },
    {
      title: "Organization",
      dataIndex: "industry_names",
      key: "industry_names",
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} className="ellipsis" style={{ color: "red" }}>{record.industry_names}</span>
        } else {
          return record.industry_names
        }
      },
    },
    {
      title: "Statutory Nature",
      dataIndex: "statutory_nature_name",
      key: "statutory_nature_name",
      ellipsis: true,
      width: '100px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} className="ellipsis" style={{ color: "red" }}>{record.statutory_nature_name}</span>
        } else {
          return record.statutory_nature_name
        }
      },
    },
    {
      title: "Statutory",
      dataIndex: "s_maps",
      key: "s_maps",
      ellipsis: true,
      width: '200px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} className="ellipsis" style={{ color: "red" }}>{record.s_maps}</span>
        } else {
          return record.s_maps
        }
      },
    },
    {
      title: "Task Name",
      dataIndex: "compliance_name",
      key: "compliance_name",
      ellipsis: true,
      width: '150px',
      render: (text, record) => {
        if (record.approval_status_text == "Rejected") {
          return <span title={text} className="ellipsis" style={{ color: "red" }}>{record.compliance_name}</span>
        } else {
          return record.compliance_name
        }

      },
    },
    {
      title: "Approval Status",
      dataIndex: "approval_status_text",
      width: '100px',
      filters: [
        {
          text: "All",
          value: 6,
        },
        {
          text: "Rejected",
          value: 4,
        },
        {
          text: "Yet to Submit",
          value: 0,
        },
        {
          text: "Pending",
          value: 1,
        },
        {
          text: "Approved",
          value: 2,
        },
        {
          text: "Approved & Notified",
          value: 3,
        },
      ],
      onFilter: (value, record) => filterfunction(value, record),
      filterSearch: true,
      render: (text, record) => {
        return (<>
          {record.is_approved == 4 ?
            <div>
              <span>
                <Tooltip
                  style={{ marginBotton: "10px", marginleft: "5px" }}
                  placement="topRight"
                  title={record.remarks}
                >
                  <InfoCircleOutlined />
                </Tooltip>
                &nbsp;
                {text == "Rejected" ? (
                  <span style={{ color: "red" }}>{text}</span>
                ) : (
                  <span>{text}</span>
                )}
              </span>
            </div> :
            <div>
              <span>
                <span>{text}</span>
              </span>
            </div>} </>
        )
      },
    },
    {
      title: (
        <>
          <label>Actions</label>
          <Tooltip
            className="toolTipClass"
            style={{ marginBotton: "5px", marginleft: "5px" }}
            placement="topRight"
            title={"This Action Includes Edit and Status View"}
          >
            <InfoCircleOutlined />
          </Tooltip>
        </>
      ),
      dataIndex: "is_active",
      width: '150px',
      render: (text, record) => {
        return (
          <Fragment>
            <div className="row">
              <div className="col-md-2">
                {record.is_approved == 2 || record.is_approved == 3 ?
                  text === true ? (
                    <div className="col-md-2">
                      <span
                        className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center" onClick={() => { deactivetask(record) }}>
                        <Tooltip title="click here to deactive" color="#fc4b6c">
                          <LikeOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  ) : (
                    <div className="col-md-2">
                      <span
                        className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center">
                        <Tooltip title="InActive" color="#39cb7f">
                          <DislikeOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  ) :
                  text === true ? (
                    <div className="col-md-2">
                      <span
                        className="btn btn-sm btn-light-success
                              text-success btn-circle d-flex align-items-center justify-content-center"
                      >
                        <Tooltip title="Active" color="#fc4b6c">
                          <LikeOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  ) : (
                    <div className="col-md-2">
                      <span
                        className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                      >
                        <Tooltip title="InActive" color="#39cb7f">
                          <DislikeOutlined />
                        </Tooltip>
                      </span>
                    </div>
                  )}
              </div>
              <div className="col-md-2">
                <div className="col-md-2">
                  <span onClick={(e) => { editstatutory(record) }}
                    className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center"
                  >
                    <Tooltip title="Edit" color="#39cb7f">
                      <i className="ri-edit-line"></i>
                    </Tooltip>
                  </span>
                </div>
              </div>
              {record.is_approved == 2 || record.is_approved == 3 ?
                <div className="col-md-2">
                  {text == true ?
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center">
                      <Tooltip title="click here to proceed amendment" color="#39cb7f">
                        <FormOutlined />
                      </Tooltip>
                    </span> : ''}
                </div> : ''}
              {record.is_approved == 2 || record.is_approved == 3 || record.is_approved == 1 ?
                <div className="col-md-2">
                  {record.history_count > 1 ?
                    <span className="btn btn-sm btn-light-info text-info btn-circle d-flex 
                                align-items-center justify-content-center" onClick={() => { showhistory(record) }}>
                      <Tooltip title="click here to view history" color="#39cb7f">
                        <BookOutlined />
                      </Tooltip>
                    </span> : ''}
                </div> : ''}
              <div className="col-md-2">
                <div className="col-md-2">
                  {record.approval_status_text == "Rejected" ||
                    record.approval_status_text == "Yet to submit" ? (
                    <span
                      className="btn btn-sm btn btn-sm btn-light-danger text-danger btn-circle d-flex 
                                align-items-center justify-content-center"
                      onClick={(e) => {
                        delete_statutory(record);
                      }}
                    >
                      <Tooltip title="Delete Compliance" color="#39cb7f">
                        <DeleteOutlined />
                      </Tooltip>
                    </span>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>
          </Fragment>
        );
      },
    },
  ];

  const filterfunction = (value, record) => {
    let id;
    for(var l in value){
      id =l
    }
    console.log(id,'id');
    const pay = {
      session_token: authtoken,
      request: [
        "GetStatutoryMappings",
        {
          approval_status_id: value,
          active_status_id: 3,
          rcount: 0,
          page_limit: 25,
        },
      ],
    };
    console.log(value);
    // getmaplist({
    //   payload: pay,
    //   paramid: paramid,
    // });
  }

  const historycolumns = [
    {
      title: "Action Type",
      dataIndex: "task_code",
      key: "task_code",
      render: (text, record) => {
        return (<>
          <div>
            {record.h_change_mode == 0 ?
              <span>Create</span> : record.h_change_mode == 1 ?
                <span>Edit</span> :
                <span>Amendment</span>
            }
          </div>
        </>
        )
      },
    },
    {
      title: "Effective Date",
      dataIndex: "h_effective_date",
      key: "h_effective_date",
    },
    {
      title: "Statutory Info",
      dataIndex: "summary",
      key: "summary",
    },
    {
      title: "Created by",
      dataIndex: "h_created_by",
      key: "h_created_by",
    },
    {
      title: "Validated by",
      dataIndex: "h_last_updated_by",
      key: "h_last_updated_by",
      width: '100px'
    },
    {
      title: "Action",
      dataIndex: "h_action",
      key: "h_action",
    },
    {
      title: "Remarks",
      dataIndex: "h_reason",
      key: "h_reason",
    },
  ]

  const delete_statutory = (record) => {
    const payload = {
      session_token: authtoken,
      request: [
        "GetComplianceDelete",
        {
          comp_id: record.compliance_id,
          task_code: record.task_code,
        },
      ],
    };
    Swal.fire({
      title: "Are You Sure Want to Delete?",
      icon: "info",
      showCancelButton: true,
      focusConfirm: false,
      confirmButtonText: "Ok",
      cancelButtonText: "Cancel",
    }).then((result) => {
      if (result.isConfirmed) {
        deletestatutorymapping({
          payload: payload,
          paramid: paramid,
        });
      }
    });
  };

  const showhistory = (data) => {
    setmodalVisible(true);
    const pay = {
      "session_token": authtoken,
      "request": [
        "GetComplianceHistory",
        {
          "comp_id": data.compliance_id
        }
      ]
    }

    gethistorydata({
      payload: pay,
      paramid: paramid
    })
  }

  const modalcancel = () => {
    setmodalVisible(false);
  }

  const deactivatecancel = () => {
    setdeactivemodal(false);
    seteffectivedata({
      ...effectivedata,
      selecteddate: '',
      comp_id: ''
    })
    setAddFormSubmit1(false);
  }

  useEffect(() => {
    if (isAuth) {
      const pay = {
        session_token: authtoken,
        request: [
          "GetStatutoryMappings",
          {
            approval_status_id: 4,
            active_status_id: 3,
            rcount: 0,
            page_limit: 25,
          },
        ],
      };
      const infopay = {
        session_token: authtoken,
        request: ["GetStatutoryMappingsMaster", {}],
      };

      const masterpay = {
        session_token: authtoken,
        request: ["GetStatutoryMaster", {}],
      };

      getmaplist({
        payload: pay,
        paramid: paramid,
      });

      getinfodetails({
        payload: infopay,
        paramid: paramid,
      });

      getmasterlist({
        payload: masterpay,
        paramid: paramid,
      });
    }
  }, [isAuth]);


  const deactivetask = (re) => {
    seteffectivedata({
      ...effectivedata,
      comp_id: re.compliance_id
    })
    setdeactivemodal(true);
  }

  const submitdate = () => {
    setAddFormSubmit1(true);
    if (formValidator1.current.allValid()) {
      const pay = {
        "session_token": authtoken,
        "request": [
          "ChangeComplianceStatus",
          {
            "compliance_id": effectivedata.comp_id,
            "is_active": false,
            "effective_date": moment(effectivedata.selecteddate).format('DD-MMM-YYYY')
          }
        ]
      }
      deactivestatuschange({
        payload: pay,
        paramid: paramid
      })

    }
    setdeactivemodal(false);

  }

  const showresult = (e) => {
    e.preventDefault();
    setAddFormSubmit(true);
    if (formValidator.current.allValid()) {
      const pay = {
        session_token: authtoken,
        request: [
          "GetStatutoryMappingsSearch",
          {
            country_id: parseInt(searchdata.country.value),
            domain_id: parseInt(searchdata.domain.value),
            s_organization_id: parseInt(searchdata.organization.value),
            s_statutory_nature_id: parseInt(searchdata.nature.value),
            s_task_code_id: searchdata.taskcode,
            s_statutory_val: searchdata.statutory,
            s_approval_status_id: parseInt(searchdata.approvalstatus),
            s_active_status_id: parseInt(searchdata.status),
            rcount: 0,
            page_limit: 25,
          },
        ],
      };
      getmaplist({
        payload: pay,
        paramid: paramid,
      });
    }
  };

  useEffect(() => {
    if (searchdata.country && searchdata.country != "") {
      const domain = _.filter(info.domain_info, {
        c_id: parseInt(searchdata.country.value),
      });
      const nature = _.filter(info.nature_info, {
        c_id: parseInt(searchdata.country.value),
      });
      setsearchdata({
        ...searchdata,
        domaindata: domain,
        naturedata: nature,
      });
    }
  }, [searchdata.country]);

  useEffect(() => {
    if (searchdata.domain && searchdata.domain != "") {
      const organisation = _.filter(info.organisation_info, {
        c_id: parseInt(searchdata.country.value),
        d_id: parseInt(searchdata.domain.value),
      });
      setsearchdata({
        ...searchdata,
        organizationdata: organisation,
      });
    }
  }, [searchdata.domain]);

  useEffect(() => {
    formValidator.current.showMessages();
    formValidator1.current.showMessages();
  }, []);

  const addstatutory = () => {
    setlistpage('add');
  };

  const editstatutory = (record) => {
    const payload =
    {
      "session_token": authtoken,
      "request": [
        "GetComplianceEdit",
        {
          "comp_id": parseInt(record.compliance_id),
          "action_type": 1
        }
      ]
    }

    getcomplianceedit({
      payload: payload,
      paramid: paramid
    })
    setlistpage('edit')
  }

  const [baseData, setBaseData] = useState([]);
  useEffect(() => {
    if (list && list.statu_mappings.length > 0) {
      setBaseData(list.statu_mappings);
    } else {
      setBaseData([]);
    }
  }, [list]);

  const searchRecords = (e) => {
    if (e == "clear") {
      setClearText("");
      setBaseData(list.statu_mappings);
      setIconEnable(false);
    } else {
      setClearText(e.target.value);
      if (e.target.value.length > 0) {
        setIconEnable(true);
      } else {
        setIconEnable(false);
      }
      const filterTable = list.statu_mappings.filter((o) =>
        Object.keys(o).some((k) =>
          String(o[k]).toLowerCase().includes(e.target.value.toLowerCase())
        )
      );
      setBaseData(filterTable);
    }
  };

  useEffect(() => {
    if (pageState == true) {
      let pay;
      if (searchdata.country == "" && searchdata.domain == "") {
        pay = {
          session_token: authtoken,
          request: [
            "GetStatutoryMappings",
            {
              approval_status_id: searchdata.approvalstatus == 6 ? 4 : parseInt(searchdata.approvalstatus),
              active_status_id: searchdata.status == 3 ? 3 : parseInt(searchdata.status),
              rcount: Math.max((current - 1) * pageSize, 0),
              page_limit: pageSize,
            },
          ],
        };
      } else {
        pay = {
          session_token: authtoken,
          request: [
            "GetStatutoryMappingsSearch",
            {
              country_id: parseInt(searchdata.country.value),
              domain_id: parseInt(searchdata.domain.value),
              s_organization_id: parseInt(searchdata.organization.value),
              s_statutory_nature_id: parseInt(searchdata.nature.value),
              s_task_code_id: searchdata.taskcode,
              s_statutory_val: searchdata.statutory,
              s_approval_status_id: searchdata.approvalstatus == 6 ? 4 : parseInt(searchdata.approvalstatus),
              s_active_status_id: searchdata.status == 3 ? 3 : parseInt(searchdata.status),
              rcount: Math.max((current - 1) * pageSize, 0),
              page_limit: pageSize,
            },
          ],
        };
      }

      getmaplist({
        payload: pay,
        paramid: paramid,
      });
    }
  }, [current, pageSize, pageState])

  return (
    <>
      {listpage == 'list' ? (
        <div className="page-wrapper">
          <div className="page-titles pb-2 pt-2">
            <div className="row">
              <div className="col-lg-8 col-md-6 col-12 align-self-center">
                <nav aria-label="breadcrumb">
                  <ol className="breadcrumb mb-0 d-flex align-items-center">
                    <li className="breadcrumb-item">
                      <Link to="/dashboard">
                        <i className="ri-home-3-line fs-5"></i>
                      </Link>
                    </li>
                    <li className="breadcrumb-item active" aria-current="page">
                      <Link>Transaction</Link>
                    </li>
                    <li
                      className="breadcrumb-item active breadcrumsheader"
                      aria-current="page"
                    >
                      <Link>Statutory Mapping</Link>
                    </li>
                  </ol>
                </nav>
              </div>
            </div>
          </div>
          <div className="container-fluid pt-1">
            <div className="row">
              <div className="col-12">
                <div className="card-body" style={{ padding: "15px 5px" }}>
                  <Collapse defaultActiveKey={["1"]}>
                    <Panel header="StatutoryMapping Search" key={key}>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Country:<span style={{ color: "red" }}>*</span>
                            </label>
                            <div className="form-group">
                              <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select Country"
                                name="Country"
                                className="form-control"
                                id="Country"
                                onChange={(data, value) => {
                                  setsearchdata({
                                    ...searchdata,
                                    country: value,
                                  });
                                }}
                              >
                                {info &&
                                  info.country_info.length > 0 &&
                                  info.country_info.map((item) => {
                                    return (
                                      <Option key={item.c_id}>
                                        {item.c_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                              {formValidator.current.message(
                                "Country",
                                searchdata.country,
                                ["required"],
                                {
                                  className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                    }`,
                                  messages: {
                                    required: "Country Name Required",
                                  },
                                }
                              )}
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Domain:<span style={{ color: "red" }}>*</span>
                            </label>
                            <Select
                              allowClear={false}
                              size="default"
                              name="domain"
                              placeholder="Select Domain"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              onChange={(data, value) => {
                                setsearchdata({
                                  ...searchdata,
                                  domain: value,
                                });
                              }}
                            >
                              {searchdata.domaindata &&
                                searchdata.domaindata.length > 0 &&
                                searchdata.domaindata.map((item) => {
                                  return (
                                    <Option key={item.d_id}>
                                      {item.d_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                            {formValidator.current.message(
                              "domain",
                              searchdata.domain,
                              ["required"],
                              {
                                className: `invalid-feedback ${AddFormSubmit ? "show" : "hide"
                                  }`,
                                messages: {
                                  required: "Compfie Domain Name Required",
                                },
                              }
                            )}
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Organization:
                            </label>
                            <Select
                              allowClear={false}
                              size="default"
                              placeholder="Select Organization"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              onChange={(data, value) => {
                                setsearchdata({
                                  ...searchdata,
                                  organization: value,
                                });
                              }}
                            >
                              {searchdata.organizationdata &&
                                searchdata.organizationdata.length > 0 &&
                                searchdata.organizationdata.map((item) => {
                                  return (
                                    <Option key={item.org_id}>
                                      {item.org_name}
                                    </Option>
                                  );
                                })}
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Statutory Nature:
                            </label>
                            <div className="form-group">
                              <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select Statutory Nature"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                                onChange={(data, value) => {
                                  setsearchdata({
                                    ...searchdata,
                                    nature: value,
                                  });
                                }}
                              >
                                {searchdata.naturedata &&
                                  searchdata.naturedata.length > 0 &&
                                  searchdata.naturedata.map((item) => {
                                    return (
                                      <Option key={item.s_n_id}>
                                        {item.s_n_name}
                                      </Option>
                                    );
                                  })}
                              </Select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Statutory:
                            </label>
                            <input
                              className="form-control input-sm"
                              type="text"
                              placeholder="Enter Statutory"
                              autocomplete="off"
                              style={{ height: "32px" }}
                              onChange={(e) => {
                                setsearchdata({
                                  ...searchdata,
                                  statutory: e.target.value,
                                });
                              }}
                            />
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Approval Status:
                            </label>
                            <Select
                              allowClear={false}
                              size="default"
                              placeholder="Select ApprovalStatus"
                              showSearch
                              filterOption={(input, option) =>
                                option.children
                                  .toLowerCase()
                                  .includes(input.toLowerCase())
                              }
                              style={{ width: "100%" }}
                              defaultValue="All"
                              onChange={(data, value) => {
                                setsearchdata({
                                  ...searchdata,
                                  approvalstatus: value.value,
                                });
                              }}
                            >
                              <Option value="6">All</Option>
                              <Option value="4">Rejected</Option>
                              <Option value="0">Yet to Submit</Option>
                              <Option value="1">Pending</Option>
                              <Option value="2">Approved</Option>
                              <Option value="3">Approved & Notified</Option>
                            </Select>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="row">
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Status:
                            </label>
                            <div className="form-group">
                              <Select
                                allowClear={false}
                                size="default"
                                placeholder="Select Status"
                                showSearch
                                filterOption={(input, option) =>
                                  option.children
                                    .toLowerCase()
                                    .includes(input.toLowerCase())
                                }
                                style={{ width: "100%" }}
                                defaultValue="All"
                                onChange={(data, value) => {
                                  setsearchdata({
                                    ...searchdata,
                                    status: value.value,
                                  });
                                }}
                              >
                                <Option value="3">All</Option>
                                <Option value="1">Active</Option>
                                <Option value="0">InActive</Option>
                              </Select>
                            </div>
                          </div>
                          <div className="col-md-4">
                            <label style={{ fontWeight: "bold" }}>
                              Task Code:
                            </label>
                            <input
                              className="form-control input-sm"
                              type="text"
                              placeholder="Task Code"
                              autocomplete="off"
                              style={{ height: "32px" }}
                              onChange={(e) => {
                                setsearchdata({
                                  ...searchdata,
                                  taskcode: e.target.value,
                                });
                              }}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="col-md-12" style={{ marginTop: "10px" }}>
                        <div className="row">
                          <div className="col-md-4"></div>
                          <div className="col-md-4">
                            <Button
                              type="primary"
                              shape="round"
                              className="addbutton"
                              style={{
                                background: "#3CB371",
                                borderColor: "#3CB371",
                                marginLeft: "39px",
                                marginRight: "10px",
                                width: "100px",
                              }}
                              onClick={showresult}
                            >
                              Show
                            </Button>
                          </div>
                        </div>
                      </div>
                    </Panel>
                  </Collapse>
                  <div className="mt-2">
                    <div class="sta-search-box">
                      <input
                        class="search-txt"
                        value={clearText}
                        type="text"
                        onChange={searchRecords}
                        placeholder="Type to Search"
                      />
                      <a class="search-btn">
                        {iconEnable == true ? (
                          <i
                            class="fas fa-close"
                            onClick={(e) => {
                              searchRecords("clear");
                            }}
                          ></i>
                        ) : (
                          <i class="fas fa-search"></i>
                        )}
                      </a>
                    </div>
                    <Tooltip title="Add StatutoryMapping" color="#1890ff">
                      <Button
                        type="primary"
                        shape="round"
                        className="addbutton"
                        icon={<PlusCircleOutlined />}
                        size="default"
                        style={{
                          float: "right",
                          "margin-top": "6px",
                          "margin-bottom": "10px",
                        }}
                        onClick={addstatutory}
                      >
                        Add
                      </Button>
                    </Tooltip>

                    <Table
                      size={'small'}
                      columns={columns}
                      dataSource={baseData && baseData.length > 0 && baseData}
                      bordered
                      scroll={{ x: 1200 }}
                      pagination={false}
                    />
                    <Pagination
                      style={{ "margin-top": "10px", float: " RIGHT" }}
                      current={current}
                      showSizeChanger={true}
                      showQuickJumper={false}
                      onShowSizeChange={() => {
                        setCurrent(1);
                      }}
                      pageSizeOptions={[25, 50, 100]}
                      defaultPageSize={25}
                      onChange={(page, pageSizes) => {
                        setpageState(true);
                        setCurrent(pageSize !== pageSizes ? 1 : page);
                        setPageSize(pageSizes);
                      }}
                      total={list.total_records}
                    />
                  </div>
                  <div
                    style={{
                      border: "1px solid",
                      "margin-top": "10px",
                      width: "200px",
                    }}
                  >
                    <span style={{ color: "#8A2BE2" }}>
                      Total Records :<b> {list.total_records}</b>
                    </span>
                    <br />
                    <span style={{ color: "#8A2BE2", "margin-left": "2px" }}>
                      InActive Count: <b>{list.inactive_count}</b>
                    </span>
                    <br />
                    <span style={{ color: "#8A2BE2", "margin-left": "2px" }}>
                      Amendment Count: <b>{list.amendment_count}</b>
                      <br />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <Modal title="Compliance History" visible={modalVisible} footer={null} maskClosable={false} className="Assignunit"
            onCancel={modalcancel}>
            <div className="row">
              <div className="col-lg-12">
                <Table
                  size={'small'}
                  columns={historycolumns}
                  dataSource={history && history.h_compliance_history_list.length > 0 && history.h_compliance_history_list}
                  bordered
                  pagination={false}
                />
              </div>
            </div>
          </Modal>
          <Modal visible={deactivemodal} className="newStyleModalPassword" title="Task Deactivate" footer={null} onCancel={deactivatecancel}>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-6">
                  <label className="control-label"> <b>Implementation Effective Date:</b><span style={{ "color": "red" }}>*</span></label>
                  <Input
                    type="date"
                    id="stat_date"
                    name="stat_date"
                    autoComplete={"off"}
                    min={moment().add(1, 'days').format('YYYY-MM-DD')}
                    value={effectivedata.selecteddate}
                    onChange={(e) => {
                      seteffectivedata({
                        ...effectivedata,
                        selecteddate: e.target.value
                      });
                    }}
                  />
                  {formValidator1.current.message(
                    'stat_date',
                    effectivedata.selecteddate,
                    ['required'],
                    {
                      className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                      messages: {
                        required: 'Effective Date required'
                      }
                    })}
                </div>
              </div>

            </div>
            <br />
            <div className="form-actions">
              <Button type="primary" shape="round" className='addbutton'
                style={{ background: "#198754", borderColor: "#198754", marginLeft: '33%' }}
                icon={<PlayCircleOutlined />} size='default' onClick={submitdate}>
                Submit
              </Button>
            </div>
          </Modal>
        </div>
      ) : listpage == 'add' ? (
        <Addstatutorymapping />
      ) : <Editstatutorymapping />}
    </>
  );
};
export default connect(mapStateToProps, {
  getmaplist,
  getinfodetails,
  getmasterlist,
  deletestatutorymapping,
  getcomplianceedit,
  gethistorydata,
  deactivestatuschange
})(StatutoryMapping);
