import { combineReducers } from 'redux';
import Login from './Login';
import Rejectedsummary from './Transactions/Rejectedsummary';
import Taskduedate from './Transactions/Taskduedate'
import Taskcategory from './Transactions/Taskcategory'
import StatutoryNotificationList from './Reports/StatutoryNotificationList';
import StatutoryMapping_BulkUploadReport from './Reports/StatutoryMapping_BulkUploadReport';
import StatutoryMappingReport from './Reports/StatutoryMappingReport';
import Statutorymapping from './Transactions/Statutorymapping';
import MappingBulkupload from './Transactions/MappingBulkupload';
import Profile from './Profile'
import Approvetaskcategorymapping from './Transactions/Approvetaskcategorymapping';
import ApproveStatutoryMapping from './Transactions/ApproveStatutoryMapping';
import ApproveStatotoryBulkupload from './Transactions/Approve_stat_bulk'
import Assignclientunit from './Transactions/Assignclientunit';
import Assignstutorybulk from './Transactions/AssignStatutoryBulkupload'
import UserMappingReport from './Reports/UserMappingReport';

const appReducer = combineReducers({
    Login,
    Rejectedsummary,
    Taskduedate,
    Taskcategory,
    StatutoryNotificationList,
    Statutorymapping,
    StatutoryMapping_BulkUploadReport,
    StatutoryMappingReport,
    Profile,
    MappingBulkupload,
    Approvetaskcategorymapping,
    ApproveStatutoryMapping,
    ApproveStatotoryBulkupload,
    Assignclientunit,
    Assignstutorybulk,
    UserMappingReport
});
export default (state, action) => {
    return appReducer(state, action);
};