import HTTP from '../../../Libs/http';
import { TASK_DUE_DATE,TASKDUEDATE,TASK_HISTORY } from '../../types/index'

const initialState = {
    TaskDuedate: {
       list:'',
       duedate:'',
       history:''
    }
}
export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case TASK_DUE_DATE: {
            return {
                ...state,
                TaskDuedate: {
                    ...state.TaskDuedate,
                    list:payload,
                    
                    
                },
            };
        }
        case TASKDUEDATE: {
            return {
                ...state,
                TaskDuedate: {
                    ...state.TaskDuedate,
                    duedate:payload,
                    
                    
                },
            };
        }
        case TASK_HISTORY: {
            return {
                ...state,
                TaskDuedate: {
                    ...state.TaskDuedate,
                    history:payload,
                    
                    
                },
            };
        }
        default:
            return state;
    }
}