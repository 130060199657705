import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Modal, Input, Table, Tooltip } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { notificationshowall } from '../../Store/Action/Login';
const _ = require("lodash");


const mapStateToProps = (state) => ({
    lists: state.Login.notificationshowall.statutory_notifications,

})
const Notifications = ({
    notificationshowall,
    lists,
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const header = paramid.split('/');
    var breadcrumb = '';
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    



    const columns = [
        {
            title: 'Date',
            dataIndex: 'created_on',
            key: 'created_on',
            width: '30px'

        },
        {
            title: 'Title',
            dataIndex: 'notification_text',
            key: 'notification_text',
            ellipsis: true,
            width: '150px',

        },
    ];

    useEffect(() => {
        if (isAuth) {
            const getUrl = callername.login_response.menu.menus['My Accounts'] 
            const messageArray = _.filter(getUrl, { form_name: 'Statutory Notification'});
            const messagekey = messageArray[0].form_key;

            let payload = {
                "session_token": authtoken,
                "request": [
                 "GetStatutoryNotifications",
                 {
                  "from_count": 0,
                  "page_count": 2
                 }
                ]
               }

               notificationshowall({
                payload: payload,
                caller: paramid,
                key: messagekey
            })

        }
    }, [isAuth])

    return (
        <Fragment>
            <div>

                <div className="page-wrapper">
                    <div className="page-titles pb-2 pt-2">
                        <div className="row">
                            <div className="col-lg-8 col-md-6 col-12 align-self-center">
                                <nav aria-label="breadcrumb">
                                    <ol className="breadcrumb mb-0 d-flex align-items-center">
                                        <li className="breadcrumb-item">
                                            <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page">
                                            <Link>Home</Link>
                                        </li>
                                        <li className="breadcrumb-item active" aria-current="page" style={{ fontSize: 'large', fontWeight: 'bold' }}>
                                            <Link>Notifications</Link>
                                        </li>
                                    </ol>
                                </nav>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid pt-1">
                        <div className="row">
                            <div className="col-12">
                                <div className="card">

                                    <div className="card-body" style={{ padding: '0px' }}>

                                        {/* <DataTable
                                                columns={columns}
                                                data={filteredItems}
                                                fixedHeader={dataTableProperties.fixedHeader}
                                                pagination
                                                subHeader
                                                subHeaderComponent={subHeaderComponent}
                                            /> */}
                                        <Table
                                            className='userprivclass'
                                            columns={columns}
                                            dataSource={lists}
                                            bordered
                                            pagination={{
                                                defaultPageSize: dataTableProperties.pagesize,
                                                showSizeChanger: dataTableProperties.sizechanger
                                            }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div >

            </div>

        </Fragment>
    )
}
export default connect(mapStateToProps, {
    notificationshowall,
})(Notifications);