import axios from 'axios';
const instance = axios.create({})



export default class HTTP {

    static setAuthToken(token) {
        localStorage.setItem('authToken', token)
        localStorage.setItem('isAuthenticated',true);
        sessionStorage.setItem('isAuthenticated',true);
    }

    static getAuthToken() {
        return localStorage.getItem('authToken') !== "null"
            && localStorage.getItem('authToken') !== "undefined"
            && localStorage.getItem('authToken') !== undefined
            && localStorage.getItem('authToken') !== null
            && localStorage.getItem('authToken')
    }

    // static get({
    //     url,
    //     headers,
    //     executeCancel
    // }) {
    //     const CancelToken = axios.CancelToken;
       
    //     if (typeof executeCancel === 'function')
    //         config = {
    //             ...config,
    //             cancelToken: new CancelToken(function executor(cancelFunction) {
    //                 executeCancel(cancelFunction);
    //             })
    //         }
    //         return instance.post(url, payload, headers)
    // }

    static post({
        url,
        headers,
        executeCancel,
        body
    }) {
        //const CancelToken = axios.CancelToken;
        const payload = body && Object.keys(body).length > 0 ? body : {}
        return instance.post(url, payload, headers)
    }

    static commonpost({
        url,
        executeCancel,
        body
    }) {
        const head = {
            headers: {
                'Content-Type': 'application/json'
            },
        }
        const payload = body && Object.keys(body).length > 0 ? body : {}
        return instance.post(url, payload, head)
    }
    
    static execute(request) {
        return new Promise((resolve, reject) => {
            request.then(res => {
                resolve(res)
            }).catch(err => {
                resolve(err)
            })
        })
    }

    static parseResponse(response) {
       
        const { data, error } = response.data
        return response.data
        if (error)
            throw new Error(error)
        else return data
    }

   

  
}