import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { Collapse, Select, Button, Table, Pagination, Modal, Card } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { StatutoryMappingReportFilters, StatutoryMappingReportData } from './../../../src/Store/Action/Reports/StatutoryMappingReport';

const mapStateToProps = (state) => ({
    statutorymappingresult: state.StatutoryMappingReport.statutorymapping,
})

const StatutoryMappingReport = ({
    StatutoryMappingReportFilters,
    StatutoryMappingReportData,
    statutorymappingresult: { StatutoryMappingReportSuccess, StatutoryMappingReportShow }
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    let [index, setIndex] = useState(0)
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [Country, setCountry] = useState([])
    const [act, setAct] = useState([])
    const [domain, setDomain] = useState([])
    const [organization, setOrganization] = useState([])
    const [statutorynature, setStatutorynature] = useState([])
    const [compliancefrequency, setComplianceFrequency] = useState([])
    const [criticality, setCriticality] = useState([])
    const [geography, setGeography] = useState([])
    const [showstatutory, setShowstatutory] = useState([])

    const [applicationModal, setApplicationModal] = useState(false)
    const [geo, setGeo] = useState([])
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true,
        currentpage: 1,
        pageSizeOptions: ['10', '20', '30']
    })
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [domainName, setDomainName] = useState([]);
    const [unitdata, setUnitData] = useState({
        country: '',
        countryName: '',
        domain: '',
        domain_name: '',
        act: '',
        act_name: '',
        from_date: "",
        to_date: "",
        organization: '',
        organization_name: '',
        statutorynature: 0,
        statutorynature_name: '',
        compliance_frequency: 0,
        compliance_frequency_name: 'All',
        geography: 0,
        geography_name: '',
        criticality: 0,
        criticality_name: 'All'
    })

    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [parentNames, setParentNames] = useState([])
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [page, setPage] = useState(1);
    const [filteredFrequency, setFilteredFrequency] = useState([]);
    const [updatedArray, setUpdatedArray] = useState([])
    const [filteredFields, setFilteredFields] = useState({
        filteredDomain: [],
        filteredNature: [],
        filteredAct: [],
    })
    console.log(domainName, 'domain')


    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '60px',
            ellipsis: true,
            render: (text, record) => {

                return index + 1
            }
        },
        {
            title: 'Organization',
            dataIndex: 'industry_names',
            key: 'industry_names',
            width: '150px',
            ellipsis: true,
        },
        {
            title: 'Statutory Nature',
            dataIndex: 'statutory_nature_name',
            key: 'statutory_nature_name',
            width: '150px',
            ellipsis: true,

        },
        {
            title: 'Statutory Provision',
            dataIndex: 's_pro_map',
            key: 's_pro_map',
            width: '170px',
            ellipsis: true,
        },
        {
            title: 'Compliance Task',
            dataIndex: 'c_task',
            key: 'c_task',
            width: '150px',
            ellipsis: true,
        },
        {
            title: 'Criticality',
            dataIndex: 'criticality_name',
            key: 'criticality_name',
            width: '150px',
            ellipsis: true,
        },
        {
            title: 'Compliance Description',
            dataIndex: 'description',
            key: 'description',
            width: '190px',
            ellipsis: true,
        },
        {
            title: 'Penal Consequences',
            dataIndex: 'p_consequences',
            key: 'p_consequences',
            width: '170px',
            ellipsis: true,
        },
        {
            title: 'Compliance Occurrence',
            dataIndex: 'summary',
            key: 'summary',
            width: '190px',
            ellipsis: true,
        },
        {
            title: 'Applicable Location',
            // dataIndex: 'geo_maps',
            // key: 'geo_maps',
            dataIndex: false,
            key: false,
            width: '180px',
            render: (text, record) => {
                return (
                    <>
                        <Link onClick={() => application(record.geo_maps)}>Applicable Location</Link>
                    </>
                )
            },
        }
    ];

    const column = [
        {
            title: 'Applicable location',
            dataIndex: false,
            key: false,
            width: '150px',
            ellipsis: true,
        }
    ]

    useEffect(() => {
        if (isAuth) {
            StatutoryMappingReportFilters({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryMappingReportFilters",
                        {}
                    ]
                },
                paramid: paramid
            })
        }
    }, [isAuth, legalstate])

    useEffect(() => {
        if (StatutoryMappingReportSuccess) {
            setAct(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.level_one_statutories)
            setCountry(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.countries)
            setDomain(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.domains)
            setOrganization(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.industries)
            setStatutorynature(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.statutory_natures)
            setComplianceFrequency(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.compliance_frequency)
            setCriticality(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.criticality)
            // setGeography(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.geographies)
        }
    }, [StatutoryMappingReportSuccess])

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (unitdata.country) {
            StatutoryMappingReportSuccess.domains && StatutoryMappingReportSuccess.domains.length > 0 && StatutoryMappingReportSuccess.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(unitdata.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name,
                        'product': domainList.product,
                        'is_cmpf': domainList.is_cmpf,
                        'is_ltgs': domainList.is_ltgs,
                        'is_vndr': domainList.is_vndr,
                        'is_active': domainList.is_active
                    }
                    domainArray.push(tempArray)
                }
            })

            setFilteredFields({
                ...filteredFields,
                filteredDomain: domainArray
            })
        }
    }, [unitdata.country])

    useEffect(() => {
        let industries = _.filter(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.industries, { country_id: parseInt(unitdata.country), domain_id: parseInt(unitdata.domain) })
        let act = _.filter(StatutoryMappingReportSuccess.level_one_statutories, { country_id: parseInt(unitdata.country), domain_id: parseInt(unitdata.domain) })
        setFilteredFields({
            ...filteredFields,
            filteredOrganization: industries,
            filteredAct: act

        })

    }, [unitdata.domain])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (StatutoryMappingReportShow.statutory_mappings) {
            let arrayTemp = [];
            for (let i in StatutoryMappingReportShow.statutory_mappings) {
                arrayTemp.push(StatutoryMappingReportShow.statutory_mappings[i])
            }
            var merged = [].concat.apply([], arrayTemp);
            setUpdatedArray([...merged])
        }
    }, [StatutoryMappingReportShow.statutory_mappings])

    useEffect(() => {
        if (updatedArray && updatedArray.length > 0) {
            let uniqueObjArray = [...new Map(updatedArray && updatedArray.length > 0 && updatedArray.map((item) => [item["act_name"], item])).values()];

            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.act_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(updatedArray && updatedArray.length > 0 && updatedArray, { act_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }

            setParentNames([...parentNames, tempArr])
        }
    }, [updatedArray])

    useEffect(() => {
        if (parentNames.length > 0) {
            let temp = []
            for (let i in parentNames) {

                temp = parentNames[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [parentNames])

    useEffect(() => {
        let tempArr = []
        if (updatedArray && updatedArray && updatedArray.length > 0) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length > 0 && item.map((items) => [items["frequency_id"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.frequency_id
                })

                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { frequency_id: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })

            setFilteredFrequency(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let arr = [];
        let countrylisttempdata = [];
        let tempSetArray = [];
        let parId = []
        if (updatedArray && updatedArray.length > 0) {
            if (filteredFrequency && filteredFrequency.length > 0) {
                const ab = filteredFrequency && filteredFrequency.length > 0 && filteredFrequency.map(function (user) {
                    if ((arr.indexOf(user.frequency_id) < 0))
                        arr.push({ 'frequency_id': user.frequency_id, 'act_name': user.act_name });
                });
                arr.map((pmenu, index) => {

                    let entityArray = []
                    let tempArray = []
                    var frequency_name = ''
                    let totalList = []
                    if (pmenu) {
                        var indexNo = 1;
                        updatedArray && updatedArray.length > 0 && updatedArray.map((subList, childIndex) => {
                            if (pmenu.frequency_id === subList.frequency_id && pmenu.act_name == subList.act_name) {
                                switch (subList.frequency_id) {
                                    case 1:
                                        frequency_name = 'One Time'
                                        break;
                                    case 2:
                                        frequency_name = 'Periodical'
                                        break;
                                    case 3:
                                        frequency_name = 'Review'
                                        break;
                                    case 4:
                                        frequency_name = 'Flexi Review'
                                        break;
                                    case 5:
                                        frequency_name = 'On Occurence'
                                        break;
                                    default:
                                        frequency_name = ''
                                        break;
                                }
                                entityArray = {
                                    'indexNo': childIndex + 1,
                                    'act_name': subList.act_name,
                                    'approval_status_id': subList.approval_status_id,
                                    'c_task': subList.c_task,
                                    'comp_id': subList.comp_id,
                                    'country_name': subList.country_name,
                                    'criticality_name': subList.criticality_name,
                                    'd_type_id': subList.d_type_id,
                                    'description': subList.description,
                                    'domain_name': subList.domain_name,
                                    'duration': subList.duration,
                                    'frequency_id': subList.frequency_id,
                                    'geo_maps': subList.geo_maps,
                                    'industry_names': subList.industry_names,
                                    'is_active': subList.is_active,
                                    'p_consequences': subList.p_consequences,
                                    'parent_names': subList.parent_names,
                                    'r_every': subList.r_every,
                                    'r_type_id': subList.r_type_id,
                                    's_pro_map': subList.s_pro_map,
                                    'statu_dates': subList.statu_dates,
                                    'statutory_nature_name': subList.statutory_nature_name,
                                    'summary': subList.summary,
                                    'url': subList.url,
                                    'frequency_name': frequency_name
                                }
                                tempArray.push(entityArray)
                            }
                            indexNo++;
                        })
                    }
                    totalList = {
                        index: index,
                        frequency_ids: pmenu.frequency_id,
                        parentData: frequency_name,
                        child: tempArray
                    }
                    countrylisttempdata.push(totalList)
                })
                // setShowstatutory([...countrylisttempdata].sort((a, b) => a.frequency_ids - b.frequency_ids))
                setShowstatutory(countrylisttempdata)
            }

        }
    }, [updatedArray, filteredFrequency])

    useEffect(() => {
        if (pageState == true) {
            StatutoryMappingReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryMappingReportData",
                        {
                            "c_id": Number(unitdata.country),
                            "d_id": Number(unitdata.domain),
                            "a_i_id": Number(unitdata.criticality) == 0 ? null : Number(unitdata.criticality),
                            "a_s_n_id": Number(unitdata.statutorynature) == 0 ? null : Number(unitdata.statutorynature),
                            "a_g_id": Number(unitdata.geography) == 0 ? null : Number(unitdata.geography),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "frequency_id": Number(unitdata.compliance_frequency) == 0 ? null : Number(unitdata.compliance_frequency),
                            "criticality_id": Number(unitdata.criticality) == 0 ? null : Number(unitdata.criticality),
                            "r_count": Math.max(((current - 1) * pageSize), 0),
                            "page_count": pageSize//Math.max(((current - 1) * pageSize), 0)
                        }
                    ]
                },
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    const onshow = () => {
        setAddFormSubmit(true)
        setfilteredArrayState([])
        let domName = _.find(domain && domain.length > 0 && domain, { domain_id: parseInt(unitdata.domain) })

        setDomainName(domName.domain_name);
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
            StatutoryMappingReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryMappingReportData",
                        {
                            "c_id": Number(unitdata.country),
                            "d_id": Number(unitdata.domain),
                            "a_i_id": Number(unitdata.organization) == 0 ? null : Number(unitdata.organization),
                            "a_s_n_id": Number(unitdata.statutorynature) == 0 ? null : Number(unitdata.statutorynature),
                            "a_g_id": Number(unitdata.geography) == 0 ? null : Number(unitdata.geography),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "frequency_id": Number(unitdata.compliance_frequency) == 0 ? null : Number(unitdata.compliance_frequency),
                            "criticality_id": Number(unitdata.criticality) == 0 ? null : Number(unitdata.criticality),
                            "r_count": 0,
                            "page_count": 25
                        }
                    ]
                },
                paramid: paramid
            })

        }
    }

    const handleCancel = () => {
        setApplicationModal(false)
        setGeo([])
        setDataTableProperties({
            ...dataTableProperties,
            currentpage: 1
        })
        // setShowValueModal(false);
    };

    const application = (geo_maps) => {
        setApplicationModal(true)
        setGeo(geo_maps)
    }

    const download = (url) => {
        window.open("https://13.234.100.64/" + url)

    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Report</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link>Statutory Mapping Report</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} >
                                <Panel header="Statutory Mapping Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><b>Country</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            placeholder="Enter Country"
                                                            onChange={(value, data) => {
                                                                let geography = Object.values(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.geographies[value]);
                                                                setGeography(geography)
                                                                let statutory = _.filter(StatutoryMappingReportSuccess && StatutoryMappingReportSuccess.statutory_natures, { country_id: Number(value) })
                                                                setStatutorynature(statutory)
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    domain: '',
                                                                    domain_name: '',
                                                                    act: '',
                                                                    act_name: '',
                                                                    organization: '',
                                                                    organization_name: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            value={unitdata.countryName || undefined}
                                                        >
                                                            {Country && Country.length && Country.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            unitdata.countryName,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Please Enter Country',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Geography</label>
                                                <Select
                                                    value={unitdata.geography_name || undefined}
                                                    onChange={(value, data) => {
                                                        // let act = _.filter(statutorylist.level_one_statutories, { domain_id: Number(value) })
                                                        // setAct(act)
                                                        setUnitData({
                                                            ...unitdata,
                                                            geography: value,
                                                            geography_name: data.children
                                                        })
                                                    }}

                                                    disabled={unitdata.domain == '' ? true : false}
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Geography"
                                                    style={{ width: '100%' }}
                                                >
                                                    {geography && geography.length > 0 && geography.map((item, i) => {
                                                        return (
                                                            <Option key={item.geography_id}>
                                                                {item.geography_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Domain <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.country == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children,
                                                            act: '',
                                                            act_name: '',
                                                            organization: '',
                                                            organization_name: ''
                                                        })
                                                    }}
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    placeholder="Enter Domain"
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredDomain && filteredFields.filteredDomain.length > 0 && filteredFields.filteredDomain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter Domain',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Act</label>
                                                <Select
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.domain == '' ? true : false}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredAct && filteredFields.filteredAct.length > 0 && filteredFields.filteredAct.map((item, i) => {
                                                        return (
                                                            <Option key={item.level_1_statutory_id}>
                                                                {item.level_1_statutory_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Organization</label>
                                                <Select
                                                    value={unitdata.organization_name || undefined}
                                                    showSearch
                                                    disabled={unitdata.domain == '' ? true : false}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            organization: value,
                                                            organization_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Organization"
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredOrganization && filteredFields.filteredOrganization.length > 0 && filteredFields.filteredOrganization.map((item, i) => {
                                                        return (
                                                            <Option key={item.industry_id}>
                                                                {item.industry_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Compliance Frequency</label>
                                                <Select
                                                    defaultValue="All"
                                                    value={unitdata.compliance_frequency_name}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            compliance_frequency: value,
                                                            compliance_frequency_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%' }}
                                                >
                                                    <Option key="All">All</Option>
                                                    {compliancefrequency && compliancefrequency && compliancefrequency.map((item, i) => {
                                                        return (
                                                            <Option key={item.frequency_id}>
                                                                {item.frequency}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Statutory Nature</label>
                                                <Select
                                                    value={unitdata.statutorynature_name || undefined}
                                                    showSearch
                                                    disabled={unitdata.country == '' ? true : false}
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            statutorynature: value,
                                                            statutorynature_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Statutory Nature"
                                                    style={{ width: '100%' }}
                                                >
                                                    {statutorynature && statutorynature.length > 0 && statutorynature.map((item, i) => {
                                                        return (
                                                            <Option key={item.statutory_nature_id}>
                                                                {item.statutory_nature_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Criticality</label>
                                                <Select
                                                    value={unitdata.criticality_name}
                                                    defaultValue={"All"}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            criticality: value,
                                                            criticality_name: data.children
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '100%' }}
                                                >
                                                    <Option key={0}>All</Option>
                                                    {criticality && criticality.map((item, i) => {
                                                        return (
                                                            <Option key={item.criticality_id}>
                                                                {item.criticality_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "20px", textAlign: 'center', marginLeft: '26%' }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <Button title='Show Records' type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <br />

                            {tableshow == true ? <>
                                <Card>
                                    <div className='row'>
                                        <div className='col-lg-6'><b>Country</b> : {unitdata.countryName}</div>
                                        <div className='col-lg-6'><b>Domain</b> : {domainName}</div>
                                    </div>
                                </Card>
                                {filteredArrayState && filteredArrayState.length > 0 ?
                                    filteredArrayState.map((list) => {

                                        return (<Collapse defaultActiveKey={['0']}>
                                            <Panel header={list[0].act_name} key={1}>
                                                <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                                    <div className='ant-table-container'>
                                                        <div className='ant-table-content fhsmp userprivclass1'>
                                                            <table className='widthHeighsmp table-fixed'>
                                                                <colgroup>
                                                                    <col style={{ width: '10px' }}></col>
                                                                </colgroup>
                                                                <thead className='ant-table-thead'>
                                                                    <tr>
                                                                        <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Organization</th>
                                                                        <th className='ant-table-cell' style={{ width: '105px' }}>Statutory Nature</th>
                                                                        <th className='ant-table-cell' style={{ width: '140px' }}>Statutory Provision</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Task</th>
                                                                        <th className='ant-table-cell' style={{ width: '90px' }}>Criticality</th>
                                                                        <th className='ant-table-cell' style={{ width: '140px' }}>Compliance Description</th>
                                                                        <th className='ant-table-cell' style={{ width: '100px' }}>Penal Consequences</th>
                                                                        <th className='ant-table-cell' style={{ width: '145px' }}>Compliance Occurrence</th>
                                                                        <th className='ant-table-cell' style={{ width: '130px' }}>Applicable Location</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody className='ant-table-tbody'>
                                                                    <>
                                                                        {showstatutory && showstatutory.length > 0 && showstatutory.map((childList) => {
                                                                            let data = _.find(childList.child, { act_name: list[0].act_name })
                                                                            if (data != undefined) {
                                                                                if (data.frequency_id == childList.frequency_ids) {
                                                                                    return <>
                                                                                        <tr className='ant-table-row ant-table-row-level-0'>
                                                                                            <td colSpan={10} className='ant-table-cell'><b>{childList.parentData}</b></td>
                                                                                        </tr>
                                                                                        {childList.child.map((items) => {
                                                                                            return <>
                                                                                                <tr className='ant-table-row ant-table-row-level-0'>

                                                                                                    <td className='ant-table-cell'>{items.indexNo}</td>
                                                                                                    <td title={items.industry_names} className='ant-table-cell ellipsis ant-table-cell-with-append'>
                                                                                                        {items.industry_names}</td>
                                                                                                    <td title={items.statutory_nature_name} className='ant-table-cell ellipsis'>{items.statutory_nature_name}</td>
                                                                                                    <td title={items.s_pro_map} className='ant-table-cell ellipsis'>{items.s_pro_map}</td>
                                                                                                    {items.url != null ?
                                                                                                        <td title={items.c_task} className='ant-table-cell ellipsis'><Link download onClick={(e) => {
                                                                                                            download(items.url)
                                                                                                        }} >{items.c_task}</Link></td>
                                                                                                        : <td title={items.c_task} className='ant-table-cell ellipsis'>{items.c_task}</td>}
                                                                                                    <td title={items.criticality_name} className='ant-table-cell ellipsis'>{items.criticality_name}</td>
                                                                                                    <td title={items.description} className='ant-table-cell ellipsis'>{items.description}</td>
                                                                                                    <td title={items.p_consequences} className='ant-table-cell ellipsis'>{items.p_consequences}</td>
                                                                                                    <td title={items.summary} className='ant-table-cell ellipsis'>{items.summary}</td>
                                                                                                    <td title={items.description} className='ant-table-cell ellipsis'> <Link onClick={() => application(items.geo_maps)}>Applicable Location</Link></td>
                                                                                                </tr>
                                                                                            </>
                                                                                        })}
                                                                                    </>
                                                                                }
                                                                            }
                                                                        })}
                                                                    </>
                                                                </tbody>
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </Panel>
                                        </Collapse>
                                        )
                                    }) : <span style={{ marginLeft: '45%' }}><b>No Records Found</b></span>}
                                <div>
                                    <span>Showing {(current - 1) * pageSize + 1} to {current * pageSize} of {StatutoryMappingReportShow.total_count} entries | Show</span>
                                    <span><Pagination style={{ "margin-top": "10px", float: " RIGHT" }}
                                        current={current}
                                        showSizeChanger={true}
                                        showQuickJumper={false}
                                        onShowSizeChange={() => {
                                            setCurrent(1)
                                        }}
                                        pageSizeOptions={[25, 50, 100]}
                                        hideOnSinglePage={true}
                                        defaultPageSize={25}
                                        onChange={(page, pageSizes) => {
                                            setpageState(true)
                                            setCurrent(pageSize !== pageSizes ? 1 : page);
                                            setPageSize(pageSizes)
                                        }} total={StatutoryMappingReportShow.total_count} /></span>
                                </div>
                            </> : false}
                        </div>
                    </div>
                </div>
                <Modal title="Applicable Location" visible={applicationModal} footer={null} onCancel={handleCancel}>
                    <Table
                        className='class'
                        columns={column}
                        dataSource={geo}
                        bordered
                        pagination={{
                            onChange: (page) => {
                                setDataTableProperties({
                                    ...dataTableProperties,
                                    currentpage: page
                                })
                            },
                            current: dataTableProperties.currentpage,
                            defaultPageSize: dataTableProperties.pagesize,
                            showSizeChanger: dataTableProperties.sizechanger,
                            pageSizeOptions: dataTableProperties.pageSizeOptions
                        }}
                    />
                </Modal>
            </div >
        </div >

    )
}
export default connect(mapStateToProps, {
    StatutoryMappingReportFilters,
    StatutoryMappingReportData
})(StatutoryMappingReport);