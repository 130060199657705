import React, { Fragment } from 'react';
import { connect } from 'react-redux';
import { Link } from 'react-router-dom';


const SideNav = () => {
  const sessionValue = JSON.parse(localStorage.getItem('sessionValue'))
  const menu = Object.values(sessionValue.login_response.menu.menus)
  // //const menu = sessionValue.login_response.menu
   //console.log(sessionValue.login_response.menu.menus,'menu')
  return (
    <Fragment>
      <aside className="left-sidebar">
        <div className="scroll-sidebar h-100">
          <nav className="sidebar-nav mt-3">
            <ul id="sidebarnav">
              {menu.length > 0 && menu.map((menuList) => {
                const arr = [];
                menuList.map(function (user) {
                  if ((arr.indexOf(user.parent_menu) < 0))
                    arr.push(user.parent_menu);
                });
                switch(menuList[0].form_type){
                  case 'Dashboard':
                    var featherIcon = 'globe'
                    break;
                  case 'Master':
                    var featherIcon = 'grid'
                    break;
                  case 'Transaction':
                    var featherIcon = 'file-text'
                    break;
                  case 'Report':
                    var featherIcon = 'archive'
                    break;
                  default:
                    var featherIcon = 'list'
                    break;
                }                
                if (menuList[0].form_type !== 'My Accounts' && menuList[0].form_type !== 'Static')                  
                return <li className="sidebar-item">
                  <a
                    className="sidebar-link has-arrow waves-effect waves-dark"
                    href="/#"
                    aria-expanded="false"
                  ><i data-feather={featherIcon}></i>
                    <span className="hide-menu" >{menuList[0].form_type}</span></a>
                  <ul className="collapse first-level">
                    {
                      arr.map((pmenu) => {
                        // const subMenuListVar = (pmenu) ? " has-arrow waves-effect waves-dark" : '';
                        // const isChildTrue = (pmenu) ? "True" : "False";

                        if (pmenu) {
                          return <li className="sidebar-item" title={pmenu}>
                            <Link className={"sidebar-link has-arrow waves-effect waves-dark"}
                            ><i className="ri-indeterminate-circle-line"></i>
                              <span className="hide-menu" >{pmenu} </span></Link>
                            <ul className="collapse first-level">
                              {menuList && menuList.map((subMenuList) => {
                                if (pmenu === subMenuList.parent_menu) {
                                  return <li className="sidebar-item" title={subMenuList.form_name}>
                                    <Link to={subMenuList.form_url} className={"sidebar-link"} onClick={(e) => {
                                      localStorage.setItem('formkey', subMenuList.form_key)
                                    }}
                                    ><i className="ri-indeterminate-circle-line"></i>
                                      <span className="hide-menu" >{subMenuList.form_name} </span></Link>
                                  </li>
                                }
                              })
                              }
                            </ul>
                          </li>

                        } else {
                          return menuList && menuList.map((subMenuList) => {
                            if (subMenuList.parent_menu == null) {
                              return (<li className="sidebar-item" title={subMenuList.form_name}>
                                <Link to={subMenuList.form_url} id="to" className={"sidebar-link"} onClick={(e) => {
                                  localStorage.setItem('formkey', subMenuList.form_key)
                                }}
                                ><i className="ri-indeterminate-circle-line"></i>
                                  <span className="hide-menu" style={{ width: "7rem" }}>{subMenuList.form_name}</span></Link>
                              </li>)
                            }
                          })
                        }
                      })
                    }

                  </ul>
                </li>
              })}
            </ul>
          </nav>
        </div>
      </aside>

    </Fragment>
  )
};
export default connect(null)(SideNav);