import HTTP from '../../Libs/http'
import { Toaster } from '../../Libs/Toaster'
import url from './../../Libs/URL'
import EncryptDecrypt from './../../Libs/Encrypt&Decrypt'
import { encode as base64_encode } from 'base-64';
import { decode as base64_decode } from 'base-64';
import { LOGINSUCCESS, TOGGLE_COMMON_LOADER, NOTIFICATIONS, ESCALATION, REMAINDER, MESSAGE, NOTIFICATIONSHOWALL } from './../types/index';
import alertmessage from './../../Libs/Altermessage'

export const login = ({ payload, navigate, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        const loginpayload =
            [
                "Login",
                {
                    "login_type": "Web",
                    "username": payload.userName,
                    "password": payload.password,
                    "short_name": null,
                    "ip": ""
                }
            ]
        let encoded = base64_encode('7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)');
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Content-Type': 'application/json'
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)')
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, '7v9y/B?E(H+MbQeThHmZq4t7w!z%C&F)'))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UserLoginSuccess') {
                dispatch({
                    type: LOGINSUCCESS,
                    payload: decryptData[1],
                });

                navigate.push('/dashboard');
                window.location.reload();
            } else if (decryptData[0] == 'InvalidCredentials') {
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
                Toaster.error("Invalid Credentials")
                dispatch({
                    type: TOGGLE_COMMON_LOADER
                });
            } else {
                Toaster.error(decryptData[0])
            }
        }


    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};


export const sessionLogout = ({ payload, executeCancel, form_key, form_url }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let token = localStorage.getItem('authToken');
        const loginpayload =
            [
                "Logout",
                {
                    "session_token": token,
                }
            ]
        let encoded = base64_decode(form_key);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Content-Type': 'application/json',
                'Caller-Name': form_url
            },
        }
        const data1 = EncryptDecrypt.encryptdata(loginpayload, encoded)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/login`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'LogoutSuccess') {
                localStorage.clear();
                window.location.reload(true);
            } else {
                Toaster.error("Invalid Error")
            }
        }



    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
}

export const notifications = ({ payload, caller, formkey, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_decode(formkey);
        const head = {
            headers: {
                'Compfie-Info': encoded,
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, encoded)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/general`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetStatutoryNotificationsSuccess') {
                dispatch({
                    type: NOTIFICATIONS,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            }
            else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const Message = ({ payload, caller, formkey, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_decode(formkey);
        const head = {
            headers: {
                'Compfie-Info': formkey,
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, encoded)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/general`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetMessagesSuccess') {
                dispatch({
                    type: MESSAGE,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            }
            else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const notificationshowall = ({ payload, caller, key, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
        let encoded = base64_decode(key);
        const head = {
            headers: {
                'Compfie-Info': key,
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, encoded)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/general`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'GetStatutoryNotificationsSuccess' || decryptData[0] === 'GetMessagesSuccess') {
                dispatch({
                    type: NOTIFICATIONSHOWALL,
                    payload: decryptData[1],
                });
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();

            }
            else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};

export const updatenotification = ({ payload, caller, key, executeCancel }) => async dispatch => {
    try {
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });

        let encoded = base64_decode(key);
        const head = {
            headers: {
                'Compfie-Info': key,
                'Caller-Name': caller,
                'Content-Type': 'application/json'
            },
        }
        const data1 = EncryptDecrypt.encryptdata(payload, encoded)
        const createRequest = HTTP.post({
            url: `${url.Login}/api/general`,
            headers: head,
            executeCancel,
            body: data1

        })
        const executeRequest = await HTTP.execute(createRequest)
        if ((executeRequest && executeRequest.response && executeRequest.response.status === 400) ||
            (executeRequest.status === 400)) {
            Toaster.error("An Error occurred while processing your request, Please contact the Help Desk. (CMPFE310)")
        } else if ((executeRequest && executeRequest.response && executeRequest.response.status === 401 ||
            executeRequest && executeRequest.response && executeRequest.response.status === 503) ||
            (executeRequest.status === 401 || executeRequest.status === 503)) {
            Toaster.error("Service Unavailable")
        } else {
            const response = HTTP.parseResponse(executeRequest)
            const decryptData = JSON.parse(EncryptDecrypt.decryptdata(response, encoded))
            dispatch({
                type: TOGGLE_COMMON_LOADER
            });
            if (decryptData[0] === 'UpdateMessageStatusSuccess') {
                window.location.reload()
            } else if (decryptData[0] === 'InvalidSessionToken') {
                alertmessage.sweetalert();
            }
            else {
                Toaster.error(decryptData[0])
            }
        }
    } catch (err) {
        Toaster.error(err)
        dispatch({
            type: TOGGLE_COMMON_LOADER
        });
    }
};