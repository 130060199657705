import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import { gettaskmapping, getviewtaskmapping, getedittaskmapping, gettaskcategorymappingfilter } from '../../Store/Action/Transactions/Taskcategory';
import Taskcategoryedit from './Taskcategoryedit';
import Taskcategoryadd from './Taskcategoryadd';
import {
    LikeOutlined, DislikeOutlined,
    PlusOutlined, InfoCircleOutlined, StopOutlined, SearchOutlined, PlusCircleOutlined, CloseCircleOutlined
} from '@ant-design/icons';

const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.Taskcategory.gettaskcategorymapping,
    viewlist: state.Taskcategory.getviewtaskcategorymapping.task_edit_compliance_data,

})
const Taskcategory = ({
    gettaskmapping,
    getviewtaskmapping,
    lists,
    viewlist,
    getedittaskmapping,
    gettaskcategorymappingfilter
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filteredData, setFilteredData] = useState({
        act_name: [],
        domain: []

    })
    const [filteredDomain, setFilteredDomain] = useState([]);
    const [filteredAct, setFilteredAct] = useState([]);
    const [filteredArrayState, setfilteredArrayState] = useState([])
    const [finalArray, setFinalArray] = useState([]);
    const [finalArrayNew, setFinalArrayNew] = useState([]);
    console.log(finalArrayNew, 'finalArray');
    const [domainList, setDomainList] = useState([]);
    const validator = useRef(new SimpleReactValidator());
    const [visible, setVisible] = useState(false);
    const [selectedvalue, setSelectedvalue] = useState({
        country: '',
        taskcategory: '',
        tasksubcategory: ''
    })
    const [AddType, setAddType] = useState("list")
    const [showList, setShowList] = useState(false)
    const [datas, setDatas] = useState([])


    useEffect(() => {
        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetTaskMapping",
                    {
                    }
                ]
            }
            gettaskmapping({
                payload: payload,
                caller: paramid,
            })

        }
    }, [isAuth]);

    const getmorerecord = (e) => {
        setVisible(true);
        setSelectedvalue({
            ...selectedvalue,
            country: e.country_name,
            taskcategory: e.task_group_name,
            tasksubcategory: e.task_sub_group_name
        })
        let payload1 = {
            "session_token": authtoken,
            "request": [
                "GetViewTaskMapping",
                {
                    "c_id": e.country_id,
                    "task_group_id": e.task_group_id,
                    "task_sub_group_id": e.task_sub_group_id,
                    "r_count": 0,
                    "page_count": 100,
                    "total_count": 0,
                }
            ]
        }
        getviewtaskmapping({
            payload: payload1,
            caller: paramid,
        })
    }

    const modalcancel = () => {
        setVisible(false);
        setFilteredDomain([]);
        setFilteredAct([])
        setFinalArray([])
    }

    const geteditrecord = (record) => {
        setAddType("edit")
        setShowList(true)
        let payload2 = {
            "session_token": authtoken,
            "request": [
                "GetEditTaskMapping",
                {
                    "c_id": record.country_id,
                    "task_group_id": record.task_group_id,
                    "task_sub_group_id": record.task_sub_group_id,
                    "r_count": 0,
                    "page_count": 100,
                    "total_count": 0,
                }
            ]
        }
        getedittaskmapping({
            payload: payload2,
            caller: paramid,
            country: record.country_name,
            taskcategory: record.task_group_name,
            tasksubcategory: record.task_sub_group_name,
            taskcategoryId: record.task_group_id,
            tasksubcategoryId: record.task_sub_group_id,
            countryId: record.country_id
        })
    }

    const columns = [
        {
            title: '#',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '40px',
            render: (text, record, index) => {
                return (page - 1) * 10 + index + 1
            }
        },
        {
            title: 'Country',
            dataIndex: 'country_name',
            key: 'country_name',
            width: '100px',
            ellipsis: true,
        },
        {
            title: 'Task Category',
            dataIndex: 'task_group_name',
            key: 'task_group_name',
            width: '120px',
            ellipsis: true,
        },
        {
            title: 'Task Sub Category',
            dataIndex: 'task_sub_group_name',
            ellipsis: true,
            key: 'task_sub_group_name',
            width: '200px'
        },
        {
            title: 'View',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '50px',
            render: (text, record) => {
                return (
                    <>
                        {record.view_total == 0 ?
                            <div className='col-md-2' style={{ marginLeft: '25%' }}>
                                <span
                                    className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                >
                                    <Tooltip title="Not Access" color='#fc4b6c'><i className="ri-forbid-line"></i></Tooltip>
                                </span>
                            </div>
                            : <div className='col-md-2' style={{ marginLeft: '25%' }}>
                                <span
                                    className="btn btn-sm btn-light-success
                          text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { getmorerecord(record) }}
                                >
                                    <Tooltip title="Click here to view" color='#39cb7f'><i className="ri-file-2-line"></i></Tooltip>
                                </span>
                            </div>}
                    </>
                )
            }
        },
        {
            title: 'Edit',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            width: '50px',
            render: (text, record) => {
                return (
                    <>
                        {record.total_count == 0 ?
                            <div className='col-md-2' style={{ marginLeft: '25%' }}>
                                <span
                                    className="btn btn-sm btn-light-danger
                              text-danger btn-circle d-flex align-items-center justify-content-center"
                                >
                                    <Tooltip title="Not Access" color='#fc4b6c'><i className="ri-forbid-line"></i></Tooltip>
                                </span>
                            </div>
                            :
                            <div className='col-md-2' style={{ marginLeft: '25%' }}>
                                <span
                                    className="btn btn-sm btn-light-success
                          text-success btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { geteditrecord(record) }}
                                >
                                    <Tooltip title="Click here to Edit" color='#39cb7f'><i className="ri-edit-line"></i></Tooltip>
                                </span>
                            </div>
                        }
                    </>
                )
            }
        },
    ];

    useEffect(() => {
        if (viewlist && viewlist.length > 0) {
            let uniqueObjArray = [...new Map(viewlist && viewlist.length > 0 && viewlist.map((item) => [item["domain_name"], item])).values()];
            console.log(uniqueObjArray, 'uniqueObjArray');
            const uniqueIds = uniqueObjArray && uniqueObjArray.map((item) => {
                return item.domain_name
            })
            let tempArr = []
            for (let i in uniqueIds) {
                let filteredArr = _.filter(viewlist && viewlist.length > 0 && viewlist, { domain_name: uniqueIds[i] })

                tempArr.push(filteredArr)
            }
            console.log(uniqueIds, 'tempArry')
            setFilteredDomain([...filteredDomain, tempArr])
        }
    }, [viewlist])

    useEffect(() => {
        if (filteredDomain.length > 0) {
            let temp = []
            for (let i in filteredDomain) {
                console.log(filteredDomain[i], 'filteredArray[i]');
                temp = filteredDomain[i]
            }
            setfilteredArrayState([...temp])
        }

    }, [filteredDomain])

    useEffect(() => {
        let tempArr = []
        if (viewlist && viewlist && viewlist.length > 0) {
            filteredArrayState && filteredArrayState.length > 0 && filteredArrayState.map((item, i) => {
                let uniqueObjArray = [...new Map(item && item.length > 0 && item.map((items) => [items["act_name"], items])).values()];
                const uniqueIds = uniqueObjArray && uniqueObjArray.map((itemData) => {
                    return itemData.act_name
                })
                console.log(uniqueIds, 'uniqueIds')
                for (let i in uniqueIds) {
                    let filteredArr = _.find(item && item.length && item, { act_name: uniqueIds[i] })
                    tempArr.push(filteredArr)
                }
            })
            setFilteredAct(tempArr)

        }
    }, [filteredArrayState])

    useEffect(() => {
        let array = [];
        let finalarray = [];
        let datavalue = [];
        let countrylisttempdataNew = [];
        if (viewlist && viewlist.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        console.log(filteredAct, 'filteredAct');
                        viewlist && viewlist.length > 0 && viewlist.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "index": childIndex + 1,
                                    "domain_name": child.domain_name,
                                    "domain_id": child.domain_id,
                                    "is_active": child.is_active,
                                    "act_name": child.act_name,
                                    "compliance_id": child.compliance_id,
                                    "statutory_provision": child.statutory_provision,
                                    "c_task_name": child.c_task_name,
                                    "description": child.description,
                                    "parent_names": child.parent_names,
                                    "country_id": child.country_id,
                                    "act_id": child.act_id
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                    countrylisttempdataNew.push(temp)
                });
                var merged = [].concat.apply([], countrylisttempdataNew);
                setFinalArray(finalarray)
                setDatas(merged)
            }
        }
    }, [viewlist, filteredAct])


    const searchRecords = (e) => {
        const filterTable = viewlist.filter(o =>
            Object.keys(o).some(k =>
                String(o[k])
                    .toLowerCase()
                    .includes(e.target.value.toLowerCase())
            )
        );
        let array = [];
        let finalarray = [];
        let datavalue = [];
        if (filterTable && filterTable.length > 0) {
            if (filteredAct && filteredAct.length > 0) {
                const ab = filteredAct && filteredAct.length > 0 && filteredAct.map(function (item) {
                    if ((array.indexOf(item.act_name) < 0))
                        array.push(item.act_name)
                })
                array.map((data, i) => {
                    let temp = [];
                    let entityArray = [];
                    let datavalue = [];
                    if (data) {
                        console.log(filteredAct, 'filteredAct');
                        filterTable && filterTable.length > 0 && filterTable.map((child, childIndex) => {
                            if (data === child.act_name) {
                                entityArray = {
                                    "domain_name": child.domain_name,
                                    "domain_id": child.domain_id,
                                    "is_active": child.is_active,
                                    "act_name": child.act_name,
                                    "compliance_id": child.compliance_id,
                                    "statutory_provision": child.statutory_provision,
                                    "c_task_name": child.c_task_name,
                                    "description": child.description,
                                    "parent_names": child.parent_names,
                                    "country_id": child.country_id,
                                    "act_id": child.act_id
                                }
                                temp.push(entityArray)

                            }
                        })
                    }
                    datavalue = {
                        index: i,
                        parent: data,
                        child: temp
                    }
                    finalarray.push(datavalue)
                });
                setFinalArray(finalarray)
            }
        }else{
            setFinalArray([])
        }
    }

    const addrecord = () => {
        setAddType("add")
        setShowList(true)
        let payload2 = {
            "session_token": authtoken,
            "request": [
                "GetTaskCategoryMappingFilter",
                {
                }
            ]
        }
        gettaskcategorymappingfilter({
            payload: payload2,
            caller: paramid,
        })
    }

    return (
        <>
            {showList == false ? <div className="page-wrapper">
                <div className="page-titles pb-2 pt-2">
                    <div className="row">
                        <div className="col-lg-8 col-md-6 col-12 align-self-center">
                            <nav aria-label="breadcrumb">
                                <ol className="breadcrumb mb-0 d-flex align-items-center">
                                    <li className="breadcrumb-item">
                                        <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                    </li>
                                    <li className="breadcrumb-item active" aria-current="page">
                                        <Link>Transactions</Link>
                                    </li>
                                    <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                        <Link><b>Task Category Mapping</b></Link>
                                    </li>
                                </ol>
                            </nav>
                        </div>
                        <div className="col-lg-4 col-md-6 d-none d-md-flex align-items-center justify-content-end">
                            <div>
                                <Tooltip title="Add New Task" color='#1890ff'><Button type="primary" shape="round" className='addbutton'
                                    icon={<PlusCircleOutlined />} size='default' onClick={addrecord}>
                                    Add
                                </Button></Tooltip></div>

                        </div>
                    </div>
                </div>
                <div className="container-fluid pt-1">
                    <div className="row">
                        <div className="col-12">
                            <div className="card-body" style={{ padding: "15px 5px" }}>
                                <div className="mt-2">
                                    <Table
                                        className='userprivclass'
                                        columns={columns}
                                        dataSource={lists.task_compliance_data && lists.task_compliance_data.length > 0 && lists.task_compliance_data}
                                        bordered
                                        pagination={{
                                            defaultPageSize: dataTableProperties.pagesize,
                                            showSizeChanger: dataTableProperties.sizechanger,
                                            pageSizeOptions: dataTableProperties.pageSizeOptions
                                        }}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <Modal title="Task Category Mapping - View" open={visible} footer={null} maskClosable={false} onCancel={modalcancel}>
                    <Card>
                        <Card type="inner">
                            <div className='row'>
                                <div className='col-lg-3'><b>Country: </b>{selectedvalue.country}</div>
                                <div className='col-lg-4'><b>Task Category: </b>{selectedvalue.taskcategory}</div>
                                <div className='col-lg-5'><b>Task Sub Category: </b>{selectedvalue.tasksubcategory}</div>
                            </div>
                        </Card>
                        <Card type="inner">
                            <div className='col-md-3'>
                                <div className='form-group has-feedback col-sm-12 no-padding' style={{ position: 'relative' }}>
                                    <input className="form-control input-sm" onChange={searchRecords} type="text" id="editsearch" placeholder="Compliance Task" maxlength="50" autocomplete="off" />
                                    <i class="ri-search-line" style={{ position: 'absolute', top: '8px', right: '9px' }}></i>
                                </div>
                            </div>
                        </Card>
                    </Card>

                    <div className='ant-table-wrapper vertical-scroll'>
                        {filteredArrayState && filteredArrayState.length > 0 ? filteredArrayState.map((item, i) => {
                            return (
                                <Collapse defaultActiveKey={['0']}>
                                    <Panel header={item[0].domain_name}>
                                        <div className='ant-table ant-table-bordered ant-table-fixed-header'>
                                            <div className='ant-table-container'>
                                                <div className='ant-table-content fh userprivclass1'>



                                                    <table className='widthHeigh table-fixed'>
                                                        <colgroup>
                                                            <col style={{ width: '10px' }}></col>
                                                        </colgroup>
                                                        <thead className='ant-table-thead'>
                                                            <tr>
                                                                <th className='ant-table-cell' style={{ width: '10px' }}>#</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Statutory Provision</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Task</th>
                                                                <th className='ant-table-cell' style={{ width: '100px' }}>Compliance Description</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className='ant-table-tbody'>
                                                            <>
                                                                {
                                                                    finalArray && finalArray.length > 0  ?
                                                                     finalArray.map((itemdata, i) => {
                                                                        let data = _.find(itemdata.child, { domain_name: item[0].domain_name })
                                                                        if (data != undefined) {
                                                                            if (data.act_name == itemdata.parent) {
                                                                                return <>
                                                                                    <tr className='ant-table-row ant-table-row-level-0'>
                                                                                        <td colSpan={4} className='ant-table-cell'><b>{itemdata.parent}</b></td>
                                                                                    </tr>
                                                                                    {itemdata.child.map((items, j) => {
                                                                                        return <>
                                                                                            <tr className='ant-table-row ant-table-row-level-0'>
                                                                                                <td className='ant-table-cell'>{items.index}</td>
                                                                                                <td title={items.statutory_provision} className='ant-table-cell ellipsis ant-table-cell-with-append'>
                                                                                                    {items.statutory_provision}</td>
                                                                                                <td title={items.c_task_name} className='ant-table-cell ellipsis'>{items.c_task_name}</td>
                                                                                                <td title={items.description} className='ant-table-cell ellipsis'>{items.description}</td>
                                                                                            </tr>
                                                                                        </>
                                                                                    })}

                                                                                </>
                                                                            }
                                                                        }


                                                                    }) : <tr>
                                                                   
                                                                    <td  colSpan={4} style={{ paddingTop: '15px', textAlign: 'center', fontSize: '14px', fontWeight: 'bold', paddingBottom: '15px' }}>No Records Found</td>
                                                                </tr>
                                                                }


                                                            </>




                                                        </tbody>
                                                    </table>


                                                </div>
                                            </div>
                                        </div>
                                    </Panel>
                                </Collapse>
                            )
                        }) : ''}
                    </div>
                    {
                        datas && datas.length > 0 ?
    
                    'Showing 1 to  '+ datas.length + '  of  ' +datas.length+ ' entries':'' }
                </Modal >


            </div > : AddType == 'add' ? <Taskcategoryadd setAddType={setAddType} showList={showList} setShowList={setShowList} AddType={AddType} /> : <Taskcategoryedit setAddType={setAddType} showList={showList} setShowList={setShowList} AddType={AddType} />

            }
        </>
    )
}
export default connect(mapStateToProps, {
    gettaskmapping,
    getviewtaskmapping,
    getedittaskmapping,
    gettaskcategorymappingfilter
})(Taskcategory);