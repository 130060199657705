import HTTP from '../../../Libs/http';
import { MAPPING_LIST,MAPPING_INFO,MAPPINTMASTER,FILEUPLOADSUCCESS,FETCHINGEDITDETAILS,
    FILEUPLOADSUCCESSNOTIFICATION,STATUTORY_MAPPING_HISTORY } from '../../types/index'

const initialState = {
    statutorymap: {
        list: '',
        info:'',
        master:'',
        filedetails:'',
        fetcheditdetails: '',
        filedetailsnotification: '',
        historydata :''
    }
}

export default function (state = initialState, action) {
    const { type, payload } = action;

    switch (type) {
        case MAPPING_LIST: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    list: payload,
                },
            };
        }
        case MAPPING_INFO: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    info: payload,
                },
            };
        }
        case MAPPINTMASTER: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    master: payload,
                },
            };
        }
        case FILEUPLOADSUCCESS: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    filedetails: payload,
                },
            };
        }
        case FETCHINGEDITDETAILS: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    fetcheditdetails: payload,
                },
            }
        }
        case FILEUPLOADSUCCESSNOTIFICATION: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    filedetailsnotification: payload,
                },
            };
        }
        case STATUTORY_MAPPING_HISTORY: {
            return {
                ...state,
                statutorymap: {
                    ...state.statutorymap,
                    historydata: payload,
                },
            };
        }
        default:
            return state;
    }
}