import { Fragment, useState, useEffect, useRef, useMemo } from 'react';
import { connect } from 'react-redux';
import { useLocation, Link } from 'react-router-dom'
import { Collapse, Select, Input, DatePicker, Card, Pagination, Drawer, Button, Table, Tooltip, Modal } from 'antd';
import SimpleReactValidator from 'simple-react-validator';
import {
    getusers, getrejectedlist, getbuconstant, getdownloadcount, getreportdata,
    getdownloadstatus, removefile
} from '../../Store/Action/Transactions/Rejectedsummary';
import { CloseCircleOutlined } from '@ant-design/icons';
const _ = require("lodash");

const mapStateToProps = (state) => ({
    lists: state.Rejectedsummary.Rejectedsummary.getusers,
    domain: state.Rejectedsummary.Rejectedsummary.domains,
    rejectedlists: state.Rejectedsummary.Rejectedsummary.rejectedlist,
    rejectedfilename: state.Rejectedsummary.Rejectedsummary.rejectedfilename,
    downloadlink: state.Rejectedsummary.Rejectedsummary.downloadlink

})
const Rejectedsummary = ({
    getusers,
    getbuconstant,
    getrejectedlist,
    rejectedlists,
    lists,
    domain,
    getdownloadcount,
    getreportdata,
    rejectedfilename,
    getdownloadstatus,
    downloadlink,
    removefile
}) => {
    const location = useLocation();
    const paramid = location.pathname;
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const formValidator = useRef(new SimpleReactValidator());
    const formValidator1 = useRef(new SimpleReactValidator());
    // const [remark, setRemark] = useState()
    const [AddFormSubmit, setAddFormSubmit] = useState(false)
    const [AddFormSubmit1, setAddFormSubmit1] = useState(false);
    const [modalVisible, setModaVisible] = useState(false)
    const [filterText, setFilterText] = useState("");
    const [dataTableProperties, setDataTableProperties] = useState({
        fixedHeader: true,
        pagesize: 10,
        sizechanger: true
    })
    const [page, setPage] = useState(1);
    const { Search } = Input;
    const legalid = localStorage.getItem('SelectedEntityid');
    const callername = JSON.parse(localStorage.getItem('sessionValue'));
    var type = '';
    const { Panel } = Collapse;
    const { Option } = Select;
    const [filteredData, setFilteredData] = useState({
        country: "",
        domain: []

    })
    const [domainList, setDomainList] = useState([]);
    const validator = useRef(new SimpleReactValidator());
    const [visible, setVisible] = useState(false);
    const [fileformat, setfileformat] = useState();
    const [removefileid, setremovefileid] = useState();
    const [passwordmodal, setpasswordmodal] = useState(false);
    const [passwordata, Setpassword] = useState({
        passvalue: '',
    })


    useEffect(() => {
        validator.current.showMessages()
        if (isAuth) {
            let payload = {
                "session_token": authtoken,
                "request": [
                    "GetUsers",
                    {
                    }
                ]
            }

            let payloadbuconstant = {
                "session_token": authtoken,
                "request": [
                    "GetBulkUploadConstants",
                    {
                    }
                ]
            }

            getusers({
                payload: payload,
                caller: paramid,
            })

            getbuconstant({
                payload: payloadbuconstant,
                caller: paramid,
            })
        }
    }, [isAuth]);

    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (filteredData.country) {
            domain && domain.length > 0 && domain.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(filteredData.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name
                    }
                    domainArray.push(tempArray)
                }
            })
            setDomainList(domainArray)
        }
    }, [filteredData.country])

    const show = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setVisible(true)
            let payloadaction = {
                "session_token": authtoken,
                "request": [
                    "GetRejectedSMBulkUploadData",
                    {
                        "c_id": parseInt(filteredData.country),
                        "d_id": parseInt(filteredData.domain)
                    }
                ]
            }

            getrejectedlist({
                payload: payloadaction,
                caller: paramid,
            })
        }
    }
    const [paginationSize, setPaginationSize] = useState(25);

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '50px',
            ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * paginationSize + index + 1
            }
        },
        {
            title: 'Uploaded File Name',
            dataIndex: 'csv_name_text',
            key: 'csv_name_text',
            ellipsis: true,
            width: '170px'
        },
        {
            title: 'Rejected on',
            dataIndex: "rejected_on",
            key: "rejected_on",
            align: 'center',
        },
        {
            title: 'Rejected By',
            dataIndex: "rejected_by",
            key: "rejected_by",
            align: 'center',
        },
        {
            title: 'No. of Records',
            dataIndex: 'total_records',
            key: 'total_records',
            align: 'center',
        },
        {
            title: 'Declined Count',
            dataIndex: 'declined_count',
            key: 'declined_count',
            align: 'center',
            render: (text, record) => {
                if (text == null) {
                    return '-'
                } else {
                    return text
                }
            }
        },
        {
            title: 'Rejected File',
            dataIndex: false,
            key: false,
            ellipsis: true,
            align: 'center',
            render: (text, record) => {
                return (
                    <div>
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('excel', record.csv_id) }}>Download Excel</span><br />
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('csv', record.csv_id) }}>Download CSV</span><br />
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('ods', record.csv_id) }}>Download ODS</span><br />
                        <span className='pointer' style={{ color: 'blue' }} onClick={(e) => { downloadfile('text', record.csv_id) }}>Download Text</span>
                    </div>
                )
            }
        },
        {
            title: 'Reason for Rejection',
            dataIndex: 'rejected_reason',
            key: 'rejected_reason',
            align: 'center',
        },
        {
            title: 'Remove',
            dataIndex: 'file_download_count',
            key: 'file_download_count',
            render: (text, record) => {
                return (
                    <Fragment>
                        <div className='row'>
                            <div className='col-md-3'>
                                {text != 0 ?
                                    <div className='col-md-2'>
                                        <span
                                            className="btn btn-sm btn-light-danger
                      text-danger btn-circle d-flex align-items-center justify-content-center" onClick={(e) => { rejectedfile(record) }}>
                                            <Tooltip title="Active" color='#fc4b6c'><CloseCircleOutlined /></Tooltip>
                                        </span>
                                    </div> : '-'}
                            </div>
                        </div>
                    </Fragment>
                )
            }
        }
    ];

    const downloadfile = (e, id) => {
        setfileformat(e);
        const downpay = {
            "session_token": authtoken,
            "request": [
                "UpdateDownloadCountToRejectedStatutory",
                {
                    "csv_id": id
                }
            ]
        }
        const filepay = {
            "session_token": authtoken,
            "request": [
                "DownloadRejectedSMReportData",
                {
                    "csv_id": id,
                    "c_id": parseInt(filteredData.country),
                    "d_id": parseInt(filteredData.domain),
                    "download_format": e
                }
            ]
        }

        getdownloadcount({
            payload: downpay,
            caller: paramid
        })

        getreportdata({
            payload: filepay,
            caller: paramid
        })

    }

    useEffect(() => {
        if (rejectedfilename) {
            const pay = {
                "session_token": authtoken,
                "request": [
                    "GetRejDownloadStatus",
                    {
                        "csv_name": rejectedfilename.csv_name
                    }
                ]
            }

            getdownloadstatus({
                payload: pay,
                caller: paramid
            })
        }
    }, [rejectedfilename])

    useEffect(() => {
        if (downloadlink) {
            if (fileformat == 'csv') {
                window.open('https://13.234.100.64' + downloadlink.csv_link);
            } else if (fileformat == 'excel') {
                window.open('https://13.234.100.64' + downloadlink.xlsx_link);
            } else if (fileformat == 'ods') {
                window.open('https://13.234.100.64' + downloadlink.ods_link);
            } else {
                window.open('https://13.234.100.64' + downloadlink.txt_link);
            }
        }
    }, [downloadlink])

    const rejectedfile = (record) => {
        const id = record.csv_id;
        setremovefileid(id);
        setpasswordmodal(true);
    }

    const passwordcancel = () => {
        setpasswordmodal(false);
        Setpassword({
            ...passwordata,
            passvalue: ""

        })
    }

    const submitpassword = () => {
        const passpay = {
            "session_token": authtoken,
            "request": [
                "VerifyPassword",
                {
                    "password": passwordata.passvalue
                }
            ]
        }

        const filepay = {
            "session_token": authtoken,
            "request": [
                "DeleteRejectedSMCsvId",
                {
                    "d_id": parseInt(filteredData.domain),
                    "csv_id": parseInt(removefileid),
                    "c_id": parseInt(filteredData.country)
                }
            ]
        }

        removefile({
            payload: passpay,
            caller: paramid,
            filepayload: filepay
        })
    }

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Transactions</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link>Rejected Statutory Mapping - Bulk Upload</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]}>
                                <Panel header="Rejected Statutory Mapping - Bulk Upload" key={"1"}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><b>Country </b><span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Country"
                                                        style={{ width: '100%' }}
                                                        onChange={(data, value) => {
                                                            setFilteredData({
                                                                ...filteredData,
                                                                country: data,
                                                            })

                                                        }
                                                        }
                                                    >
                                                        {lists && lists.length > 0 && lists.map((list) => {
                                                            return (
                                                                <Option key={list.country_id}>
                                                                    {list.country_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {validator.current.message(
                                                        'country',
                                                        filteredData.country,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label><b>Domain </b><span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <Select
                                                        allowClear={false}
                                                        size="default"
                                                        placeholder="Enter Domain"
                                                        style={{ width: '100%' }}
                                                        onChange={(data, value) => {
                                                            setFilteredData({
                                                                ...filteredData,
                                                                domain: data,
                                                            })

                                                        }
                                                        }
                                                    >
                                                        {domainList && domainList.length > 0 && domainList.map((listdomain) => {
                                                            return (
                                                                <Option key={listdomain.domain_id}>
                                                                    {listdomain.domain_name}
                                                                </Option>
                                                            );
                                                        })}

                                                    </Select>
                                                    {validator.current.message(
                                                        'domain',
                                                        filteredData.domain,
                                                        'required',
                                                        {
                                                            className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                            messages: {
                                                                required: 'Required Field Missing',
                                                            }
                                                        })}
                                                </div>
                                            </div>
                                            <br />
                                            <div className='col-md-6' style={{ marginTop: "20px", marginLeft: "25%", textAlign: 'center' }}>
                                                <Button type="primary" shape="round" className='addbutton' style={{ background: "#0096FF", borderColor: "#0096FF" }} onClick={show}>
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            {visible == true ?
                                <div className="mt-2">
                                    <Table
                                        size={'small'}
                                        columns={columns}
                                        dataSource={rejectedlists}
                                        bordered
                                        pagination={false}
                                    // scroll={{ x: 1500, y: 1000 }}
                                    />
                                </div>
                                : ''}
                        </div>
                    </div>
                </div>
            </div>
            <Modal visible={passwordmodal} footer={null} onCancel={passwordcancel} className="newStyleModalPassword">
                <div className="row">
                    <div className="col-md-12">
                        <div className="mb-6">
                            <label>Enter Password</label>
                            <span style={{ "color": "red" }}>*</span>
                            <input
                                type="password"
                                id="password"
                                name='passwordvalue'
                                className="form-control"
                                placeholder="Enter password"
                                onChange={(e) => {
                                    Setpassword({
                                        ...passwordata,
                                        passvalue: e.target.value

                                    })
                                }}
                                value={passwordata.passvalue}
                            />
                            {formValidator1.current.message(
                                'Password',
                                passwordata.passvalue,
                                ['required'],
                                {
                                    className: `invalid-feedback ${AddFormSubmit1 ? 'show' : 'hide'}`,
                                    messages: {
                                        regex: 'Enter Password'
                                    }
                                })}
                        </div>
                    </div>

                </div>
                <br />
                <div className="form-actions">
                    <button
                        className="btn btn-light-success rounded-pill px-4 text-success"
                        onClick={submitpassword}
                    >Submit
                    </button>
                    <button
                        className="btn btn-light-danger rounded-pill px-4 ms-2 text-danger waves-effect text-start"
                        onClick={passwordcancel}
                    >Cancel
                    </button>
                </div>
            </Modal>
        </div>

    )
}
export default connect(mapStateToProps, {
    getusers,
    getrejectedlist,
    getbuconstant,
    getdownloadcount,
    getreportdata,
    getdownloadstatus,
    removefile
})(Rejectedsummary);