import React, { Fragment } from 'react'
import { connect } from 'react-redux';
import { Collapse, Select, DatePicker, Button, Table, Pagination, Tooltip, Card } from 'antd';
import moment from 'moment';
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";
import { ExclamationCircleTwoTone } from '@ant-design/icons';
import { toast } from 'react-toastify';
import { StatutoryNotificationsFilters, StatutoryNotificationsReportData } from './../../../src/Store/Action/Reports/StatutoryNotificationList';

const mapStateToProps = (state) => ({
    statutorynotification: state.StatutoryNotificationList.statutorynotificationlist,
})

const StatutoryNotificationsList = ({
    StatutoryNotificationsFilters,
    StatutoryNotificationsReportData,
    statutorynotification: { statutorylist, statutorylistreportdata }
}) => {
    const location = useLocation();
    const isAuth = localStorage.getItem('isAuthenticated');
    const authtoken = localStorage.getItem('authToken');
    const sessionValue = localStorage.getItem('sessionValue')
    const sessionParsedValue = JSON.parse(sessionValue)
    const [pageState, setpageState] = useState(false);
    const paramid = location.pathname;
    const _ = require("lodash");
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [legalstate, Setlegalstate] = useState(false)
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    let [count, setcount] = useState(0)
    const [filteredFields, setFilteredFields] = useState({
        filteredDomain: [],
        filteredAct: []
    })
    const [unitdata, setUnitData] = useState({
        country: '',
        countryName: '',
        domain: '',
        domain_name: '',
        act: '',
        act_name: '',
        from_date: "",
        to_date: "",
    })

    const validator = useRef(new SimpleReactValidator());
    const [pageSize, setPageSize] = useState(25)
    const [current, setCurrent] = useState(1)
    const [paginationArray, setPaginationArray] = useState([])
    const { Panel } = Collapse;
    const { Option } = Select;
    const [page, setPage] = useState(1);
    const [domainName, setDomainName] = useState([]);

    const columns = [
        {
            title: "#",
            dataIndex: false,
            key: false,
            width: '20px',
            ellipsis: true,
            render: (text, record, index) => {
                return (page - 1) * pageSize + index + 1
            }
        },
        {
            title: "Act",
            dataIndex: 'statutory_name',
            width: '60px',
            ellipsis: true
        },
        {
            title: "Complaince Task",
            dataIndex: false,
            key: false,
            width: '70px',
            // ellipsis: true
            render: (record) => {
                return (
                    <>
                        <Tooltip title={record.description}>
                            <ExclamationCircleTwoTone />
                        </Tooltip> &nbsp;
                        {record.c_task}
                    </>
                )
            },
        },
        {
            title: "Date",
            dataIndex: 'notification_date',
            width: '40px',
            ellipsis: true
        },
        {
            title: "Notifications content",
            dataIndex: 'notification_text',
            width: '100px',
            ellipsis: true
        }
    ]

    useEffect(() => {
        if (isAuth) {
            StatutoryNotificationsFilters({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsFilters",
                        {}
                    ]
                }
                ,
                paramid: paramid
            })
        }
    }, [isAuth, legalstate])


    useEffect(() => {
        let domainArray = [];
        let tempArray = [];
        if (unitdata.country) {
            statutorylist.domains && statutorylist.domains.length > 0 && statutorylist.domains.map((domainList) => {
                const checkDomainExists = domainList.country_ids.includes(parseInt(unitdata.country));
                if (checkDomainExists) {
                    tempArray = {
                        'domain_id': domainList.domain_id,
                        'domain_name': domainList.domain_name,
                        'product': domainList.product,
                        'is_cmpf': domainList.is_cmpf,
                        'is_ltgs': domainList.is_ltgs,
                        'is_vndr': domainList.is_vndr,
                        'is_active': domainList.is_active
                    }
                    domainArray.push(tempArray)
                }
            })
            console.log(domainArray, 'domainArray')
            setFilteredFields({
                ...filteredFields,
                filteredDomain: domainArray
            })
        }
    }, [unitdata.country])

    useEffect(() => {
        let act = _.filter(statutorylist.level_one_statutories, { country_id: parseInt(unitdata.country), domain_id: parseInt(unitdata.domain) })
        setFilteredFields({
            ...filteredFields,
            filteredAct: act

        })
    }, [unitdata.domain])

    useEffect(() => {
        validator.current.showMessages()
    }, [])

    useEffect(() => {
        if (statutorylistreportdata && statutorylistreportdata.statutory_notifictions_list && statutorylistreportdata.statutory_notifictions_list.length > 0) {
            var array = statutorylistreportdata.statutory_notifictions_list.slice((current - 1) * pageSize, (current * pageSize))
            setPaginationArray(array)
        }
    }, [statutorylistreportdata.statutory_notifictions_list, pageSize, current])

    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            let domName = _.find(statutorylist.domains && statutorylist.domains.length > 0 && statutorylist.domains, { domain_id: parseInt(unitdata.domain) })
            setDomainName(domName.domain_name);
            setTableShow(true)
            setKey(0)
            StatutoryNotificationsReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsReportData",
                        {
                            "country_id": Number(unitdata.country),
                            "domain_id": Number(unitdata.domain),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "from_date_optional": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
                            "to_date_optional": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
                            "from_count": 0,
                            "page_count": 25
                        }
                    ]
                },
                paramid: paramid
            })

        }
    }

    useEffect(() => {
        if (pageState == true) {
            StatutoryNotificationsReportData({
                payload:
                {
                    "session_token": authtoken,
                    "request": [
                        "GetStatutoryNotificationsReportData",
                        {
                            "country_id": Number(unitdata.country),
                            "domain_id": Number(unitdata.domain),
                            "statutory_id_optional": Number(unitdata.act) == 0 ? null : Number(unitdata.act),
                            "from_date_optional": unitdata.from_date ? moment(new Date(unitdata.from_date)).format('DD-MMM-YYYY') : null,
                            "to_date_optional": unitdata.to_date ? moment(new Date(unitdata.to_date)).format('DD-MMM-YYYY') : null,
                            "from_count": Math.max(((current - 1) * pageSize), 0),
                            "page_count": pageSize
                        }
                    ]
                },
                paramid: paramid
            })
        }
    }, [current, pageSize, pageState])

    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Report</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link>Statutory Notification List</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card-body" style={{ padding: "15px 5px" }}>
                            <Collapse defaultActiveKey={["1"]} >
                                <Panel header="Statutory Notification List" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label><b>Country</b> <span style={{ color: "red" }}>*</span></label>
                                                <div className="form-group">
                                                    <div className="form-group">
                                                        <Select
                                                            allowClear={false}
                                                            size="default"
                                                            onChange={(value, data) => {
                                                                setUnitData({
                                                                    ...unitdata,
                                                                    country: value,
                                                                    countryName: data.children,
                                                                    domain: '',
                                                                    domain_name: '',
                                                                    act: '',
                                                                    act_name: ''
                                                                })
                                                            }}
                                                            showSearch
                                                            filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                            style={{ width: '100%' }}
                                                            placeholder="Enter Country"
                                                            value={unitdata.countryName || undefined}
                                                        >
                                                            {statutorylist.countries && statutorylist.countries.length && statutorylist.countries.map((item, i) => {
                                                                return (
                                                                    <Option key={item.country_id}>
                                                                        {item.country_name}
                                                                    </Option>
                                                                );
                                                            })}
                                                        </Select>
                                                        {validator.current.message(
                                                            'country',
                                                            unitdata.country,
                                                            'required',
                                                            {
                                                                className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                                messages: {
                                                                    required: 'Please Enter Country',
                                                                }
                                                            })}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>From Date <span ></span></label>
                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} style={{ width: '100%' }} value={unitdata.from_date ? moment(unitdata.from_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            from_date: dateString,
                                                            to_date: ''
                                                        })
                                                    }}
                                                    renderExtraFooter={() => {
                                                        return <Link style={{ float: 'right' }} onClick={(e) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                from_date: '',
                                                                to_date: ''
                                                            })
                                                        }}>Clear</Link>
                                                    }}
                                                    showToday={false}
                                                    
                                                    
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Domain <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    allowClear={false}
                                                    size="default"
                                                    showSearch
                                                    filterOption={(input, option) => option.children.toLowerCase().includes(input.toLowerCase())}
                                                    value={unitdata.domain_name || undefined}
                                                    disabled={unitdata.country == 0 ? true : false}

                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            domain: value,
                                                            domain_name: data.children,
                                                            act: '',
                                                            act_name: ''
                                                        })
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '100%' }}
                                                    
                                                >
                                                    {filteredFields.filteredDomain && filteredFields.filteredDomain.length > 0 && filteredFields.filteredDomain.map((item, i) => {
                                                        return (
                                                            <Option key={item.domain_id}>
                                                                {item.domain_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    unitdata.domain_name,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Please Enter Domain',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-6'>
                                                <label><b>To Date</b></label>
                                                <DatePicker allowClear={false} format={'DD-MMM-YYYY'} style={{ width: '100%' }} value={unitdata.to_date ? moment(unitdata.to_date) : ''}
                                                    onChange={(date, dateString) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            to_date: dateString
                                                        })
                                                    }}
                                                    disabledDate={(current) => {
                                                        return moment(unitdata.from_date).add(0, 'days') >= current
                                                    }}
                                                    renderExtraFooter={() => {
                                                        return <Link style={{ float: 'right' }} onClick={(e) => {
                                                            setUnitData({
                                                                ...unitdata,
                                                                to_date: ''
                                                            })
                                                        }}>Clear</Link>
                                                    }}
                                                    showToday={false}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <label style={{ "fontWeight": "bold" }}>Act</label>
                                                <Select
                                                    value={unitdata.act_name || undefined}
                                                    disabled={unitdata.domain == 0 ? true : false}
                                                    onChange={(value, data) => {
                                                        setUnitData({
                                                            ...unitdata,
                                                            act: value,
                                                            act_name: data.children
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '100%' }}
                                                >
                                                    {filteredFields.filteredAct && filteredFields.filteredAct.length > 0 && filteredFields.filteredAct.map((item, i) => {
                                                        return (
                                                            <Option key={item.level_1_statutory_id}>
                                                                {item.level_1_statutory_name}
                                                            </Option>
                                                        )
                                                    })}
                                                </Select>
                                            </div>
                                            <div className='col-md-6'>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "15px", textAlign: 'center', marginLeft: '26%' }}>
                                        <div className='row'>
                                            <div className='col-md-6'>
                                                <Button type="primary" shape="round" className='addbutton'
                                                    style={{ background: "#0096FF", borderColor: "#0096FF" }}
                                                    onClick={onshow}
                                                >
                                                    Show
                                                </Button>
                                            </div>
                                        </div>
                                    </div>
                                </Panel>
                            </Collapse>
                            <br />
                            {tableshow == true ?
                                <>
                                    <Card>
                                        <div className='row'>
                                            <div className='col-lg-6'><b>Country</b> : {unitdata.countryName}</div>
                                            <div className='col-lg-6'><b>Domain</b> : {domainName}</div>
                                        </div>
                                    </Card>
                                    <Table size={'small'}
                                        columns={columns}
                                        bordered
                                        scroll={{ y: 400 }}
                                        dataSource={statutorylistreportdata.statutory_notifictions_list}
                                        pagination={false}
                                    />
                                    <div className='col-lg-12'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                Showing {(current - 1) * pageSize + 1} to {current * pageSize} of {statutorylistreportdata.total_count} entries
                                            </div>
                                            <div className='col-lg-6'>
                                                <Pagination style={{ "margin-top": "10px", float: " RIGHT" }}
                                                    current={current}
                                                    showSizeChanger={true}
                                                    showQuickJumper={false}
                                                    onShowSizeChange={() => {
                                                        setCurrent(1)
                                                    }}
                                                    pageSizeOptions={[25, 50, 100]}
                                                    hideOnSinglePage={true}
                                                    defaultPageSize={25}
                                                    onChange={(page, pageSizes) => {
                                                        setpageState(true)
                                                        setCurrent(pageSize !== pageSizes ? 1 : page);
                                                        setPageSize(pageSizes)
                                                        setPage(page);
                                                    }} total={statutorylistreportdata.total_count} />
                                            </div>
                                        </div>
                                    </div>
                                </> : false}
                        </div>
                    </div>
                </div>
            </div >
        </div>

    )
}
export default connect(mapStateToProps, {
    StatutoryNotificationsFilters,
    StatutoryNotificationsReportData
})(StatutoryNotificationsList);