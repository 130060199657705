import { ConsoleSqlOutlined, ContactsFilled } from '@ant-design/icons';
import HTTP from '../../../Libs/http';
import {APPROVE_BULK_UPLOAD,APPROVE_BULK_UPLOAD_EXE,BULK_UPLOAD_LIST,BULK_UPLOAD_RESPONSE,
    BULK_REJECT_RESPONSE,BULK_UPLOAD_VIEW,BULK_UPLOAD_FILTER,BULK_UPLOAD_APPROVE,BULK_UPLOAD_REJECT} from '../../types/index'

const initialState = {
    approvebulkupload: {
        domaindetails: [],
        exedetails: [],
        list:[],
        ressuccess:[],
        rejectresponse:[],
        viewdata:[],
        filterdata:[],
        approve:[],
        reject:[]
    }
}
export default function(state = initialState, action) {
    const { type, payload } = action;
    switch (type) {
        case APPROVE_BULK_UPLOAD:
            {
                return {
                    ...state,
                    approvebulkupload: {
                        ...state.approvebulkupload,
                        domaindetails: payload,
                    },
                };
            }
        case APPROVE_BULK_UPLOAD_EXE:
            {
                return {
                    ...state,
                    approvebulkupload: {
                        ...state.approvebulkupload,
                        exedetails: payload,
                    },
                };
            }
            case BULK_UPLOAD_LIST:
            {
                return {
                    ...state,
                    approvebulkupload: {
                        ...state.approvebulkupload,
                        list: payload,
                    },
                };
            }
            case BULK_UPLOAD_RESPONSE:
            {
                return {
                    ...state,
                    approvebulkupload: {
                        ...state.approvebulkupload,
                        ressuccess: payload,
                    },
                };
            }
            case BULK_REJECT_RESPONSE:
                {
                    return {
                        ...state,
                        approvebulkupload: {
                            ...state.approvebulkupload,
                            rejectresponse: payload,
                        },
                    };
                }
                case BULK_UPLOAD_VIEW:
                {
                    return {
                        ...state,
                        approvebulkupload: {
                            ...state.approvebulkupload,
                            viewdata: payload,
                        },
                    };
                }

                case BULK_UPLOAD_FILTER:
                {
                    return {
                        ...state,
                        approvebulkupload: {
                            ...state.approvebulkupload,
                            filterdata: payload,
                        },
                    };
                }

        default:
            return state;
    }
}

         