import React from 'react'
import { Collapse, Select, Input, DatePicker, Card, Modal } from 'antd';
import DataTable from "react-data-table-component";
import { useState, useRef, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
import SimpleReactValidator from "simple-react-validator";

const DomainReport = (() => {
    const [AddFormSubmit, setAddFormSubmit] = useState(false);
    const [tableshow, setTableShow] = useState(false)
    const [key, setKey] = useState("1")
    const [consolidateddata, setConsolidatedDate] = useState({
        country: "",
        category: "",
        user_type: "",
        legel_entity: "",
        unit: '',
        user: "",
        domain: "",
        act: "",
        from_date: "",
        task_category: "",
        compliance_task: "",
        to_date: "",
        task_sub_category: "",
        compliance_frequency: "",
        compliance_task_status: "",
        division: ""

    })
    const validator = useRef(new SimpleReactValidator());
    const [isModalOpen, setIsModalOpen] = useState(false);
    const { Panel } = Collapse;
    const { Option } = Select;
    const onshow = () => {
        setAddFormSubmit(true)
        if (validator.current.allValid()) {
            setTableShow(true)
            setKey(0)
        }
    }
    console.log(isModalOpen, "isModalOpen");
    const columns = [
        {

            name: "Compliance Task",
            key: "compliance",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '200px',

        },
        {

            name: "Frequency",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '150px',

        },
        {

            name: "Due Date",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '150px',

        },
        {

            name: "Compliance Task Status",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '250px',

        },
        {

            name: "User Name",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '150px',

        },
        {

            name: "Activity Status",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '200px',

        },
        {

            name: "Activity Date",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '150px',

        },
        {

            name: "Uploaded Document",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '200px',

        },
        {

            name: "Task Completion Date/Document issued Date",
            // selector: (row) => row && row.comp_name,
            sortable: true,
            reorder: true,
            width: '400px',

        },
    ]

    const showModal = () => {
        console.log("hiiii");
        setIsModalOpen(true);
    };

    const handleOk = () => {
        setIsModalOpen(false);
    };

    const handleCancel = () => {
        setIsModalOpen(false);
    };

    const item = [
        {
            compliance: "manoj"
        }
    ]
    const onChange = (key) => {
        console.log(key);
    };
    useEffect(() => {
        validator.current.showMessages()
    }, [])
    console.log(consolidateddata, "consolidateddata");
    return (
        <div className="page-wrapper">
            <div className="page-titles pb-2 pt-2">
                <div className="row">
                    <div className="col-lg-8 col-md-6 col-12 align-self-center">
                        <nav aria-label="breadcrumb">
                            <ol className="breadcrumb mb-0 d-flex align-items-center">
                                <li className="breadcrumb-item">
                                    <Link to="/dashboard"><i className="ri-home-3-line fs-5"></i></Link>
                                </li>
                                <li className="breadcrumb-item active" aria-current="page">
                                    <Link>Report</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link>Compliance Report</Link>
                                </li>
                                <li className="breadcrumb-item active breadcrumsheader" aria-current="page">
                                    <Link>Domain Report</Link>
                                </li>
                            </ol>
                        </nav>
                    </div>
                </div>
            </div>
            <Modal title="Basic Modal" visible={isModalOpen} onOk={handleOk} onCancel={handleCancel} >
                <p>Some contents...</p>
                <p>Some contents...</p>
                <p>Some contents...</p>
            </Modal>
            <div className="container-fluid pt-1">
                <div className="row">
                    <div className="col-12">
                        <div className="card">
                            <Collapse defaultActiveKey={["1"]} onChange={onChange} >
                                <Panel header=" Domain Report" key={key}>
                                    <div className='col-md-12'>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Country <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            country: value
                                                        })
                                                    }}
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                                {validator.current.message(
                                                    'country',
                                                    consolidateddata.country,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Unit</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            unit: value
                                                        })
                                                    }}
                                                    placeholder="Enter Unit"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>User</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            user: value
                                                        })
                                                    }}
                                                    placeholder="Enter User"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="All">All</Option>

                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Legal Entity <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            legel_entity: value
                                                        })
                                                    }}
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                                {validator.current.message(
                                                    'legalentity',
                                                    consolidateddata.legel_entity,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Act</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            act: value
                                                        })
                                                    }}
                                                    placeholder="Enter Act"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>From Date <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker style={{ width: '280px' }}
                                                    onChange={(date, dateString) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            from_date: dateString
                                                        })
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'from_date',
                                                    consolidateddata.from_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Domain <span style={{ color: "red" }}>*</span></label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            domain: value
                                                        })
                                                    }}
                                                    placeholder="Enter Domain Name"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                                {validator.current.message(
                                                    'domain',
                                                    consolidateddata.domain,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Compliance Task</label>
                                                <Input placeholder="Enter Compliance Task" style={{ width: '280px' }}
                                                    onChange={(e) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_task: e.target.value
                                                        })
                                                    }}
                                                />
                                            </div>
                                            <div className='col-md-4'>
                                                <label>To Date <span style={{ color: "red" }}>*</span></label>
                                                <DatePicker style={{ width: '280px' }}
                                                    onChange={(date, dateString) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            to_date: dateString
                                                        })
                                                    }}
                                                />
                                                {validator.current.message(
                                                    'to_date',
                                                    consolidateddata.to_date,
                                                    'required',
                                                    {
                                                        className: `invalid-feedback ${AddFormSubmit ? 'show' : 'hide'}`,
                                                        messages: {
                                                            required: 'Required Field Missing',
                                                        }
                                                    })}
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Division</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            division: value
                                                        })
                                                    }}
                                                    placeholder="Enter Division Name"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Compliance Frequency</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_frequency: value
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="All">All</Option>
                                                    <Option key="One Time">One Time</Option>
                                                    <Option key="Periodical">Periodical</Option>
                                                    <Option key="Review">Review</Option>
                                                    <Option key="Flexi Review">Flexi Review</Option>
                                                    <Option key="On Occurence">On Occurence</Option>
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Compliance Task Status</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            compliance_task_status: value
                                                        })
                                                    }}
                                                    // placeholder="Enter Domain Name"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="All">All</Option>
                                                    <Option key="Complied">Complied</Option>
                                                    <Option key="Delayed Compliance">Delayed Compliance</Option>
                                                    <Option key="Review">Review</Option>
                                                    <Option key="In Progress">In Progress</Option>
                                                    <Option key="Not Complied">Not Complied</Option>
                                                </Select>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Category</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            category: value
                                                        })
                                                    }}
                                                    placeholder="Enter Category Name"
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="India">India</Option>
                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>User Type</label>
                                                <Select
                                                    onChange={(value) => {
                                                        setConsolidatedDate({
                                                            ...consolidateddata,
                                                            user_type: value
                                                        })
                                                    }}
                                                    style={{ width: '280px' }}
                                                >
                                                    <Option key="All">All</Option>
                                                    <Option key="Assignee">Assignee</Option>
                                                    <Option key="Concurrence">Concurrence</Option>
                                                    <Option key="Approval">Approval</Option>

                                                </Select>
                                            </div>
                                            <div className='col-md-4'>
                                            </div>

                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "10px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <button className="btn btn-secondary" style={{ marginLeft: "59px", marginRight: "10px", width: "100px" }} onClick={onshow}>Show</button>
                                                <button className="btn btn-success" style={{ width: "100px" }} onClick={showModal} >Export</button>
                                            </div>
                                        </div>
                                    </div>

                                </Panel>
                            </Collapse>

                            {tableshow == true ?
                                <Card>
                                    <div className='col-md-12' style={{ marginTop: "30px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'></div>
                                            <div className='col-md-4'>
                                                <h2>Domain Report</h2>
                                            </div>
                                            <div className='col-md-4'></div>
                                        </div>
                                    </div>
                                    <div className='col-md-12' style={{ marginTop: "30px", marginBottom: "15px" }}>
                                        <div className='row'>
                                            <div className='col-md-4'>
                                                <label>Legal Entity :</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Country :</label>
                                            </div>
                                            <div className='col-md-4'>
                                                <label>Domain :</label>
                                            </div>
                                        </div>
                                    </div>
                                    <DataTable
                                        columns={columns}
                                        data={item}
                                        selectableRows={false}
                                        pagination={false}
                                    />
                                </Card>
                                : ""}
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
})
export default DomainReport;